import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { isNotEmptyArray } from '@travel/utils';

import { EMPTY_ARRAY, EMPTY_FUNCTION } from 'constants/defaultValue';
import { ProviderInformation } from 'ProviderInformation-Types';

import { ProviderInformationState } from './reducer';

export const getErrors = (state: RootState) => state.providerInformation.errors;

export const getProviderInformation = (state: RootState) => state.providerInformation;

export const getFacilities = createSelector(
  getProviderInformation,
  (state: ProviderInformationState) => state.item.facilities,
);

export const getItem = createSelector(
  getProviderInformation,
  (state: ProviderInformationState) => state.item,
);

export const getIsFetching = createSelector(
  getProviderInformation,
  (state: ProviderInformationState) => state.isFetching,
);

export const getGalleryCoverInformation = createSelector(
  getProviderInformation,
  (state: ProviderInformationState) => {
    const { item } = state;
    const media = item.media || [];

    return {
      galleryData: media,
      media: media[0], //Image for display
      isLiked: false,
      isLikedHandler: () => {}, //for now API does not have information about it, so sending false,
      isShare: true,
      photosCount: media.length,
    };
  },
);

export const getHasHighlights = createSelector(
  getProviderInformation,
  (state: ProviderInformationState) => isNotEmptyArray(state.item?.highlights),
);

const getProviderCaches = createSelector(
  getProviderInformation,
  (state: ProviderInformationState) => state.caches,
);

export const getDetailSummary = (id: string) =>
  createSelector(
    getItem,
    getProviderCaches,
    (item: ProviderInformation, caches: Record<string, Partial<ProviderInformation>>) => {
      const cache = caches[id];
      const data = cache || item;
      const media = data.media || EMPTY_ARRAY;

      return {
        id: item.id,
        title: data.name,
        isActive: item.isActive,
        starRating: data.starRating,
        catchCopy: item.catchCopy,
        awards: data.certifications ? data.certifications : EMPTY_ARRAY,
        isJapanStandard: data.isJapanStandard,
        categoryNames: item.facilities
          ?.find(facility => facility.management === 'Category')
          ?.features?.map(feature => feature.text),
        galleryData: media,
        media: media[0], //Image for display
        isLiked: false,
        isLikedHandler: EMPTY_FUNCTION, //for now API does not have information about it, so sending false,
        isShare: true,
        photosCount: media.length,
        isBookmarked: data.isBookmarked,
      };
    },
  );

export const getProviderFeatures = createSelector(
  getProviderInformation,
  (state: ProviderInformationState) => {
    const { item } = state;

    return {
      categoryFeatures: item.facilities
        ?.find(facility => facility.management === 'Category')
        ?.features.map(feature => feature.text)
        .filter(category => category),
      facilitiesFeatures: item.facilities
        ?.find(facility => facility.management === 'Facility')
        ?.features.map(feature => feature.text)
        .filter(facility => facility),
    };
  },
);

export const getIsFindARoomBtnPresent = (state: RootState) =>
  state.providerInformation.isFindARoomBtnPresent;

export const getSelectedNavSection = (state: RootState) =>
  state.providerInformation.selectedNavSection;
