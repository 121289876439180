import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { ProviderPlanList, ProviderPlanListErrors } from 'ProviderPlanList-Types';

import { SearchRequestBody } from '../../pages/ProviderInformationPage/resolver';
import { fetchPlanList } from './apis';

export const fetchProviderPlanListAsync = createAsyncAction(
  'FETCH_PLAN_LIST_REQUEST',
  'FETCH_PLAN_LIST_SUCCESS',
  'FETCH_PLAN_LIST_FAILURE',
)<undefined, ProviderPlanList, ProviderPlanListErrors[]>();

export const fetchProviderPlanList = (req: SearchRequestBody): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  try {
    dispatch(fetchProviderPlanListAsync.request());
    const response = await fetchPlanList(apiClient, req);
    dispatch(fetchProviderPlanListAsync.success(response));
  } catch (error) {
    dispatch(fetchProviderPlanListAsync.failure(error));
  }
};
