import { setPreviousPageName } from 'store/__router/actions';
import { fetchLegacyReservationList } from 'store/legacyReservationList/actions';
import { getLatestPageName } from 'store/pageLoading/selectors';
import { setDefaultSEOData } from 'store/seo/actions';

import { PAGE_NAMES } from 'constants/reservationList';
import { universalRouterProps } from 'core/universalRouter/types';
import { STATUS_TYPE } from 'LegacyReservationList-Types';

type Query = {
  category: STATUS_TYPE;
};

export default ({ store: { dispatch, getState } }: universalRouterProps, query: Query) => {
  const state = getState();
  const latestPageName = getLatestPageName(state);
  const selectedTab =
    query?.category ||
    (latestPageName === 'legacyReservationDetail'
      ? state.legacyReservationList.selectedStatusType
      : 'UPCOMING');
  const req = {
    offset: 0,
    limit: 5,
    reservationList: selectedTab,
  };

  dispatch(setPreviousPageName(PAGE_NAMES[selectedTab]));

  return Promise.all([dispatch(fetchLegacyReservationList(req)), dispatch(setDefaultSEOData())]);
};
