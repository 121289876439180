import dayjs from 'dayjs';

import { DEFAULT_ADULT } from '@travel/traveler-core/constants';
import { toAppSearchObject } from '@travel/traveler-core/utils';
import { isEmptyObject } from '@travel/utils';

import { postBookingStep1 } from 'store/bookingStep/actions';
import { fetchProviderInformation } from 'store/providerInformation/actions';
import { getItem } from 'store/providerInformation/selectors';
import { setDefaultSEOData } from 'store/seo/actions';

import { NewQuery, Query } from 'BookingStep-Types';
import { universalRouterProps } from 'core/universalRouter/types';

export type BookingStep1Params = {
  providerId: string;
};

const DATE_FORMAT = 'YYYY-MM-DD';
export function getBookingStep1Body(providerId: string, query: NewQuery) {
  const checkInDate = query.startDate && dayjs(query.startDate).format(DATE_FORMAT);
  const checkOutDate = query.endDate && dayjs(query.endDate).format(DATE_FORMAT);
  return {
    providerId,
    itemId: query.itemId || '',
    ratePlanId: query.ratePlanId || '',
    itemRatePlanId: query.itemRatePlanId,
    // room
    checkInDate: checkInDate || '',
    checkOutDate: checkOutDate || '',
    roomCount: Number(query.noOfUnits || 1),
    noOfAdults: Number(query.adults || DEFAULT_ADULT),
    childAges: query.childrenAges,
    appliedSalesPromotionIds: query.appliedSalesPromotionIds,
    isCouponSpecified: query?.isCouponSpecified === '1' ? true : false,
    perRoomPriceBeforeDiscount: Number(query.perRoomPriceBeforeDiscount),
    perRoomPriceAfterDiscount: query?.appliedSalesPromotionId_1
      ? Number(query.perRoomPriceAfterDiscount)
      : null,
  };
}

export default (
  { store }: universalRouterProps,
  bookingStep1Query: { params: BookingStep1Params; query: Query },
) => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const providerItem = getItem(state);
  let fetchProviderInformationDispatch;
  const { params, query } = bookingStep1Query;
  if (isEmptyObject(providerItem)) {
    fetchProviderInformationDispatch = () => dispatch(fetchProviderInformation(params.providerId));
  }
  const newQuery = toAppSearchObject(query) as NewQuery;
  const bookingStep1Body = getBookingStep1Body(params.providerId, newQuery);
  return Promise.all([
    dispatch(postBookingStep1(bookingStep1Body)),
    dispatch(setDefaultSEOData()),
    fetchProviderInformationDispatch ? fetchProviderInformationDispatch() : () => true,
  ]);
};
