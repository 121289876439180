import { useEffect, useRef, useState } from 'react';

/**
 * To listen the current connection state
 * @param reloadOnOnline - Flag to define whether the page should be reloaded when offline has triggered from service-worker or not
 */
const useConnectionHandler = (reloadOnOnline: boolean = false) => {
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const isFirstRenderRef = useRef<boolean>(true);

  /** To handle connection state */
  useEffect(() => {
    function handleConnection() {
      setIsOnline(navigator.onLine);

      if (navigator.onLine && reloadOnOnline) {
        window.location.reload();
      }
    }

    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
    }

    window.addEventListener('online', handleConnection);
    window.addEventListener('offline', handleConnection);

    return () => {
      window.removeEventListener('online', handleConnection);
      window.removeEventListener('offline', handleConnection);
    };
  }, [reloadOnOnline]);

  return { isOnline };
};

export default useConnectionHandler;
