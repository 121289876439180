import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getIsIOS } from '../store/userAgent/selectors';

function usePeventIOSZoomingOnInputFocusing() {
  const isIOS = useSelector(getIsIOS);

  useEffect(() => {
    if (isIOS) {
      const metaViewportEl = document.querySelector<HTMLMetaElement>('meta[name=viewport]');

      if (metaViewportEl) {
        metaViewportEl.setAttribute(
          'content',
          [metaViewportEl.getAttribute('content'), 'maximum-scale=1.0'].join(', '),
        );
      }
    }
  }, [isIOS]);
}

export default usePeventIOSZoomingOnInputFocusing;
