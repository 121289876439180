import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { getCurrency, getLanguage, getMarket } from '@travel/i18n';

import { SEOAlternatePaths } from 'Markets-Types';

import { MarketsState } from './reducer';

export const getMarketState = (state: RootState) => state.markets;

export const getMarkets = createSelector(getMarketState, (state: MarketsState) => state.items);

export const getDefaultLanguageCode = createSelector(getMarket, currentMarket => {
  const { displayOrder } = currentMarket;
  return displayOrder?.find(lang => lang.priority === 1)?.langCode;
});

export const getDefaultCurrency = createSelector(getMarket, currentMarket => {
  const { supportedCurrencies } = currentMarket;
  return supportedCurrencies?.find(curr => Boolean(curr.isDefault))?.currency;
});

export const getIsGlobalCurrency = createSelector(
  getDefaultLanguageCode,
  getDefaultCurrency,
  getCurrency,
  getLanguage,
  (defaultLanguage, defaultCurrency, currentCurrency, currentLanguage) => {
    return currentLanguage !== defaultLanguage || currentCurrency !== defaultCurrency;
  },
);

export const getCurrencyListOptions = createSelector(
  getMarket,
  market =>
    market.supportedCurrencies?.map(item => ({ text: item.currency, value: item.currency })) || [],
);

/**
 * SEO
 */

// filter only SEO supported market and languages
export const getSEOAlternatePaths = createSelector(
  getMarkets,
  (items): SEOAlternatePaths =>
    items.reduce((result: SEOAlternatePaths, { displayOrder, marketCode }) => {
      const marketLangPaths = displayOrder
        ?.filter(lang => lang.isSeoIndexTarget)
        .reduce((rs, { langCode }) => {
          const lang = langCode.split('-')[0];
          const countryCode = marketCode.substring(0, 2);

          return {
            ...rs,
            [`${marketCode}/${langCode}`]: {
              marketCode,
              langCode,
              // KOR is an only exceptional case that we cannot make it automatically
              hreflang: `${lang}-${marketCode === 'KOR' ? 'KR' : countryCode}`,
            },
          };
        }, {});

      return { ...result, ...marketLangPaths };
    }, {}),
);

export const getLimitationsForCurrentMarket = createSelector(
  getMarkets,
  getMarket,
  (markets, currentMarket) => {
    const currentMarketItem = markets.find(
      market => market.marketCode === currentMarket.marketCode.toUpperCase(),
    );
    return currentMarketItem?.limitations;
  },
);

export const getPreferredMarket = createSelector(
  getMarketState,
  (state: MarketsState) => state.preferredMarket,
);

export const getIsFetchingPreferredMarket = createSelector(
  getMarketState,
  (state: MarketsState) => state.isFetchingPreferredMarket,
);
