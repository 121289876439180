import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import useDeviceType, { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';

import { getLocation } from 'store/__router/selectors';
import { getHeight, getShouldDisplayWideBanner } from 'store/banner/selectors';

import paths, { baseUrl } from '../../universalRouter/paths';

import styles from './components.module.scss';

const travelerHeaderHeightPC = 70;

type Props = {
  children: ReactNode;
};

function MainContent(props: Props) {
  const isMobile = useDeviceType() === MOBILE_SCREEN;
  const bannerHeight = useSelector(getHeight);
  const shouldDisplayWideBanner = useSelector(getShouldDisplayWideBanner);
  const location = useSelector(getLocation);
  const isProviderInformationPage = location.pathname.includes(baseUrl.providerInfo);
  const isProviderListPage = location.pathname.includes(baseUrl.providerList);
  const isTopOrAreaPage =
    paths.top.alternatePaths.some(path => path === location.pathname) ||
    location.pathname.includes(baseUrl.area);

  return (
    <div
      className={
        isProviderInformationPage || isTopOrAreaPage
          ? styles.noFixedHeaderContent
          : styles.mainContent
      }
      style={
        !isMobile && shouldDisplayWideBanner
          ? {
              marginTop: isProviderListPage
                ? bannerHeight + travelerHeaderHeightPC
                : isTopOrAreaPage
                ? 0
                : bannerHeight,
            }
          : undefined
      }
    >
      {props.children}
    </div>
  );
}

export default MainContent;
