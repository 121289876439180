import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { IntroductionBanner, IntroductionBannerErrors } from 'IntroductionBanner-Types';

import { fetchIntroductionBannerAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchIntroductionBannerAsync.request], () => true)
  .handleAction(
    [fetchIntroductionBannerAsync.success, fetchIntroductionBannerAsync.failure],
    () => false,
  );

export const item = createReducer({} as IntroductionBanner).handleAction(
  fetchIntroductionBannerAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as IntroductionBannerErrors[]).handleAction(
  fetchIntroductionBannerAsync.failure,
  (state, action) => action.payload,
);

const introductionBannerReducer = combineReducers({
  isFetching,
  item,
  errors,
});

export default introductionBannerReducer;
export type introductionBannerState = ReturnType<typeof introductionBannerReducer>;
