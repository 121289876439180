import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ProviderViewersErrors, ProviderViewersItem } from 'ProviderViewers-Types';

import { fetchProviderViewersAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchProviderViewersAsync.request], () => true)
  .handleAction(
    [fetchProviderViewersAsync.success, fetchProviderViewersAsync.failure],
    () => false,
  );

export const item = createReducer({} as ProviderViewersItem).handleAction(
  fetchProviderViewersAsync.success,
  (_state, action) => action.payload,
);

export const errors = createReducer([] as ProviderViewersErrors[]).handleAction(
  fetchProviderViewersAsync.failure,
  (_state, action) => action.payload,
);

const providerViewersReducer = combineReducers({
  isFetching,
  item,
  errors,
});

export default providerViewersReducer;
export type ProviderViewersState = ReturnType<typeof providerViewersReducer>;
