import React from 'react';

import { getL10nNumber } from '@travel/i18n';
import { Tag } from '@travel/ui';
import { cx } from '@travel/utils';

import { Translate } from 'core/translate';
import { getReviewLabelText, roundReviewScore } from 'utils/review';

import styles from './reviewLink.module.scss';

type Props = {
  className?: string;
  textClassName?: string;
  /** String of current market */
  market: string;
  /** Number of review score */
  reviewScore?: number;
  /** Number of total reviews */
  totalReviews: number;
  /** Review Label */
  reviewLabel?: string;
  /** Callback to be called when link is clicked */
  onClick?: (event: React.MouseEvent) => void;
};

function ReviewLink(props: Props) {
  const {
    className,
    textClassName,
    reviewScore,
    totalReviews,
    reviewLabel,
    onClick,
    market,
  } = props;
  if (!reviewScore && !totalReviews) {
    return null;
  }

  const reviewLabelText = getReviewLabelText(reviewLabel);

  return (
    <button
      className={cx(className, styles.reviewLink)}
      onClick={onClick}
      data-testid="reviewLink-wrapper"
    >
      <Tag
        className={styles.scoreWrapper}
        type="filled"
        color="blue"
        children={<span className={styles.bold}>{roundReviewScore(reviewScore)}</span>}
        data-testid="reviewLink-reviewScoreTag"
      />
      <div className={cx(styles.reviewLabelText, textClassName)}>
        {reviewLabelText && <>{reviewLabelText}</>}
        {Number(totalReviews) > 0 && (
          <div className={styles.reviewCount}>
            &nbsp; (
            <Translate
              className={styles.reviewCount}
              id="Provider_List.Search_Results.Reviews"
              data={{
                review_count: (
                  // Cannot use L10nMoney because the component is being used in GoogleMap
                  <span>{getL10nNumber(market, totalReviews)}</span>
                ),
              }}
            />
            )
          </div>
        )}
      </div>
    </button>
  );
}

export default ReviewLink;
