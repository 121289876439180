import { ApiClient } from '@travel/api-client';

import { MemberError, MemberItem, MemberResponse } from 'Member-Types';

export const fetch = (http: ApiClient): Promise<MemberItem | MemberError> =>
  http.get('/consumer/member/members', {
    withAuthCode: true,
    values: {
      fields: 'memberInfo,points',
    },
    ignoreCommonErrorHandler: true,
  });

export const updateLanguageAndCurrency = (
  http: ApiClient,
  type: { market?: string; currency?: string; language?: string },
): Promise<MemberResponse> =>
  http
    .post(`/consumer/member/members/siteSettings`, {
      withAuthCode: true,
      headers: {
        'trv-currency': type?.currency,
        'trv-language': type?.language,
        'trv-market': type?.market,
      },
    })
    .catch(error => {
      // do nothing
    });

export const nonMemberEmailVerification = (http: ApiClient, url: string, email?: string) =>
  http.post('/verify/emailVerification/v2', {
    values: {
      // @ts-ignore
      email: email,
      clientId: 'domesticHotel',
      optionalParams: {
        nextStepUrl: url,
      },
    },
  });

export const nonMemberVerification = (http: ApiClient, deviceId: string, token: string) =>
  http.get(`/consumer/auth-endusers/verify/${token}`, {
    ignoreCommonErrorHandler: true,
    values: { preDeviceId: deviceId },
    headers: {
      'trv-device-id': deviceId,
    },
  });
