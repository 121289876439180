import { ApiClient } from '@travel/api-client';

import { PostReviewRequestBody, PostReviewResponse, ReviewOptions } from 'PostReview-Types';

export const requestReview = (
  http: ApiClient,
  id: string,
  req: PostReviewRequestBody,
): Promise<PostReviewResponse> =>
  http.post(`/reviews/traveler/${id}`, {
    values: req,
    withAuthCode: true,
  });

export const getReviewOptions = (http: ApiClient, id: string): Promise<ReviewOptions> =>
  http.get(`/reviews/traveler/${id}/reviewForm`, {
    ignoreCommonErrorHandler: true,
    withAuthCode: true,
  });
