import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  SpecialFeatureArticlesErrors,
  SpecialFeatureArticlesItems,
} from 'SpecialFeatureArticles-Types';

import { fetchSpecialFeatureArticlesAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchSpecialFeatureArticlesAsync.request], () => true)
  .handleAction(
    [fetchSpecialFeatureArticlesAsync.success, fetchSpecialFeatureArticlesAsync.failure],
    () => false,
  );

export const items = createReducer([] as SpecialFeatureArticlesItems[])
  .handleAction(fetchSpecialFeatureArticlesAsync.success, (_state, action) => action.payload)
  .handleAction(
    fetchSpecialFeatureArticlesAsync.failure,
    () => [] as SpecialFeatureArticlesItems[],
  );

export const errors = createReducer([] as SpecialFeatureArticlesErrors[]).handleAction(
  fetchSpecialFeatureArticlesAsync.failure,
  (_state, action) => action.payload,
);

const specialFeatureArticlesReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default specialFeatureArticlesReducer;
export type SpecialFeatureArticlesState = ReturnType<typeof specialFeatureArticlesReducer>;
