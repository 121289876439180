import { getIn } from 'formik';

type ShouldUpdateProps<T, U> = {
  formik: {
    touched: T;
    values: T;
  };
  meta: U;
  name: string;
};

// Formik FastField only re-renders when the field value, touched or errors is changed
// In some case we need to trigger a render based on another property so we hook into Formik shouldComponentUpdate through this
export const shouldUpdateFastField = <T, U>(
  nextProps: ShouldUpdateProps<T, U>,
  currentProps: ShouldUpdateProps<T, U>,
) => {
  return (
    JSON.stringify(nextProps.meta) !== JSON.stringify(currentProps.meta) ||
    getIn(nextProps.formik.values, currentProps.name) !==
      getIn(currentProps.formik.values, currentProps.name) ||
    getIn(nextProps.formik.touched, currentProps.name) !==
      getIn(currentProps.formik.touched, currentProps.name)
  );
};
