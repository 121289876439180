import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { SuggestionsState } from './reducer';

export const getItems = (state: RootState) => state.suggestions.items;
export const getErrors = (state: RootState) => state.suggestions.errors;

export const getSuggestions = (state: RootState) => state.suggestions;

// XXX: Update to your own implementation of computed state
export const getIsFetching = createSelector(
  getSuggestions,
  (state: SuggestionsState) => state.isFetching,
);
