import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  DeleteResponse,
  Notifications,
  NotificationsErrors,
  NotificationsReq,
  NotificationStatus,
} from 'Notifications-Types';

import { deleteNotificationRequest, fetchNotificationItems, fetchNotificationStatus } from './api';

export const saveNotificationReqType = createStandardAction('STEP_NOTIFICATIONS_REQ_TYPE')<
  string
>();

export const fetchNotificationsAsync = createAsyncAction(
  'FETCH_NOTIFICATIONS_REQUEST',
  'FETCH_NOTIFICATIONS_SUCCESS',
  'FETCH_NOTIFICATIONS_FAILURE',
)<undefined, Notifications, NotificationsErrors[]>();

export const fetchNotifications = (
  req: NotificationsReq,
  ignoreCommonErrorHandler?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchNotificationsAsync.request());
  try {
    const response = await fetchNotificationItems(apiClient, req, ignoreCommonErrorHandler);
    dispatch(fetchNotificationsAsync.success(response));
    dispatch(saveNotificationReqType(req.notificationType));
  } catch (error) {
    dispatch(fetchNotificationsAsync.failure(error));
  }
};

export const fetchUnreadNotificationsAsync = createAsyncAction(
  'FETCH_UNREAD_NOTIFICATION_COUNT_REQUEST',
  'FETCH_UNREAD_NOTIFICATION_COUNT_SUCCESS',
  'FETCH_UNREAD_NOTIFICATION_COUNT_FAILURE',
)<undefined, NotificationStatus[], NotificationsErrors[]>();

export const fetchUnreadNotificationCount = (): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchUnreadNotificationsAsync.request());
  try {
    const response = await fetchNotificationStatus(apiClient, true);
    dispatch(fetchUnreadNotificationsAsync.success(response));
  } catch (error) {
    dispatch(fetchUnreadNotificationsAsync.failure(error));
  }
};

export const fetchMoreNotificationsAsync = createAsyncAction(
  'FETCH_MORE_NOTIFICATIONS_REQUEST',
  'FETCH_MORE_NOTIFICATIONS_SUCCESS',
  'FETCH_MORE_NOTIFICATIONS_FAILURE',
)<undefined, Notifications, NotificationsErrors[]>();

export const deleteNotificationAsync = createAsyncAction(
  'DELETE_NOTIFICATION_REQUEST',
  'DELETE_NOTIFICATION_SUCCESS',
  'DELETE_NOTIFICATION_FAILURE',
)<undefined, DeleteResponse, NotificationsErrors[]>();

export const fetchMoreNotifications = (req: NotificationsReq): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMoreNotificationsAsync.request());
  try {
    const response = await fetchNotificationItems(apiClient, req);
    dispatch(fetchMoreNotificationsAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreNotificationsAsync.failure(error));
  }
};

export const deleteNotification = (notificationId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(deleteNotificationAsync.request());
  try {
    const response = await deleteNotificationRequest(apiClient, notificationId);
    dispatch(deleteNotificationAsync.success(response));
    dispatch(fetchUnreadNotificationCount());
  } catch (error) {
    dispatch(deleteNotificationAsync.failure([error]));
  }
};
