import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ADJUST_APP_LINK } from '@travel/traveler-core/constants/common';

import { setShortUrl, updateRanData, updateShortUrl } from './actions';
import { RanData, ShortUrl } from './types';

export const ranData = createReducer({} as RanData).handleAction(
  updateRanData,
  (_state, action) => action.payload,
);

export const defaultShortState = { hasShort: false, url: ADJUST_APP_LINK };

export const shortUrl = createReducer({
  iOS: defaultShortState,
  android: defaultShortState,
} as ShortUrl)
  .handleAction(setShortUrl, (_state, action) => action.payload)
  .handleAction(updateShortUrl, (state, action) => ({
    ...state,
    ...action.payload,
  }));

const ranStoreReducer = combineReducers({
  ranData,
  shortUrl,
});

export default ranStoreReducer;
export type ProviderListState = ReturnType<typeof ranStoreReducer>;
