import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { pushLocation, replaceLocation } from 'store/__router/actions';

import paths from 'core/universalRouter/paths';
import {
  Errors,
  ModificationItemError,
  ModificationStep2,
  ModificationStep3,
  PostModificationStep2,
  PostModificationStep3,
} from 'Modification-Types';

import { modificationStep2, modificationStep3 } from './api';

export const postModificationStep3Async = createAsyncAction(
  'POST_MODIFICATION_STEP3_REQUEST',
  'POST_MODIFICATION_STEP3_SUCCESS',
  'POST_MODIFICATION_STEP3_FAILURE',
)<undefined, ModificationStep3, ModificationItemError>();

export const postModificationStep2Async = createAsyncAction(
  'POST_MODIFICATION_STEP2_REQUEST',
  'POST_MODIFICATION_STEP2_SUCCESS',
  'POST_MODIFICATION_STEP2_FAILURE',
)<undefined, ModificationStep2, ModificationItemError>();

export const errorCodeAction = createStandardAction('ERROR_CODE_SUCCESS')<Errors>();

export const postModificationStep2 = (
  reservationId: string,
  req: PostModificationStep2,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(postModificationStep2Async.request());
  try {
    const response = await modificationStep2(apiClient, reservationId, req);
    dispatch(postModificationStep2Async.success(response));
  } catch (error) {
    dispatch(postModificationStep2Async.failure({ status: error?.status, errors: error?.errors }));
  }
};

export const postModificationStep3 = (
  reservationId: string,
  req: PostModificationStep3,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(postModificationStep3Async.request());
  const response = await modificationStep3(apiClient, reservationId, req);
  if (!response.status) {
    dispatch(postModificationStep3Async.success(response));
    dispatch(replaceLocation(paths.modificationStep3.pathResolver(reservationId)));
  } else {
    dispatch(
      postModificationStep3Async.failure({
        status: response?.status,
        errors: response?.errors,
        ...(response?.minLos && { minLos: response?.minLos }),
      }),
    );
  }
};
