import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { MediaResponse, NewMedia } from 'Media-Types';

import { removeMediaAsync, uploadMediaAsync } from './actions';

export const isUploading = createReducer(false as boolean)
  .handleAction([uploadMediaAsync.request], () => true)
  .handleAction([uploadMediaAsync.success, uploadMediaAsync.failure], () => false);

// response
export const uploadResponse = createReducer({} as NewMedia).handleAction(
  uploadMediaAsync.success,
  (_state, action) => action.payload,
);

export const removeResponse = createReducer({} as MediaResponse).handleAction(
  removeMediaAsync.success,
  (_state, action) => action.payload,
);

// errors
export const uploadErrors = createReducer([] as MediaResponse[]).handleAction(
  uploadMediaAsync.failure,
  (_state, action) => action.payload,
);

export const removeErrors = createReducer([] as MediaResponse[]).handleAction(
  removeMediaAsync.failure,
  (_state, action) => action.payload,
);

const mediaReducer = combineReducers({
  isUploading,

  // response
  uploadResponse,
  removeResponse,

  // errors
  uploadErrors,
  removeErrors,
});

export default mediaReducer;
export type MediaState = ReturnType<typeof mediaReducer>;
