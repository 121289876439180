import React from 'react';

import { L10nDate } from '@travel/i18n';
import { ArrowRight, More } from '@travel/icons/ui';
import { useDeviceType, useDialogHandler } from '@travel/traveler-core/hooks';
import { FlatButton, IconTextLink, Popup } from '@travel/ui';

import { STATUS_KEYS } from 'pages/ReservationListPage/constants';

import { Translate } from 'core/translate';
import Link from 'core/universalRouter/Link';
import paths from 'core/universalRouter/paths';
import { LegacyReservationListItem, STATUS_TYPE } from 'LegacyReservationList-Types';

import styles from './reservationItem.module.scss';

type Props = {
  selectedTab: STATUS_TYPE;
  language: string;
} & LegacyReservationListItem;

function ReservationItem(props: Props) {
  const {
    reservationId,
    language,
    checkInDate,
    checkOutDate,
    cancellable,
    providerName,
    roomName,
    planName,
  } = props;
  const { onClose, onToggle, isOpen } = useDialogHandler(false);

  const deviceType = useDeviceType();
  const isSP = deviceType === 'sp';

  const onMenuSwitch = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onToggle();
  };

  return (
    <div data-testid="reservationItem-wrapper" className={styles.container}>
      <div className={styles.roomAndPrice}>
        <div className={styles.imageAndPrice}>
          {isSP && (
            <>
              <div className={styles.providerNameWrapper}>
                <div className={styles.content}>{providerName}</div>
              </div>

              {cancellable && (
                <button
                  className={styles.moreButton}
                  onClick={onMenuSwitch}
                  data-testid="reservationItem-popupEllipses-button"
                >
                  <More size={24} color={'default'} />
                  <Popup
                    className={styles.popup}
                    isOpen={isOpen}
                    onClose={onClose}
                    isShowTriangle={false}
                  >
                    <Link
                      tabIndex={-1}
                      to={paths.legacyReservationDetail.pathResolver(reservationId)}
                    >
                      <FlatButton
                        className={styles.popupButton}
                        classType="secondary"
                        data-testid="reservationItemButtons-cancelButton"
                      >
                        <div>
                          <Translate id="Reservation_List.Reservation_Summary.Navigation.Cancel" />
                        </div>
                      </FlatButton>
                    </Link>
                  </Popup>
                </button>
              )}
            </>
          )}
        </div>

        <main className={styles.itemDetails}>
          {!isSP && (
            <div>
              <div className={styles.titleAndRegion}>
                <div
                  className={styles.providerName}
                  data-testid="reservationItem-providerName-button"
                >
                  <div className={styles.content}>{providerName}</div>
                </div>
              </div>
              {(roomName || planName) && (
                <div className={styles.ResInfo}>
                  <Translate id="Reservation_List.Tablet_Labels.Room_Information" />
                  <div data-testid="legacyReservationList-reservationItem-roomInfo">
                    {roomName && <p className={styles.itemName}>{roomName}</p>}
                    {planName && <p>{planName}</p>}
                  </div>
                </div>
              )}

              <div
                className={styles.ResInfo}
                data-testid="legacyReservationList-reservationItem-stayDate"
              >
                <Translate
                  className={styles.stayDate}
                  id="Reservation_List.Reservation_Summary.Date_Of_Stay.Title"
                />
                <Translate
                  id="Reservation_List.Reservation_Summary.Date_Of_Stay.From_To"
                  data={{
                    check_in_date: (
                      <>
                        <L10nDate value={checkInDate} locale={language} format={'ddd'} />
                        &nbsp;
                        <L10nDate value={checkInDate} locale={language} format={'L'} />
                      </>
                    ),
                    check_out_date: (
                      <L10nDate value={checkOutDate} locale={language} format={'L'} />
                    ),
                  }}
                />
              </div>

              <div
                className={styles.ResInfo}
                data-testid="legacyReservationList-reservationItem-bookingId"
              >
                <Translate
                  className={styles.stayDate}
                  id="Cancel_Step1.Booking_Summary.Booking_Id"
                />
                {reservationId}
              </div>

              <div className={styles.detailLinkWrapper}>
                <Link to={paths.legacyReservationDetail.pathResolver(reservationId)}>
                  <IconTextLink
                    className={styles.detailLink}
                    icon={<ArrowRight size={16} color={'cilantro'} />}
                    text={
                      <Translate id="Reservation_List.Reservation_Summary.Navigation.Details" />
                    }
                    iconPosition={'right'}
                  />
                </Link>
              </div>
            </div>
          )}

          {isSP ? (
            <div className={styles.navigationWrapper}>
              {roomName && <p className={styles.itemName}>{roomName}</p>}
              {planName && <p className={styles.ratePlan}>{planName}</p>}
              <p className={styles.date}>
                {checkInDate} ~ {checkOutDate}
              </p>
              <p className={styles.date}>
                <Translate id="Cancel_Step1.Booking_Summary.Booking_Id" />
                {reservationId}
              </p>
            </div>
          ) : (
            cancellable && (
              <div className={styles.buttonWrapper}>
                <Link tabIndex={-1} to={paths.legacyReservationDetail.pathResolver(reservationId)}>
                  <FlatButton
                    className={styles.button}
                    classType="secondary"
                    data-testid="reservationItemButtons-cancelButton"
                  >
                    <div>
                      <Translate id="Reservation_List.Reservation_Summary.Navigation.Cancel" />
                    </div>
                  </FlatButton>
                </Link>
              </div>
            )
          )}
        </main>
      </div>
    </div>
  );
}

ReservationItem.defaultProps = {
  selectedTab: STATUS_KEYS[0], // 'UPCOMING'
};

export default ReservationItem;
