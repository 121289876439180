import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { GetTranslation } from '@travel/translation';
import { ACQUIRED_STATUS } from '@travel/traveler-core/constants/salesPromotion';
import { isEmptyArray } from '@travel/utils';

import {
  getBedText,
  getMaxAdultChild,
  getRoomSizeText,
} from 'pages/ProviderInformationPage/helpers';

import { FILTER_GROUP_IDS } from 'constants/provider';
import {
  Plan,
  ProviderRatePlan,
  RatePlanFilter,
  RatePlanItem,
  RatePlanItems,
} from 'ProviderRatePlan-Types';

import { ProviderRatePlanState } from './reducer';

export const getProviderRatePlan = (state: RootState) => state.providerRatePlan;

export const getIsFetching = createSelector(
  getProviderRatePlan,
  (state: ProviderRatePlanState) => state.isFetching,
);

export const getPlansTotal = createSelector(
  getProviderRatePlan,
  (state: ProviderRatePlanState) => state.items?.length || 0,
);

export const getItems = createSelector(
  getProviderRatePlan,
  (state: ProviderRatePlanState) => state.items,
);

export const getFilters = createSelector(
  getProviderRatePlan,
  (state: ProviderRatePlanState) => state.filters,
);

export const getFilterPriceRange = createSelector(getFilters, (state: RatePlanFilter[]) => {
  const priceFilter = state.find(filter => filter.groupId === FILTER_GROUP_IDS.PRICE);
  return { min: priceFilter?.min, max: priceFilter?.max };
});

export const getSelectedFeatures = createSelector(
  getProviderRatePlan,
  (state: ProviderRatePlanState) => state.selectedFeatures,
);

export const getSelectedObjectivesFeatureIdMapping = createSelector(
  getProviderRatePlan,
  ({ filters }: ProviderRatePlan): { [key: string]: string } => {
    return (
      filters?.reduce((result, filter) => {
        if (isEmptyArray(filter?.features)) {
          return result;
        }

        const mappedFeatures = filter.features.reduce((featureResult, feature) => {
          if (feature.managementName && feature.selected) {
            return { ...featureResult, [feature.managementName.toLowerCase()]: feature.featureId };
          } else {
            return featureResult;
          }
        }, {});

        return { ...result, ...mappedFeatures };
      }, {}) || {}
    );
  },
);

export const getErrors = createSelector(
  getProviderRatePlan,
  (state: ProviderRatePlanState) => state.errors,
);

export const getPlanDetail = (roomId: string, planId: string) =>
  createSelector(getItems, (items: RatePlanItems) => {
    return (
      items.find(item => item.id === roomId)?.plans.find(plan => plan.planId === planId) ||
      ({} as Plan)
    );
  });

export const getAcquiredCoupon = createSelector(
  getProviderRatePlan,
  (state: ProviderRatePlanState) => state.gottenCoupon,
);

export const getSalesPromotions = createSelector(
  getProviderRatePlan,
  (state: ProviderRatePlanState) => {
    const itemPlans = state.items;
    return itemPlans
      .reduce((prev: string[], item: RatePlanItem): string[] => {
        return [
          ...prev,
          ...item.plans.map(plan => plan?.price?.salesPromotions?.map(sp => sp.id)).flat(),
        ];
      }, [])
      .join(',');
  },
);

export const getRoomDetail = (id: string, getTranslation: GetTranslation) =>
  createSelector(getItems, (items: RatePlanItems) => {
    const item = items.find(item => item.id === id);
    if (!item) {
      return {};
    }

    // lowest plan price
    const lowestPlanPrice = Math.min.apply(
      null,
      item.plans.map(plan => plan.price.total),
    );

    return {
      facilities: item.facilities,
      views: item.view,
      lowestPlanPrice,
      displayImage: item.media?.find(item => item.url || item.videoVendorId),
      media: item.media,
      plans: item.plans,
      name: item.name,
      smokingAllowed: item.smokingAllowed,
      description: item.description,
      capacityText: getMaxAdultChild(item.capacity, getTranslation),
      bedText: getBedText(item.bedSizes),
      roomText: getRoomSizeText(item.roomSize),
      isGoogleTranslated: item.isGoogleTranslated,
    };
  });

export const getNoOfAppliedCoupons = (roomId: string, planId: string) =>
  createSelector(getPlanDetail(roomId, planId), (plan: Plan) => {
    return (
      plan?.price.salesPromotions?.filter(
        sp => sp.acquiredStatus === ACQUIRED_STATUS.ACQUIRED_BY_USER,
      ).length || 0
    );
  });

export const getNoOfAvailableCoupons = (roomId: string, planId: string) =>
  createSelector(getPlanDetail(roomId, planId), (plan: Plan) => {
    return (
      plan?.price.salesPromotions?.filter(
        sp => sp.acquiredStatus === ACQUIRED_STATUS.NOT_ACQUIRED_BY_USER,
      ).length || 0
    );
  });

export const getShouldRenderSimilarProviders = createSelector(
  getProviderRatePlan,
  ({ shouldRenderSimilarProviders }) => shouldRenderSimilarProviders,
);
