import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { CountriesItems } from 'Countries-Types';

import { fetchCountriesAsync } from './actions';

export const items = createReducer({} as CountriesItems).handleAction(
  fetchCountriesAsync.success,
  (_state, action) => action.payload,
);

const countriesReducer = combineReducers({
  items,
});

export default countriesReducer;
export type CountriesState = ReturnType<typeof countriesReducer>;
