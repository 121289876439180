import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { PostReviewRequestBody, PostReviewResponse, ReviewOptions } from 'PostReview-Types';

import { getReviewOptions, requestReview } from './apis';

export const fetchPostReviewAsync = createAsyncAction(
  'FETCH_POST_REVIEW_REQUEST',
  'FETCH_POST_REVIEW_SUCCESS',
  'FETCH_POST_REVIEW_FAILURE',
)<undefined, PostReviewResponse, PostReviewResponse[]>();

export const fetchPostReviewOptionsAsync = createAsyncAction(
  'FETCH_POST_REVIEW_OPTIONS_REQUEST',
  'FETCH_POST_REVIEW_OPTIONS_SUCCESS',
  'FETCH_POST_REVIEW_OPTIONS_FAILURE',
)<undefined, ReviewOptions, PostReviewResponse[]>();

export const postReview = (
  id: string,
  req: PostReviewRequestBody,
): AppThunk<Promise<PostReviewResponse>> => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchPostReviewAsync.request());

  try {
    const response = await requestReview(apiClient, id, req);
    dispatch(fetchPostReviewAsync.success(response));
    return { ...response, status: 200 };
  } catch (error) {
    dispatch(fetchPostReviewAsync.failure([error]));
    return error;
  }
};

export const fetchPostReviewOptions = (id: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchPostReviewOptionsAsync.request());

  try {
    const response = await getReviewOptions(apiClient, id);
    dispatch(fetchPostReviewOptionsAsync.success(response));
    return response;
  } catch (error) {
    dispatch(fetchPostReviewOptionsAsync.failure([error]));
    return error;
  }
};
