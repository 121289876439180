import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  GuestPendingReviewsItems,
  GuestReviewsErrors,
  GuestReviewsItems,
  PendingReviewDetail,
  ReviewDetail,
} from 'GuestReviews-Types';

import {
  fetchGuestReviewDetailAsync,
  fetchGuestReviewsAsync,
  fetchMoreGuestReviewsAsync,
  fetchMorePendingGuestReviewsAsync,
  fetchPendingGuestReviewAsync,
  fetchPendingGuestReviewsAsync,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchGuestReviewsAsync.request], () => true)
  .handleAction([fetchGuestReviewsAsync.success, fetchGuestReviewsAsync.failure], () => false);

export const isFetchingMore = createReducer(false as boolean)
  .handleAction(fetchMoreGuestReviewsAsync.request, () => true)
  .handleAction(
    [fetchMoreGuestReviewsAsync.success, fetchMoreGuestReviewsAsync.failure],
    () => false,
  );

export const item = createReducer({} as ReviewDetail).handleAction(
  fetchGuestReviewDetailAsync.success,
  (_state, action) => action.payload,
);

export const items = createReducer({} as GuestReviewsItems)
  .handleAction(fetchGuestReviewsAsync.success, (_state, action) => action.payload)
  .handleAction(fetchMoreGuestReviewsAsync.success, (state, action) => ({
    ...state,
    ...action.payload,
    reviews: [...state.reviews, ...action.payload.reviews],
  }));

export const errors = createReducer([] as GuestReviewsErrors[]).handleAction(
  fetchGuestReviewsAsync.failure,
  (_state, action) => action.payload,
);

/**
 * PENDING
 */

export const isPendingFetching = createReducer(false as boolean)
  .handleAction(
    [fetchPendingGuestReviewAsync.request, fetchPendingGuestReviewsAsync.request],
    () => true,
  )
  .handleAction(
    [
      fetchPendingGuestReviewAsync.success,
      fetchPendingGuestReviewAsync.failure,
      fetchPendingGuestReviewsAsync.success,
      fetchPendingGuestReviewsAsync.failure,
    ],
    () => false,
  );

export const isPendingFetchingMore = createReducer(false as boolean)
  .handleAction(fetchMorePendingGuestReviewsAsync.request, () => true)
  .handleAction(
    [fetchMorePendingGuestReviewsAsync.success, fetchMorePendingGuestReviewsAsync.failure],
    () => false,
  );

export const pendingItem = createReducer({} as PendingReviewDetail).handleAction(
  fetchPendingGuestReviewAsync.success,
  (_state, action) => action.payload,
);

export const pendingItems = createReducer({} as GuestPendingReviewsItems)
  .handleAction(fetchPendingGuestReviewsAsync.success, (_state, action) => action.payload)
  .handleAction(fetchMorePendingGuestReviewsAsync.success, (state, action) => ({
    ...state,
    ...action.payload,
    pendingReviews: [...state.pendingReviews, ...action.payload.pendingReviews],
  }));

export const pendingItemError = createReducer({} as GuestReviewsErrors).handleAction(
  fetchPendingGuestReviewAsync.failure,
  (_state, action) => action.payload,
);

const guestReviewsReducer = combineReducers({
  isFetching,
  isFetchingMore,
  isPendingFetching,
  isPendingFetchingMore,
  item, // detail item
  items,
  errors,
  pendingItem,
  pendingItems,
  pendingItemError,
});

export default guestReviewsReducer;
export type GuestReviewsState = ReturnType<typeof guestReviewsReducer>;
