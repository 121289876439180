import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ApplicableCouponDetails, CouponDetails, SalesPromotionErrors } from 'SalesPromotion-Types';

import { getApplicableCouponAsync, getCouponDetailsAsync, resetCouponDetail } from './actions';

export const isCouponDetailFetching = createReducer(false as boolean)
  .handleAction([getCouponDetailsAsync.request], () => true)
  .handleAction([getCouponDetailsAsync.success, getCouponDetailsAsync.failure], () => false);

export const couponDetails = createReducer({} as { [key: string]: CouponDetails })
  .handleAction(getCouponDetailsAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.details,
  }))
  .handleAction(resetCouponDetail, () => ({}));

export const couponDetailsErrors = createReducer([] as SalesPromotionErrors[]).handleAction(
  getCouponDetailsAsync.failure,
  (_state, action) => action.payload,
);

export const applicableCoupon = createReducer({} as ApplicableCouponDetails).handleAction(
  getApplicableCouponAsync.success,
  (_state, action) => action.payload,
);

const salesPromotionReducer = combineReducers({
  isCouponDetailFetching,
  couponDetails,
  couponDetailsErrors,
  applicableCoupon,
});

export default salesPromotionReducer;
export type SalesPromotionState = ReturnType<typeof salesPromotionReducer>;
