import React from 'react';

import { Skeleton } from '@travel/ui';

import styles from './skeleton.module.scss';

function SkeletonPage() {
  return (
    <div className={styles.skeletonWrapper}>
      <Skeleton className={styles.skeleton} length={'long'} />
      <Skeleton className={styles.paragraphSkeleton} length={'long'} type={'paragraph'} />
      <div className={styles.items}>
        <Skeleton height="150px" />
        <Skeleton height="150px" />
        <Skeleton height="150px" />
      </div>
      <Skeleton className={styles.paragraphSkeleton} length={'long'} type={'paragraph'} />
    </div>
  );
}

export default SkeletonPage;
