import { ApiClient } from '@travel/api-client';

import {
  GuestPendingReviewsItems,
  GuestReviewsItems,
  PendingReviewDetail,
  ReviewDetail,
} from 'GuestReviews-Types';

/**
 * REVIEW LIST
 */
export const fetchReviewItem = (http: ApiClient, reviewId: string): Promise<ReviewDetail> =>
  http.get(`/reviews/traveler/${reviewId}`, {
    withAuthCode: true,
  });

export const fetchReviewItems = (
  http: ApiClient,
  offset: number = 0,
  limit: number = 5,
): Promise<GuestReviewsItems> => {
  return http.get(`/reviews/traveler`, {
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    values: {
      offset: offset,
      limit: limit,
    },
  });
};

/**
 * PENDING
 */
export const fetchPendingReviewItem = (
  http: ApiClient,
  reviewId: string,
): Promise<PendingReviewDetail> =>
  http.get(`/reviews/traveler/pending/${reviewId}`, {
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
  });

export const fetchPendingReviewItems = (
  http: ApiClient,
  offset: number = 0,
  limit: number = 5,
): Promise<GuestPendingReviewsItems> =>
  http.get('/reviews/traveler/pending', {
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    values: {
      offset: offset,
      limit: limit,
    },
  });
