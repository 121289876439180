import React, { useCallback } from 'react';

type OnBlurHandler = (event: React.FocusEvent) => void;

const useBlurWrapper = (onBlurHandler: OnBlurHandler) => {
  const onBlur = useCallback(
    (event: React.FocusEvent) => {
      if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget as Node)) {
        onBlurHandler(event);
      }
    },
    [onBlurHandler],
  );
  return onBlur;
};

export default useBlurWrapper;
