import dayjs from 'dayjs';

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { FeatureConfigErrors, FeatureConfigs } from 'Feature-Config-Types';

import { fetchFeatureConfigAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchFeatureConfigAsync.request], (state, action) => true)
  .handleAction(
    [fetchFeatureConfigAsync.success, fetchFeatureConfigAsync.failure],
    (state, action) => false,
  );

export const appFeatureConfigs = createReducer({} as FeatureConfigs).handleAction(
  fetchFeatureConfigAsync.success,
  (_state, action) => {
    //We are storing the current date from the server to avoid the mis calculation of date
    //from client machine
    const utcTime = dayjs.utc();

    //As the campaign start date will be mentioned in JST timezone, we need
    //to convert the server date to JST date. This is required in case if the server is
    //in different timezone.
    const targetDate = dayjs(utcTime).tz('Asia/Tokyo');

    return { ...action.payload?.featureConfigs, currentDate: targetDate };
  },
);

export const errors = createReducer([] as FeatureConfigErrors[]).handleAction(
  fetchFeatureConfigAsync.failure,
  (_state, action) => action.payload,
);

const featureConfigReducer = combineReducers({
  isFetching,
  appFeatureConfigs,
  errors,
});

export default featureConfigReducer;
export type FeatureConfigState = ReturnType<typeof featureConfigReducer>;
