import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { IntroductionBanner, IntroductionBannerErrors } from 'IntroductionBanner-Types';

import { getEnvironment } from '../__router/selectors';
import { fetchAll } from './api';

export const fetchIntroductionBannerAsync = createAsyncAction(
  'FETCH_INTRODUCTION_BANNER_REQUEST',
  'FETCH_INTRODUCTION_BANNER_SUCCESS',
  'FETCH_INTRODUCTION_BANNER_FAILURE',
)<undefined, IntroductionBanner, IntroductionBannerErrors[]>();

export const fetchIntroductionBanner = (page: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const { _i18n } = getState();
    dispatch(fetchIntroductionBannerAsync.request());
    const response = await fetchAll(apiClient, page, _i18n.market?.marketCode, _i18n.language);
    dispatch(fetchIntroductionBannerAsync.success(response));
  } catch (error) {
    dispatch(fetchIntroductionBannerAsync.failure(error));
  }
};
