import { pushLocation } from 'store/__router/actions';
import { fetchProviderInformation } from 'store/providerInformation/actions';

import paths from 'core/universalRouter/paths';
import { universalRouterProps } from 'core/universalRouter/types';

export default (
  { store }: universalRouterProps,
  query: Record<string, string | Array<string> | undefined> & { callback?: string },
) => {
  const { dispatch } = store;

  const callbackUrlArr = query.callback?.split('?');
  const providerId =
    callbackUrlArr &&
    callbackUrlArr[0] &&
    callbackUrlArr[0].split('/').filter(el => !isNaN(parseInt(el)))[0];

  if (providerId) {
    return Promise.all([dispatch(fetchProviderInformation(providerId))]);
  } else {
    dispatch(pushLocation(paths.top.pathResolver()));
  }
};
