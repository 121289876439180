import { ApiClient } from '@travel/api-client';

import { SuggestionsItem } from 'Suggestions-Types';

export type FetchOption = {
  limit?: number;
  pathIdScope?: string;
};

export const fetch = (
  text: string,
  options: FetchOption,
  http: ApiClient,
): Promise<SuggestionsItem[]> =>
  http.get('/auto-completion', {
    values: {
      ...options,
      keyword: text,
    },
  });
