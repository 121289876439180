import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { BannerErrors, BannerItem } from 'Banner-Types';

import { fetchBannerAsync, setWideBannerHeight } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchBannerAsync.request], (state, action) => true)
  .handleAction([fetchBannerAsync.success, fetchBannerAsync.failure], (state, action) => false);

export const item = createReducer({} as BannerItem)
  .handleAction(fetchBannerAsync.success, (_state, action) => action.payload)
  .handleAction(fetchBannerAsync.failure, () => ({} as BannerItem));

export const errors = createReducer([] as BannerErrors[]).handleAction(
  fetchBannerAsync.failure,
  (_state, action) => action.payload,
);

const height = createReducer(0)
  .handleAction(setWideBannerHeight, (_state, action) => action.payload)
  // reset every time we loaded a new one (especially when we changed the page)
  .handleAction(fetchBannerAsync.failure, () => 0)
  .handleAction(fetchBannerAsync.success, (_state, action) => 0);

const bannerReducer = combineReducers({
  isFetching,
  item,
  errors,
  height,
});

export default bannerReducer;
export type BannerState = ReturnType<typeof bannerReducer>;
