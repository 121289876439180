import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { toSEOSearchString } from '@travel/traveler-core/utils/seo';

import { pushLocation } from 'store/__router/actions';

import { getLimitations } from 'core/universalRouter';
import paths from 'core/universalRouter/paths';
import {
  LegacyReservationDetailsErrors,
  LegacyReservationDetailsItems,
} from 'LegacyReservationDetails-Types';

import { fetchLegacyReservationDetailsItems } from './api';

export const fetchLegacyReservationDetailsAsync = createAsyncAction(
  'FETCH_LEGACY_RESERVATION_DETAILS_REQUEST',
  'FETCH_LEGACY_RESERVATION_DETAILS_SUCCESS',
  'FETCH_LEGACY_RESERVATION_DETAILS_FAILURE',
)<undefined, LegacyReservationDetailsItems, LegacyReservationDetailsErrors>();

export const fetchLegacyReservationDetails = (
  reservationId: string,
  email: string,
  ignoreCommonErrorHandler?: boolean,
  redirection?: boolean,
): AppThunk => async (dispatch, _getState, { apiClient }) => {
  dispatch(fetchLegacyReservationDetailsAsync.request());
  try {
    const response = await fetchLegacyReservationDetailsItems(
      apiClient,
      reservationId,
      email,
      ignoreCommonErrorHandler,
    );
    dispatch(fetchLegacyReservationDetailsAsync.success(response));
    if (redirection) {
      const limitations = getLimitations(_getState());

      dispatch(
        pushLocation({
          pathname: paths.legacyReservationDetail.pathResolver(reservationId),
          search: toSEOSearchString(
            {
              email: email,
            },
            limitations,
          ),
        }),
      );
    }
  } catch (error) {
    dispatch(fetchLegacyReservationDetailsAsync.failure(error?.errors?.[0]));
  }
};
