import { ApiClient } from '@travel/api-client';

import { IntroductionBanner } from 'IntroductionBanner-Types';
import { fetchEditableContents } from 'utils/editableContents';

export const fetchAll = (
  http: ApiClient,
  page: string,
  market: string,
  language: string,
): Promise<IntroductionBanner> =>
  fetchEditableContents(http, page, 'introductionbanner', market, language).then(data => {
    if (!data) {
      return {};
    }

    if (data.quality_badge?.length) {
      data.quality_badge.sort(
        (itemA: { display_order: number }, itemB: { display_order: number }) =>
          itemA.display_order - itemB.display_order,
      );
    }

    return data;
  });
