import { ApiClient } from '@travel/api-client';

import { LegacyCancelStep2Item, PostCancelStep2 } from 'LegacyCancelStep-Types';

export const cancelStep2 = (
  http: ApiClient,
  req: PostCancelStep2,
  data: { email: string },
): Promise<any> =>
  http
    .post('/consumer/mypage/maui_reservation/cancel-step2/' + req.reservationId, {
      headers: {},
      ignoreCommonErrorHandler: true,
      values: data,
    })
    .then((data: LegacyCancelStep2Item) => data)
    .catch((err: any) => err);
