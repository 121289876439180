import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { Dictionary } from '@travel/translation';

import { setDictionary } from './actions';

export const dictionary = createReducer({} as Dictionary).handleAction(
  setDictionary,
  (state, action) => action.payload,
);

const labelReducer = combineReducers({
  dictionary,
});

export default labelReducer;
export type LabelState = ReturnType<typeof labelReducer>;
