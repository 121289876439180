import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { GeneralContents, GeneralContentsErrors } from 'GeneralContents-Types';

import { getEnvironment } from '../__router/selectors';
import { fetchAll } from './api';

export const fetchGeneralContentsAsync = createAsyncAction(
  'FETCH_GENERAL_CONTENTS_REQUEST',
  'FETCH_GENERAL_CONTENTS_SUCCESS',
  'FETCH_GENERAL_CONTENTS_FAILURE',
)<undefined, GeneralContents, GeneralContentsErrors[]>();

export const fetchGeneralContents = (page: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const { _i18n } = getState();
    dispatch(fetchGeneralContentsAsync.request());
    const response = await fetchAll(apiClient, page, _i18n.market?.marketCode, _i18n.language);
    dispatch(fetchGeneralContentsAsync.success(response));
  } catch (error) {
    dispatch(fetchGeneralContentsAsync.failure(error));
  }
};
