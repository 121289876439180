import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { SuggestionsErrors, SuggestionsItem } from 'Suggestions-Types';

import { fetchSuggestionsAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchSuggestionsAsync.request], (state, action) => true)
  .handleAction(
    [fetchSuggestionsAsync.success, fetchSuggestionsAsync.failure],
    (state, action) => false,
  );

export const items = createReducer([] as SuggestionsItem[]).handleAction(
  fetchSuggestionsAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as SuggestionsErrors[]).handleAction(
  fetchSuggestionsAsync.failure,
  (state, action) => action.payload,
);

const suggestionsReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default suggestionsReducer;
export type SuggestionsState = ReturnType<typeof suggestionsReducer>;
