import { useEffect, useRef } from 'react';

function useOnResizeDebounce(callback: () => void, debounceTime = 400) {
  const timerRef = useRef<NodeJS.Timeout | number>(0);

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(timerRef.current as number);
      timerRef.current = setTimeout(() => {
        callback();
      }, debounceTime);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timerRef.current as number);
    };
  }, [callback, debounceTime]);
}

export default useOnResizeDebounce;
