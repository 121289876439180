import React from 'react';

import { capitalize } from '@travel/utils';

import { Translate } from 'core/translate';
import { Evaluation } from 'GuestReviews-Types';

export function roundReviewScore(score?: number | null) {
  return Math.round((Number(score || 0) * 100) / 10) / 10;
}

export function getReviewLabelText(label?: string, className?: string) {
  if (!label) {
    return;
  }
  const condition =
    label === 'Exceptional'
      ? '{averageScore}>=4.7'
      : label === 'Excellent'
      ? '4.3<={averageScore}<=4.6'
      : label === 'Very Good'
      ? '3.9<={averageScore}<=4.2'
      : label === 'Good'
      ? '3.5<={averageScore}<=3.8'
      : null;
  return condition ? (
    <Translate
      id="Traveler_Common.Review_Score_Average"
      condition={() => `status == ${condition}`}
      className={className}
    />
  ) : null;
}

/**
 * To convert the evaluation criteria from API to ScoreBarProps
 * @param evaluationCriteria - data from Review API
 * @param hasAnimation - Flag to define whether the scorebar will have animation or not
 */
export function criteriasToScoreBarProps(
  evaluationCriteria: Array<Evaluation>,
  hasAnimation: boolean = true,
) {
  return evaluationCriteria.map(evaluation => ({
    id: evaluation?.id,
    text: (
      <Translate
        id={`Traveler_Enumeration.Review_Evaluation_Criteria.${capitalize(evaluation?.id, '_')}`}
      />
    ),

    score: roundReviewScore(evaluation.score),
    max: 5,
    hasAnimation,
  }));
}
