import { ApiClient } from '@travel/api-client';

import {
  ReservationEntranceItems,
  ReservationEntranceRequestType,
} from 'ReservationEntrance-Types';

export const fetchAuthentication = (
  http: ApiClient,
  req: ReservationEntranceRequestType,
): Promise<ReservationEntranceItems> =>
  http
    .get('/consumer/auth-endusers/reservation-confirmation', {
      values: { ...req },
      ignoreCommonErrorHandler: true,
    })
    .then((data: ReservationEntranceItems) => {
      return data;
    });
