import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { getDictionary } from '@travel/i18n';
import { getTranslation } from '@travel/translation';

import { Section } from 'components/ScrollingNavigation';

import { getLocation } from 'store/__router/selectors';
import { updateSEOData } from 'store/seo/actions';

import paths from 'core/universalRouter/paths';
import { ProviderInformation, ProviderInformationErrors } from 'ProviderInformation-Types';

import { fetchAll } from './apis';
import { getItem } from './selectors';

export const fetchProviderInformationAsync = createAsyncAction(
  'FETCH_PROVIDER_INFORMATION_REQUEST',
  'FETCH_PROVIDER_INFORMATION_SUCCESS',
  'FETCH_PROVIDER_INFORMATION_FAILURE',
)<undefined, ProviderInformation, ProviderInformationErrors[]>();

export const fetchProviderInformation = (id: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const { providerInformation } = getState();
    if (id !== providerInformation.item?.id) {
      dispatch(fetchProviderInformationAsync.request());
    }
    const response = await fetchAll(apiClient, id);
    dispatch(fetchProviderInformationAsync.success(response));
  } catch (error) {
    dispatch(fetchProviderInformationAsync.failure([error] as ProviderInformationErrors[]));
  }
};

export const fetchSEOdata = (isIndexed?: boolean): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const { name, pathId, isTest } = getItem(state);
  const location = getLocation(state);
  const dictionary = getDictionary(state);

  dispatch(
    updateSEOData({
      title: getTranslation(
        {
          id: 'SEO.ProviderInformation.Item.Title',
          data: { provider: name },
        },
        dictionary,
      )?.join(''),
      description: getTranslation(
        {
          id: 'SEO.ProviderInformation.Item.Meta.Description',
          data: { provider: name },
        },
        dictionary,
      )?.join(''),
      isIndexedByDefault: isIndexed && !isTest,
      canonicalPath: location.pathname || paths.providerInfo.canonicalPathResolver(pathId),
    }),
  );
};

export const setIsFindARoomBtnPresent = createStandardAction('SET_IS_FIND_A_ROOM_BTN_PRESENT')<
  boolean
>();

export const setSelectedNavSection = createStandardAction('SET_SELECTED_NAV_SECTION')<Section>();
