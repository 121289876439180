import React from 'react';

import WithSkeletonLoadingCore, {
  Props,
} from '@travel/traveler-core/components/WithSkeletonLoading';

function WithSkeletonLoading(props: Props) {
  return <WithSkeletonLoadingCore {...props} />;
}

WithSkeletonLoading.defaultProps = {
  length: 'short',
  count: 1,
  spaceBetween: 0,
  children: null,
  customStyle: 'text',
  direction: 'column',
};

export default WithSkeletonLoading;
