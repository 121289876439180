import React from 'react';

import { IconProps } from '@travel/icons';
import {
  BusinessNew,
  CoupleNew,
  FamilyNew,
  FriendsNew,
  OthersNew,
  SoloNew,
} from '@travel/icons/service';

import { OptionItem } from 'Layout-Types';

/**********************************
 * SORT
 *********************************/
export const DEFAULT_SORTKEY = 'MOST_RECENT';

export const SORT_OPTIONS: { [key: string]: OptionItem } = {
  MOST_RECENT: {
    labelId: 'Traveler_Enumeration.Luxury.Sort.Review.Date_New_Old',
    value: 'MOST_RECENT',
  },
  OLDEST: {
    labelId: 'Traveler_Enumeration.Luxury.Sort.Review.Date_Old_New',
    value: 'OLDEST',
  },
  MOST_HELPFUL: {
    labelId: 'Traveler_Enumeration.Luxury.Sort.Review.Helpful_Most_Least',
    value: 'MOST_HELPFUL',
  },
};

/**********************************
 * TRAVEL TYPE
 *********************************/

export const TRAVEL_TYPES: {
  [key: string]: OptionItem & { icon: React.ComponentType<IconProps> };
} = {
  BUSINESS: {
    labelId: 'Traveler_Enumeration.Review.Type_Of_Travel.Business',
    value: 'BUSINESS',
    icon: props => <BusinessNew {...props} />,
  },
  FAMILY: {
    labelId: 'Traveler_Enumeration.Review.Type_Of_Travel.Family',
    value: 'FAMILY',
    icon: props => <FamilyNew {...props} />,
  },
  FRIENDS: {
    labelId: 'Traveler_Enumeration.Review.Type_Of_Travel.Friends',
    value: 'FRIENDS',
    icon: props => <FriendsNew {...props} />,
  },
  COUPLE: {
    labelId: 'Traveler_Enumeration.Review.Type_Of_Travel.Couple',
    value: 'COUPLE',
    icon: props => <CoupleNew {...props} />,
  },
  SOLO: {
    labelId: 'Traveler_Enumeration.Review.Type_Of_Travel.Solo',
    value: 'SOLO',
    icon: props => <SoloNew {...props} />,
  },
  OTHERS: {
    labelId: 'Traveler_Enumeration.Review.Type_Of_Travel.Others',
    value: 'OTHERS',
    icon: props => <OthersNew {...props} />,
  },
};

/**********************************
 * AGE GROUP
 *********************************/

export const AGE_GROUPS: { [key: string]: OptionItem } = {
  '10': {
    labelId: 'Traveler_Enumeration.Review.Age Range.10',
    value: '10',
  },
  '20': {
    labelId: 'Traveler_Enumeration.Review.Age Range.20',
    value: '20',
  },
  '30': {
    labelId: 'Traveler_Enumeration.Review.Age Range.30',
    value: '30',
  },
  '40': {
    labelId: 'Traveler_Enumeration.Review.Age Range.40',
    value: '40',
  },
  '50': {
    labelId: 'Traveler_Enumeration.Review.Age Range.50',
    value: '50',
  },
  '60': {
    labelId: 'Traveler_Enumeration.Review.Age Range.60',
    value: '60',
  },
  '70': {
    labelId: 'Traveler_Enumeration.Review.Age Range.70',
    value: '70',
  },
  '80': {
    labelId: 'Traveler_Enumeration.Review.Age Range.80',
    value: '80',
  },
};

export const REVIEW_DEFAULT_LIMIT_FOR_WEB = 5;
export const REVIEW_DEFAULT_LIMIT_FOR_MOBILE = 10;
