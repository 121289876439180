import { fetchLegacyReservationDetails } from 'store/legacyReservationDetails/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type SearchProps = {
  params: { reservationId: string };
  query: { email: string };
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  return Promise.all([
    dispatch(fetchLegacyReservationDetails(search.params.reservationId, search.query.email || '')),
  ]);
};
