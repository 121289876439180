import { AppThunk, createAction, createAsyncAction } from 'typesafe-actions';

import { getDictionary } from '@travel/i18n';
import { getTranslation } from '@travel/translation';

import { getItem as getProviderInfo } from 'store/providerInformation/selectors';
import { reportReview, voteReview } from 'store/providerReview/api';
import { updateSEOData } from 'store/seo/actions';

import paths from 'core/universalRouter/paths';
import {
  Errors,
  ProviderReviewDetail,
  ProviderReviewList,
  ProviderSEOReviewList,
  ReviewItemErrors,
  SearchRequestBody,
} from 'ProviderReview-Types';

import { fetchProviderGuestReviewAPI, fetchProviderReviewItem, fetchReviewListItem } from './api';
import { getProviderGuestReview } from './selectors';

export const updaterReportReviewAsync = createAsyncAction(
  'REPORT_REVIEW_REQUEST',
  'REPORT_REVIEW_SUCCESS',
  'REPORT_REVIEW_FAILURE',
)<string, { reviewId: string; count: number }, { reviewId: string; errors: Array<Errors> }>();

export const updaterVoteReviewAsync = createAsyncAction(
  'VOTE_REVIEW_REQUEST',
  'VOTE_REVIEW_SUCCESS',
  'VOTE_REVIEW_FAILURE',
)<string, { reviewId: string; count: number }, { reviewId: string; errors: Array<Errors> }>();

export const updaterReportReview = (reviewId: string): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(updaterReportReviewAsync.request(reviewId));

  try {
    const res = await reportReview(apiClient, reviewId);
    dispatch(updaterReportReviewAsync.success({ reviewId, count: res.count }));
  } catch (error) {
    dispatch(updaterReportReviewAsync.failure({ reviewId, errors: [error] as Errors[] }));
  }
};

export const updaterVoteReview = (reviewId: string): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(updaterVoteReviewAsync.request(reviewId));

  try {
    const res = await voteReview(apiClient, reviewId);
    dispatch(updaterVoteReviewAsync.success({ reviewId, count: res.count }));
  } catch (error) {
    dispatch(updaterVoteReviewAsync.failure({ reviewId, errors: [error] as Errors[] }));
  }
};

/**
 * Provider info page
 */
export const fetchReviewListAsync = createAsyncAction(
  'FETCH_REVIEW_LIST_REQUEST',
  'FETCH_REVIEW_LIST_SUCCESS',
  'FETCH_REVIEW_LIST_FAILURE',
)<undefined, ProviderReviewList, Array<Errors>>();

export const fetchMoreReviewListAsync = createAsyncAction(
  'FETCH_MORE_REVIEW_LIST_REQUEST',
  'FETCH_MORE_REVIEW_LIST_SUCCESS',
  'FETCH_MORE_REVIEW_LIST_FAILURE',
)<undefined, ProviderReviewList, Array<Errors>>();

export const fetchReviewList = (providerId: string, req: SearchRequestBody): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchReviewListAsync.request());

  try {
    const response = await fetchReviewListItem(apiClient, providerId, req);
    dispatch(fetchReviewListAsync.success(response));
  } catch (error) {
    dispatch(fetchReviewListAsync.failure([error] as Errors[]));
  }
};

export const fetchMoreReviewList = (providerId: string, req: SearchRequestBody): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchMoreReviewListAsync.request());

  try {
    const response = await fetchReviewListItem(apiClient, providerId, req);
    dispatch(fetchMoreReviewListAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreReviewListAsync.failure([error] as Errors[]));
  }
};

/**
 * SEO page
 */
export const fetchProviderGuestReviewAsync = createAsyncAction(
  'FETCH_PROVIDER_GUEST_REVIEW_REQUEST',
  'FETCH_PROVIDER_GUEST_REVIEW_SUCCESS',
  'FETCH_PROVIDER_GUEST_REVIEW_FAILURE',
)<undefined, ProviderSEOReviewList, Array<Errors>>();

export const fetchMoreProviderGuestReviewAsync = createAsyncAction(
  'FETCH_MORE_PROVIDER_GUEST_REVIEW_REQUEST',
  'FETCH_MORE_PROVIDER_GUEST_REVIEW_SUCCESS',
  'FETCH_MORE_PROVIDER_GUEST_REVIEW_FAILURE',
)<undefined, ProviderSEOReviewList, Array<Errors>>();

export const fetchProvideGuestReview = (id: string, req: SearchRequestBody): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchProviderGuestReviewAsync.request());

  try {
    const response = await fetchProviderGuestReviewAPI(apiClient, id, req);
    dispatch(fetchProviderGuestReviewAsync.success(response));
  } catch (error) {
    dispatch(fetchProviderGuestReviewAsync.failure([error] as Errors[]));
  }
};

export const fetchMoreProvideGuestReview = (id: string, req: SearchRequestBody): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchMoreProviderGuestReviewAsync.request());

  try {
    const response = await fetchProviderGuestReviewAPI(apiClient, id, req);
    dispatch(fetchMoreProviderGuestReviewAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreProviderGuestReviewAsync.failure([error] as Errors[]));
  }
};

export const fetchProviderGuestReviewSEOData = (): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const dictionary = getDictionary(state);
  const { provider, pathId: reviewPathId, total } = getProviderGuestReview(state);
  const { pathId: infoPathId, isTest } = getProviderInfo(state);

  dispatch(
    updateSEOData({
      title: getTranslation(
        {
          id: 'SEO.ProviderInformation.GuestReview.Title',
          data: { provider: provider?.name },
        },
        dictionary,
      ).join(''),
      description: getTranslation(
        {
          id: 'SEO.ProviderInformation.GuestReview.Meta.Description',
          data: { provider: provider?.name },
        },
        dictionary,
      ).join(''),
      isIndexedByDefault: !isTest && Number(total) > 0,
      canonicalPath: paths.providerReviewInfo.canonicalPathResolver(reviewPathId || infoPathId),
    }),
  );
};

export const fetchReviewItemAsync = createAsyncAction(
  'FETCH_REVIEW_ITEM_REQUEST',
  'FETCH_REVIEW_ITEM_SUCCESS',
  'FETCH_REVIEW_ITEM_FAILURE',
)<undefined, ProviderReviewDetail, ReviewItemErrors>();

export const fetchProviderReviewItemData = (id: string): AppThunk<void> => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchReviewItemAsync.request());

  try {
    const response = await fetchProviderReviewItem(apiClient, id);
    dispatch(fetchReviewItemAsync.success(response));
  } catch (error) {
    dispatch(fetchReviewItemAsync.failure({ [id]: error }));
  }
};

export const clearError = createAction('CLEAR_REVIEW_ERROR', action => {
  return (reviewId: string) => action({ reviewId });
});
