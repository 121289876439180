import React from 'react';
import dayjs from 'dayjs';
import Translate from '@travel/traveler-core/components/Translate';
import { DEFAULT_ADULT } from '@travel/traveler-core/constants';

import { SearchFormQuery } from '../types/providerList';

function usePriceLabel(searchParam?: SearchFormQuery) {
  const adultCount = searchParam?.adults ? parseInt(searchParam.adults) : DEFAULT_ADULT;
  const childCOunt = searchParam?.childrenAges ? searchParam.childrenAges.split(',').length : 0;
  const guestCount = adultCount + childCOunt;

  const nightCount =
    searchParam?.startDate && searchParam?.endDate
      ? dayjs(searchParam.endDate).diff(dayjs(searchParam.startDate), 'day')
      : 1;

  const chargeUnitLabel = !Boolean(adultCount && nightCount) && (
    <Translate id="Provider_Info.Room_List.Plan_Info.Room" data={{ price: '' }} />
  );
  const roomsLabelText = Boolean(adultCount && nightCount) && (
    <Translate
      id="Common_Plan_Details.Reservation_Details.Number_Rooms.Rooms"
      count={1}
      data={{ room_count: 1 }}
    />
  );
  const guestLabel = (
    <Translate
      id="Provider_Info.Filter.Guest_Total"
      count={guestCount}
      data={{
        total_number_guests: guestCount,
      }}
    />
  );
  const nightLabel = (
    <Translate
      id="Provider_List.Search_Results.Nights"
      count={nightCount}
      data={{ length_of_stay: nightCount }}
    />
  );

  return {
    chargeUnitLabel,
    roomsLabelText,
    guestLabel,
    nightLabel,
    guestCount,
  };
}

export default usePriceLabel;
