import { GetTranslation } from '@travel/translation';

export const NAME_INPUT_REGEX: RegExp = /^[A-Za-z\s,.-]*$/;
export const CHAR_INPUT_REGEX: RegExp = /[A-Za-z\s,.-]/;

export const getFIRST_NAME = (getTranslation: GetTranslation) => ({
  type: 'required',
  params: [
    getTranslation({
      id: 'Traveler_Input_Error.Booking_Step_1.First_Name.Enter',
    }),
  ],
});

export const getLAST_NAME = (getTranslation: GetTranslation) => ({
  type: 'required',
  params: [
    getTranslation({
      id: 'Traveler_Input_Error.Booking_Step_1.Last_Name.Enter',
    }),
  ],
});

export const getCOUNTRY_CODE = (getTranslation: GetTranslation) => ({
  type: 'required',
  params: [
    getTranslation({
      id: 'Traveler_Input_Error.Edit_Basic_Info.Phone_Number.Enter_Valid_Phone_Code',
    }),
  ],
});

export const getPHONE_NUM = (getTranslation: GetTranslation) => ({
  type: 'required',
  params: [
    getTranslation({
      id: 'Traveler_Input_Error.Edit_Basic_Info.Phone_Number.Enter_Valid_Phone',
    }),
  ],
});

export const makeValidations = (getTranslation: GetTranslation) => [
  {
    fieldName: 'applicant',
    validationType: 'object{}',
    validationRules: [
      {
        fieldName: 'firstName',
        validationType: 'string',
        validationRules: [getFIRST_NAME(getTranslation)],
      },
      {
        fieldName: 'lastName',
        validationType: 'string',
        validationRules: [getLAST_NAME(getTranslation)],
      },
      {
        fieldName: 'phoneCountryCode',
        validationType: 'string',
        validationRules: [getCOUNTRY_CODE(getTranslation)],
      },
      {
        fieldName: 'phoneNumber',
        validationType: 'string',
        validationRules: [getPHONE_NUM(getTranslation)],
      },
    ],
  },
];
