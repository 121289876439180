import { ApiClient } from '@travel/api-client';

import {
  ReceiptPreview,
  ReceiptPreviewPostValue,
  Receipts,
  ReservationDetailsItems,
} from 'ReservationDetails-Types';

export const fetchReservationDetailsItems = (
  http: ApiClient,
  reservationId: string,
): Promise<ReservationDetailsItems> =>
  http
    .get(`/consumer/mypage/reservation/details/${reservationId}`, {
      method: 'GET',
      headers: {
        'trv-currency': 'USD',
      },
      withAuthCode: true,
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .then((data: ReservationDetailsItems) => {
      return data;
    });

export const fetchReservationReceipts = (
  http: ApiClient,
  bookingId: string,
  reservationId: string,
): Promise<Receipts> =>
  http
    .get(`/receipt/summary`, {
      method: 'GET',
      values: {
        bookingId,
        reservationId,
      },
      headers: {
        'trv-currency': 'USD',
      },
    })
    .catch((error: Error) => {
      return error;
    })
    .then((data: Receipts) => {
      return data;
    });

export const fetchReceiptPreview = (
  http: ApiClient,
  data: ReceiptPreviewPostValue,
): Promise<ReceiptPreview[]> =>
  http
    .post('/receipt/receiptPreviewJson', {
      values: data,
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .then((data: ReceiptPreview[]) => {
      return data;
    });

export const fetchReceiptsDownload = (
  http: ApiClient,
  data: ReceiptPreviewPostValue,
): Promise<any> =>
  http
    .post('/receipt/download', {
      headers: {
        'Response-Type': 'blob',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      values: data,
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .then((data: any) => {
      return data;
    });

export const resendToInputEmailAddress = (
  http: ApiClient,
  reservationId: string,
  email: string,
): Promise<any> =>
  http.post(
    `/notification-gateway-api/extranet/reservations/${reservationId}/notifications/email/latest`,
    {
      values: { emailAddress: email },
      ignoreCommonErrorHandler: true,
    },
  );
