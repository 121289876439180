import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { MarketType } from '@travel/i18n';

import { MarketItems } from 'Markets-Types';

import { fetchPreferredMarketAsync, setIpAddress, setMarkets } from './actions';

export const items = createReducer([] as MarketItems).handleAction(
  setMarkets,
  (_state, action) => action.payload,
);

export const ipAddress = createReducer('').handleAction(
  setIpAddress,
  (_state, action) => action.payload,
);

// null: initial state
export const preferredMarket = createReducer(null as MarketType | null)
  .handleAction(fetchPreferredMarketAsync.success, (_state, action) => action.payload)
  .handleAction(fetchPreferredMarketAsync.failure, () => null);

export const isFetchingPreferredMarket = createReducer(false)
  .handleAction(fetchPreferredMarketAsync.request, () => true)
  .handleAction(
    [fetchPreferredMarketAsync.failure, fetchPreferredMarketAsync.success],
    () => false,
  );

const marketsReducer = combineReducers({
  items,
  ipAddress,
  preferredMarket,
  isFetchingPreferredMarket,
});

export default marketsReducer;
export type MarketsState = ReturnType<typeof marketsReducer>;
