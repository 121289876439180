import React, {
  createContext,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Action, History, Location } from 'history';

import { getLanguage, getMarket } from '@travel/i18n';
import { useGetTranslation } from '@travel/traveler-core/hooks';

import { locationChangeBegin, locationChangeEnd } from 'store/__router/actions';
import { clearCommonError, setIsUIImplementationError } from 'store/commonError/actions';
import { getIsMaintenanceMode, getIsUIImplementationError } from 'store/commonError/selectors';
import { getMember } from 'store/member/selectors';

import { getURLPagePathName, isDefaultTopPagePath } from 'core/universalRouter/paths';

type Props = {
  router: { resolve: (location: Location) => Promise<ReactNode> };
  history: History;
  children: ReactNode;
};

type HistoryState = {
  /* to define whether it should update the route component or not */
  shouldUpdate: boolean;
};

type RouterContextType = {
  history: History;
};

const RouterContext = createContext<RouterContextType>({} as RouterContextType);
RouterContext.displayName = 'Router';

function Router(props: Props) {
  const { router, history, children } = props;
  const dispatch = useDispatch();
  const currentLocation = useRef<Location>();
  const currentAction = useRef<Action>();
  const prevLocationState = useRef<HistoryState>();
  const [Component, setComponent] = useState(children);

  const getTranslation = useGetTranslation();

  const isUIImplementationError = useSelector(getIsUIImplementationError);
  const market = useSelector(getMarket);
  const language = useSelector(getLanguage);
  const { isMember } = useSelector(getMember);
  const isMaintenanceMode = useSelector(getIsMaintenanceMode);

  useEffect(() => {
    let unListen = history.listen((location, action) => {
      let shouldUpdate = true;

      if (location.state?.shouldUpdate !== undefined) {
        shouldUpdate = location.state.shouldUpdate;
      }

      if (action === 'POP' && prevLocationState.current?.shouldUpdate !== undefined) {
        shouldUpdate = prevLocationState.current.shouldUpdate;
      }

      if (!shouldUpdate) {
        prevLocationState.current = location.state;
        return;
      }

      if (!isMaintenanceMode) {
        dispatch(clearCommonError());
      }

      dispatch(locationChangeBegin());
      currentLocation.current = location;
      currentAction.current = action;

      // we have to replace the URL on CSR since we cannot update baseURL without fully reload the site
      // in case of other page url navigate to Top page on usa/en-us
      if (
        isDefaultTopPagePath(market?.marketCode, language, `${location.pathname}${location.hash}`)
      ) {
        window.history.replaceState(
          {},
          getTranslation({ id: 'SEO.Top.Title' }),
          window.location.origin,
        );
      }

      router.resolve(location).then((NextComponent: ReactNode) => {
        // XXX: if location doesn't match current location, it means a user triggered location change
        // before the previous one has been resolved. In this situation we skip the previous location change.
        if (location === currentLocation.current && action === currentAction.current) {
          // To reset the error from <ErrorBoundary />, we do need to fully reload the page since we track the error from getDerivedStateFromError
          if (isUIImplementationError && typeof window !== 'undefined') {
            dispatch(setIsUIImplementationError(false));

            window.location.assign(
              `${window.location.origin}${getURLPagePathName(
                market?.marketCode,
                language,
                location.pathname,
              )}${location.search}`,
            );
          } else {
            dispatch(locationChangeEnd({ location, action }));
            setComponent(NextComponent);
          }
        }
      });
    });

    return () => unListen();
  }, [
    dispatch,
    history,
    isMaintenanceMode,
    isMember,
    isUIImplementationError,
    language,
    market,
    router,
    getTranslation,
  ]);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      // We need to stop browser's default scroll restoration, because browser will restore the scroll position right
      // after history.push (or pop/...), but sometimes we don't render the next page immediately. This will cause
      // unexpected flash on previous page
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  // XXX: This is a hacky solution. I'm using useLayoutEffect here is just to make sure that if any child component
  // wants to overwrite the scroll position, it can use useEffect because useEffect will be excuted after useLayoutEffect.
  // We need to find another solution and change it to use useEffect if this solution brings any performance issue
  useLayoutEffect(() => {
    if (currentAction.current !== 'REPLACE') {
      window.scrollTo(0, 0);
    }
  }, [Component]);

  return <RouterContext.Provider value={{ history }}>{Component}</RouterContext.Provider>;
}

export { RouterContext };

export default Router;
