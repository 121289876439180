import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setLoadingStatus } from 'store/pageLoading/actions';

import styles from './errorPage.module.scss';

type Props = {
  /** Node of icon to be displayed */
  icon: ReactElement;
  /** String of main error text */
  mainText: ReactNode;
  /** String of sub error text */
  subText?: ReactNode;
  /** Node of link to displayed at the bottom */
  link?: ReactNode;
};

function ErrorPage(props: Props) {
  // always reset page loading when the error page is shown
  const dispatch = useDispatch();

  useEffect(() => {
    // Force reset page loading when the error page is shown
    dispatch(setLoadingStatus({ isPageLoading: false, pageName: 'error' }));
  }, [dispatch]);

  return (
    <div data-testid="errorPage-wrapper" className={styles.wrapper}>
      {React.cloneElement(props.icon, {
        size: 150,
        color: 'pastelGray',
        className: styles.icon,
      })}
      <p className={styles.mainText}>{props.mainText}</p>
      {props.subText && <p className={styles.subText}>{props.subText}</p>}
      {props.link && <span className={styles.link}>{props.link}</span>}
    </div>
  );
}

export default ErrorPage;
