import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  ReservationListErrors,
  ReservationListItems,
  ReservationListPostBodyType,
  STATUS_TYPE,
} from 'ReservationList-Types';

import { fetchReservationListItems } from './api';

export const fetchReservationListAsync = createAsyncAction(
  'FETCH_RESERVATION_LIST_REQUEST',
  'FETCH_RESERVATION_LIST_SUCCESS',
  'FETCH_RESERVATION_LIST_FAILURE',
)<undefined, ReservationListItems, ReservationListErrors[]>();

export const setLastStatusType = createStandardAction('SET_LAST_STATUS_TYPE')<STATUS_TYPE>();

export const fetchReservationList = (req: ReservationListPostBodyType): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReservationListAsync.request());
  try {
    const response = await fetchReservationListItems(apiClient, req);
    dispatch(fetchReservationListAsync.success(response));
  } catch (error) {
    dispatch(fetchReservationListAsync.failure(error));
  }
};

export const fetchMoreReservationsAsync = createAsyncAction(
  'FETCH_MORE_RESERVATIONS_REQUEST',
  'FETCH_MORE_RESERVATIONS_SUCCESS',
  'FETCH_MORE_RESERVATIONS_FAILURE',
)<undefined, ReservationListItems, ReservationListErrors[]>();

export const fetchMoreReservations = (req: ReservationListPostBodyType): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMoreReservationsAsync.request());
  try {
    const response = await fetchReservationListItems(apiClient, req);
    dispatch(fetchMoreReservationsAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreReservationsAsync.failure(error));
  }
};
