import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { setIsFindARoomBtnPresent } from './actions';

export const isFindARoomBtnPresent = createReducer(false).handleAction(
  setIsFindARoomBtnPresent,
  (state, action) => action.payload,
);

const findARoomBtnReducer = combineReducers({
  isFindARoomBtnPresent,
});

export default findARoomBtnReducer;
export type FindARoomBtnState = ReturnType<typeof findARoomBtnReducer>;
