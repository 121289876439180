import { SEOExtraParams } from '@travel/traveler-core/utils/seo';

import { SalesPromotionFormQuery } from 'components/SalesPromotionInput';

import { setPreviousPageName } from 'store/__router/actions';
import { fetchProviderInformation } from 'store/providerInformation/actions';
import { fetchProviderList } from 'store/providerList/actions';
import { fetchProviderRatePlan } from 'store/providerRatePlan/actions';
import { fetchCouponDetails } from 'store/salesPromotion/actions';
import { getCouponDetails } from 'store/salesPromotion/selectors';

import { EMPTY_OBJECT } from 'constants/defaultValue';
import { universalRouterProps } from 'core/universalRouter/types';
import { SearchFormQuery } from 'ProviderList-Types';

import { getRatePlanRequestBody } from '../ProviderInformationPage/resolver';
import { ListTypes } from '../ProviderListPage/components/ProviderList';
import { RoomFormQuery } from '../ProviderListPage/components/SearchForm';
import { getRequestBody } from '../ProviderListPage/resolver';
import { PAGE_NAME } from './';

type PlanFilterQuery = {
  minPrice?: number | string | null;
  maxPrice?: number | string | null;
  features?: Array<string> | string;
  sortKey?: string;
};

type URLParams = { pageIdentifier: string; locationPath: string };

//location only for map API
type Location = {
  lat?: number;
  lng?: number;
  zoomLevel?: number;
};

type QueryParams = RoomFormQuery &
  PlanFilterQuery &
  SalesPromotionFormQuery &
  SearchFormQuery &
  ListTypes &
  SEOExtraParams &
  Location;

type SearchProps = {
  params: URLParams;
  query?: QueryParams;
};

export default async ({ store }: universalRouterProps, search: SearchProps) => {
  const { dispatch } = store;
  const query = (search && search.query) || (EMPTY_OBJECT as QueryParams);
  const couponId = search.query?.salesPromotionId;

  let couponDetail;
  if (couponId) {
    await dispatch(fetchCouponDetails(couponId));
    couponDetail = getCouponDetails(couponId)(store.getState());
  }

  const state = store.getState();
  const errorCode = state.bookingStep?.errors?.errors?.[0];
  const isProviderAvailable = errorCode?.code !== 'PROVIDER_UNAVAILABLE';
  const providerPathId = state.providerInformation.item.pathId;

  // if provider is unavailable we should guide user to the area which provider in
  const providerPathIdArray = providerPathId?.split('/');
  if (providerPathIdArray?.length > 2) {
    providerPathIdArray?.pop();
  }
  const newPathId = providerPathIdArray?.join('/');

  const pathId = isProviderAvailable && providerPathId ? providerPathId : newPathId;

  const requestBody = getRatePlanRequestBody(search.params.pageIdentifier, query, couponDetail);
  const providerRequestBody = getRequestBody(search, couponDetail);

  dispatch(setPreviousPageName(PAGE_NAME[errorCode?.code || 'PLAN_UNAVAILABLE']));

  return Promise.all([
    dispatch(fetchProviderInformation(search.params.pageIdentifier)),
    dispatch(fetchProviderRatePlan(requestBody)),
    pathId && dispatch(fetchProviderList({ ...providerRequestBody, pathId: pathId })),
  ]);
};
