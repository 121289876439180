import { fetchCouponDetails } from 'store/salesPromotion/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type SearchProps = {
  params: { couponId: string };
};

export default ({ store: { dispatch } }: universalRouterProps, { params }: SearchProps) => {
  return Promise.all([dispatch(fetchCouponDetails(params.couponId, true, false))]);
};
