import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export type Props = {
  next: () => void;
  hasMore: boolean;
  children: React.ReactNode;
  loader: React.ReactNode;
  isFetching: boolean;
};

function InfiniteScroll(props: Props) {
  const [ref, inView] = useInView({ rootMargin: '0px' });
  const { isFetching, hasMore, next } = props;

  useEffect(() => {
    if (!isFetching && inView && hasMore) {
      next?.();
    }
  }, [inView, hasMore, isFetching, next]);

  return (
    <div>
      {props.children}
      {!isFetching && <div ref={ref} />}
      {hasMore && props.loader}
    </div>
  );
}

export default InfiniteScroll;
