// TODOs: Kindly remove this once the icon components are implemented
import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

function SvgIcon(props: Props) {
  return <svg viewBox={'0 0 24 24'}>{props.children}</svg>;
}

export const LeftIcon = () => {
  return (
    <SvgIcon>
      <path d="M15.619 4.2l.887.846L9.263 12l7.243 6.954-.887.846L7.494 12z" />
    </SvgIcon>
  );
};

export const RightIcon = () => {
  return (
    <SvgIcon>
      <path d="M7.984 20L7 19.132 15.037 12 7 4.868 7.984 4 17 12z" />
    </SvgIcon>
  );
};
