import { fetchBookmarks } from 'store/bookmarks/actions';
import { setDefaultSEOData } from 'store/seo/actions';

import { universalRouterProps } from 'core/universalRouter/types';

const DEFAULT_LIMIT = 5;
const DEFAULT_OFFSET = 0;

type SearchProps = {
  query: { offset?: number; limit?: number };
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  const offsetLimitQuery = {
    offset: search.query.offset || DEFAULT_OFFSET,
    limit: search.query.limit || DEFAULT_LIMIT,
  };
  return Promise.all([dispatch(fetchBookmarks(offsetLimitQuery)), dispatch(setDefaultSEOData())]);
};
