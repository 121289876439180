import { ApiClient } from '@travel/api-client';

import { LegacyReservationDetailsItems } from 'LegacyReservationDetails-Types';

export const fetchLegacyReservationDetailsItems = (
  http: ApiClient,
  reservationId: string,
  email: string,
  ignoreCommonErrorHandler?: boolean,
): Promise<LegacyReservationDetailsItems> =>
  http.post(`/consumer/mypage/maui_reservation/details/${reservationId}`, {
    method: 'POST',
    withAuthCode: true,
    ignoreCommonErrorHandler: ignoreCommonErrorHandler,
    values: { email: email },
  });
