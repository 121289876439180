export const fetchShortUrl = (url: string) => {
  const body = JSON.stringify({ url });

  return fetch('/icr/shortURL', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then(async res => await res.text());
};
