import { JSONparse } from './helper';

export function LSsetItem<T>(key: string, data: T) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.localStorage || !window.JSON || !key) {
    return;
  }

  return localStorage.setItem(key, JSON.stringify(data));
}

export function LSgetItem<T>(key: string): T | void {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.localStorage || !window.JSON || !key) {
    return;
  }
  const item = localStorage.getItem(key);

  if (!item) {
    return;
  }

  return JSONparse<T>(item);
}

export function LSremoveItem(key: string) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.localStorage || !window.JSON || !key) {
    return;
  }
  localStorage.removeItem(key);
}
