import { ApiClient } from '@travel/api-client';

import { ProviderRatePlan } from 'ProviderRatePlan-Types';

import { SearchRequestBody } from '../../pages/ProviderInformationPage/resolver';

export const fetchAll = (http: ApiClient, req: SearchRequestBody): Promise<ProviderRatePlan> =>
  http.post(`/monterosa-api/providers/item-plan-summaries`, {
    values: req,
    ignoreCommonErrorHandler: true,
  });
