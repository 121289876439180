import loadable from '@loadable/component';
import React, { ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AttentionDisable } from '@travel/icons/ui';
import useDeviceType, { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';
import useMemberKeepAlive from '@travel/traveler-core/hooks/useMemberKeepAlive';
import { LoadingMask } from '@travel/ui';

import OfflinePage from 'pages/OfflinePage';

import { backLocation } from 'store/__router/actions';
import { getLocation } from 'store/__router/selectors';
import { refreshAuthCode } from 'store/authCode/actions';
import { setIsUIImplementationError } from 'store/commonError/actions';
import {
  getCommonError,
  getIsMaintenanceMode,
  getIsUIImplementationError,
} from 'store/commonError/selectors';
import { getMember } from 'store/member/selectors';
import { getIsLoading } from 'store/pageLoading/selectors';
import { getSEOData } from 'store/seo/selectors';

import { Translate } from 'core/translate';
import { Link } from 'core/universalRouter/Link';
import paths, { baseUrl } from 'core/universalRouter/paths';
import useConnectionHandler from 'hooks/useConnectionHandler';
import usePreventIOSZoomingOnInputFocusing from 'hooks/usePreventIOSZoomingOnInputFocusing';
import { SearchFormQuery } from 'ProviderList-Types';

import BusyPage from '../../pages/BusyPage';
import ErrorPage from '../../pages/ErrorPage';
import ErrorBoundary from './components/ErrorBoundary';
import EsimMainContent from './components/EsimMainContent';
import Layout from './components/Layout';
import MainContent from './components/MainContent';
import SEO from './components/SEO';

import './base.scss';
import style from './app.module.scss';

const Header = loadable(() =>
  import(/* webpackChunkName: "component-Header" */ 'components/Header'),
);
const Footer = loadable(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackFetchPriority: "low" */
      /* webpackChunkName: "component-Footer" */
      './components/Footer'
    ),
  { ssr: false },
);

type Props = {
  params: { pageIdentifier: string };
  query: SearchFormQuery;
  children: ReactNode;
};

function App(props: Props) {
  const dispatch = useDispatch();
  const seoData = useSelector(getSEOData);
  const isPageLoading = useSelector(getIsLoading);
  const { isMember } = useSelector(getMember);

  const { commonError } = useSelector(getCommonError);
  const isMaintenanceMode = useSelector(getIsMaintenanceMode);
  const isUIImplementationError = useSelector(getIsUIImplementationError);
  const { isOnline } = useConnectionHandler(true);

  usePreventIOSZoomingOnInputFocusing();

  // refresh token
  const refreshTokenHookCallback = useCallback(() => {
    dispatch(refreshAuthCode());
  }, [dispatch]);

  useMemberKeepAlive(isMember, refreshTokenHookCallback);

  // Footer appearance handler
  const location = useSelector(getLocation);
  // NOTE: Since the provider list page (mobile) will be covered up with map
  // We have to render <Footer /> inside the content part. The common one should not be rendered
  const shouldHideFooter =
    useDeviceType() === MOBILE_SCREEN && location.pathname.includes(baseUrl.providerList);

  const isTopPage = seoData?.canonicalPath === '/';

  let mainContent;

  const setUIError = useCallback(() => {
    dispatch(setIsUIImplementationError(true));
  }, [dispatch]);

  if (isMaintenanceMode) {
    mainContent = <BusyPage />;
  } else if (commonError.httpStatusCode === 403 || commonError.httpStatusCode === 404) {
    // Exceptional Error: 403 Forbidden 404 Page Not Found
    mainContent = (
      <ErrorPage
        mainText={<Translate id="Traveler_Server_Error.a.3.Message1" />}
        link={
          <Link to={paths.top.pathResolver()}>
            <Translate id="Traveler_Server_Error.a.3.Navigation" />
          </Link>
        }
        icon={<AttentionDisable color="lightGrey" />}
      />
    );
  } else if (commonError.httpStatusCode === 408) {
    mainContent = (
      <ErrorPage
        mainText={<Translate id="Traveler_Server_Error.d.Message1" />}
        subText={<Translate id="Traveler_Server_Error.d.Message2" />}
        link={
          <button
            onClick={() => window.location.reload()}
            className={style.appNavigationButton}
            data-testid="app-error-navigation-button"
          >
            <Translate id="Traveler_Server_Error.d.Navigation" />
          </button>
        }
        icon={<AttentionDisable color="lightGrey" />}
      />
    );
  } else if (/^5/.test(String(commonError.httpStatusCode))) {
    // General Server Error + Bug
    mainContent = (
      <ErrorPage
        mainText={
          <>
            <Translate id="Traveler_Server_Error.a.1.Message1" />
            <br />
            <Translate id="Traveler_Server_Error.a.1.Message2" />
          </>
        }
        icon={<AttentionDisable color="lightGrey" />}
      />
    );
  } else if (/^4/.test(String(commonError.httpStatusCode))) {
    // General Client Error
    mainContent = (
      <ErrorPage
        mainText={<Translate id="Traveler_Server_Error.a.2.Message1" />}
        subText={<Translate id="Traveler_Server_Error.a.2.Message2" />}
        link={
          <button
            onClick={() => dispatch(backLocation())}
            className={style.appNavigationButton}
            data-testid="app-error-navigation-button"
          >
            <Translate id="Traveler_Server_Error.a.2.Navigation" />
          </button>
        }
        icon={<AttentionDisable color="lightGrey" />}
      />
    );
  } else if (commonError.isExternalSystemError || isUIImplementationError) {
    mainContent = (
      <ErrorPage
        mainText={<Translate id="Traveler_Server_Error.d.Message1" />}
        subText={<Translate id="Traveler_Server_Error.d.Message2" />}
        link={
          <Link to={paths.top.pathResolver()} data-testid="app-error-navigation-button">
            <Translate id="Traveler_Server_Error.d.Navigation" />
          </Link>
        }
        icon={<AttentionDisable color="lightGrey" />}
      />
    );
  } else if (!isOnline) {
    mainContent = <OfflinePage />;
  } else if (location.pathname.includes(baseUrl.eSimCampaign)) {
    mainContent = (
      <ErrorBoundary setUIError={setUIError}>
        <EsimMainContent>{props.children}</EsimMainContent>
      </ErrorBoundary>
    );
  } else {
    mainContent = (
      <ErrorBoundary setUIError={setUIError}>
        <MainContent>{props.children}</MainContent>
      </ErrorBoundary>
    );
  }

  return (
    <React.StrictMode>
      <Layout>
        <SEO {...seoData} />
        <Header isOnline={isOnline} />
        {mainContent}
        {!shouldHideFooter && <Footer isTopPage={isTopPage} />}
        {isPageLoading && <LoadingMask type="page" />}
      </Layout>
    </React.StrictMode>
  );
}

export default App;
