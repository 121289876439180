import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  AreaDetailsErrors,
  AreaDetailsItems,
  AreaInfoResponse,
  AreaRelatedItems,
  RecommendedProvider,
} from 'AreaDetails-Types';

import { fetchAreaDetailsAsync, fetchAreaInfoAsync, fetchAreaRelatedAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchAreaDetailsAsync.request, fetchAreaInfoAsync.request, fetchAreaRelatedAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchAreaDetailsAsync.success,
      fetchAreaDetailsAsync.failure,
      fetchAreaInfoAsync.success,
      fetchAreaInfoAsync.failure,
      fetchAreaRelatedAsync.success,
      fetchAreaRelatedAsync.failure,
    ],
    (state, action) => false,
  );

export const items = createReducer({} as AreaDetailsItems).handleAction(
  fetchAreaDetailsAsync.success,
  (_state, action) => {
    const { recommendedProviders, ...rest } = action.payload;
    const modifiedProviders = recommendedProviders.map((provider: RecommendedProvider) => {
      const { media, ...rest } = provider;
      //TODO: Remove the slice part once API implementation is done https://jira.rakuten-it.com/jira/browse/TIDP-30604
      return { ...rest, media: media.slice(0, 1) };
    });
    return { ...rest, recommendedProviders: modifiedProviders };
  },
);

export const areaInfoItems = createReducer({} as AreaInfoResponse).handleAction(
  fetchAreaInfoAsync.success,
  (_state, action) => {
    const { areas } = action.payload;
    const modifiedProviders = areas.map((provider: RecommendedProvider) => {
      const { media, ...rest } = provider;
      //TODO: Remove the slice part once API implementation is done https://jira.rakuten-it.com/jira/browse/TIDP-30604
      return { ...rest, media: media.slice(0, 1) };
    });
    return { areas: modifiedProviders };
  },
);

export const areaRelatedItems = createReducer({} as AreaRelatedItems).handleAction(
  fetchAreaRelatedAsync.success,
  (_state, action) => action.payload,
);

export const errors = createReducer({} as AreaDetailsErrors).handleAction(
  [fetchAreaDetailsAsync.failure, fetchAreaInfoAsync.failure, fetchAreaRelatedAsync.failure],
  (_state, action) => action.payload,
);

const areaDetailsReducer = combineReducers({
  isFetching,
  items,
  areaInfoItems,
  areaRelatedItems,
  errors,
});

export default areaDetailsReducer;
export type AreaDetailsState = ReturnType<typeof areaDetailsReducer>;
