import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import {
  FeatureGroup,
  ReservationDetailsItems,
  ReservationDetailsPrice,
} from 'ReservationDetails-Types';
import { formatOption } from 'utils/date';

import { ReservationDetailsState } from './reducer';

export const getItems = (state: RootState) => state.reservationDetails.items;
export const getErrors = (state: RootState) => state.reservationDetails.errors;

export const getReservationDetails = (state: RootState) => state.reservationDetails;
export const getPaymentDetails = (state: RootState) => state.reservationDetails.items.payments;
export const getReservationPrice = (state: RootState) => state.reservationDetails.items.price;
export const getItemReservationItem = (state: RootState) => state.reservationDetails.items.item;
export const getReceipts = (state: RootState) => state.reservationDetails.receipts.reservations;
export const getBookingId = (state: RootState) => state.reservationDetails.items.bookingId;
export const getReceiptPreview = (state: RootState) => state.reservationDetails.receiptPreviews;
export const getReceiptPDF = (state: RootState) => state.reservationDetails.downloadReceipts;

export const getCheckInDate = (state: RootState) => state.reservationDetails.items.checkInDate;
export const getCheckOutDate = (state: RootState) => state.reservationDetails.items.checkOutDate;
export const getLastCheckInTime = (state: RootState) =>
  state.reservationDetails.items.ratePlan.lastCheckIn;

// XXX: Update to your own implementation of computed state
export const getIsFetching = createSelector(
  getReservationDetails,
  (state: ReservationDetailsState) => state.isFetching,
);

export const getIsFetchingReceipts = createSelector(
  getReservationDetails,
  (state: ReservationDetailsState) => state.isFetchingReceipts,
);

export const getReservationTotal = createSelector(
  getReservationPrice,
  (state: ReservationDetailsPrice) => ({
    priceWithDiscountProvider: state?.priceWithDiscountProvider,
    priceWithDiscountUser: state?.priceWithDiscountUser,
    afterDiscountTotal: state?.salesPromotions[0]?.appliedAmountUser,
    userCurrency: state?.userCurrency,
    providerCurrency: state?.providerCurrency,
    earnPoint: state?.salesPromotions[0]?.points,
  }),
);

const getNormalizedFeatureGroup = (featureGroups: Array<FeatureGroup>) => {
  featureGroups.forEach((obj, groupIndex) => {
    featureGroups[groupIndex].name = obj.name;
    obj.features.forEach((feature, index) => {
      featureGroups[groupIndex].features[index].name = feature.text;
    });
  });
  return featureGroups;
};

export const getRoomInformation = createSelector(getItems, (state: ReservationDetailsItems) => {
  const item = state.item;
  const formatRoomSize = item?.roomSize?.area
    ? { ...item?.roomSize, measurementType: item?.roomSize.area }
    : item?.roomSize;

  return {
    name: item?.name,
    isNameGoogleTranslated: item?.nameGoogleTranslated,
    roomSize: formatRoomSize,
    itemVisualContent: item?.media,
    beds: item?.bedSizes,
    itemView: item?.itemView,
    capacity: item?.guestCapacity,
    itemFeatureGroups: getNormalizedFeatureGroup(item?.featureGroups || []),
    ratePlanFeatureGroups: getNormalizedFeatureGroup(state.ratePlan?.featureGroups || []),
    providerFeatureGroups: getNormalizedFeatureGroup(state.provider?.featureGroups || []),
  };
});

export const getCheckInTimes = createSelector(
  getReservationDetails,
  (state: ReservationDetailsState) =>
    formatOption({
      startCheckInTime: state.items.checkInTime,
      endCheckInTime: state.items.checkOutTime,
    }),
);

export const getArrivalTimeRange = createSelector(
  getReservationDetails,
  (state: ReservationDetailsState) =>
    formatOption({
      startCheckInTime: state.items.checkInTime,
      endCheckInTime: state.items.lastCheckInTime,
    }),
);
