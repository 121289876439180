import { compose } from 'redux';

export const isClient = typeof window !== 'undefined';
export const isTouchDevice = isClient && ('ontouchstart' in window || navigator.maxTouchPoints > 0);

export const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    isClient &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
