import { ApiClient } from '@travel/api-client';

import { ProviderListFilters, ProviderListItems } from 'ProviderList-Types';

import { SearchRequestBody } from '../../pages/ProviderListPage/resolver';

export const fetchProviderListItems = (
  http: ApiClient,
  req: SearchRequestBody,
): Promise<ProviderListItems> =>
  http
    .post('/monterosa-api/v2/provider-summaries', {
      method: 'POST',
      req: { ...req },
      values: { ...req },
    })
    .then((data: ProviderListItems) => {
      return data;
    });

export const fetchProviderListFilters = (
  http: ApiClient,
  req: SearchRequestBody,
): Promise<ProviderListFilters> =>
  http
    .post('/monterosa-api/v2/provider-summaries-faceting', {
      method: 'POST',
      req: { ...req },
      values: { ...req },
    })
    .then((data: ProviderListFilters) => {
      return data;
    });
