import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import useDeviceType, { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';

import { getHeight, getShouldDisplayWideBanner } from 'store/banner/selectors';

import styles from './components.module.scss';

type Props = {
  children: ReactNode;
};

function EsimMainContent(props: Props) {
  const isMobile = useDeviceType() === MOBILE_SCREEN;
  const bannerHeight = useSelector(getHeight);
  const shouldDisplayWideBanner = useSelector(getShouldDisplayWideBanner);

  return (
    <div
      className={styles.noFixedHeaderContent}
      style={!isMobile && shouldDisplayWideBanner ? { marginTop: bannerHeight } : undefined}
    >
      {props.children}
    </div>
  );
}

export default EsimMainContent;
