import { ApiClient } from '@travel/api-client';

import paths from 'core/universalRouter/paths';

export const fetch = (http: ApiClient, code: string, marketCode: string, language: string) =>
  http.post('/consumer/auth-endusers/member-token/omni-member-token-request', {
    headers: {
      'trv-code': code,
      'trv-redirect-uri': paths.auth.pathResolver(window.location.origin),
      'trv-language': language,
      'trv-market': marketCode,
    },
  });

export const fetchMobile = (http: ApiClient, code: string, marketCode: string, language: string) =>
  http.post('/consumer/auth-endusers/member-token/mobile-omni-member-token-request', {
    headers: {
      'trv-exchange-token': code,
      'trv-language': language,
      'trv-market': marketCode,
      'Content-Type': 'application/json',
    },
  });

export const logoutApi = (http: ApiClient) =>
  http.get('/consumer/auth-endusers/member-logout/logout', {
    ignoreCommonErrorHandler: true,
    withAuthCode: true,
  });

export const refresh = (http: ApiClient) =>
  http.get('/consumer/auth-endusers/member-refresh/token-refresh', {
    ignoreCommonErrorHandler: true,
    withAuthCode: true,
  });
