import { Store } from 'typesafe-actions';

import env from '@travel/env';
import { DEVICE_ID } from '@travel/traveler-core/constants';

import { EMPTY_OBJECT } from 'constants/defaultValue';

type RequestInterceptorOptions = {
  options: any;
  path: string;
  url: string;
  isThirdPartyApi: boolean;
};

const requestInterceptor = (store: Store) => ({
  options,
  path,
  url,
  isThirdPartyApi,
}: RequestInterceptorOptions) => {
  const state = store.getState();
  const { normalizedCookies } = state._httpRequest;
  const { currency, language, market } = state._i18n;

  const { isMobile, isTablet, os } = state.userAgent.userAgent;
  const platform = isMobile ? 'SP Web' : isTablet ? 'Tablet Web' : 'PC Web';

  const pageName = state.router.previousPageName;

  let verbose = false;
  if (normalizedCookies) {
    verbose = 'trv-very-verbose' in normalizedCookies;
  }

  // To make sure that we will send user credential only if url is under the same origin for 3rd party
  const credentials = isThirdPartyApi ? 'same-origin' : options.credentials;

  const {
    item: { token },
  } = store.getState().authCode;
  if (env('UNIVERSAL_ISGATED')) {
    return {
      url: 'http://localhost:5555/' + path,
      options: {
        ...options,
        credentials,
        headers: isThirdPartyApi
          ? EMPTY_OBJECT
          : {
              ...options.headers,
              'trv-device-id': normalizedCookies[DEVICE_ID],
              'trv-currency': currency,
            },
      },
    };
  }

  const result = {
    url,
    options: {
      ...options,
      credentials,
      headers: isThirdPartyApi
        ? EMPTY_OBJECT
        : {
            'trv-device-id': normalizedCookies[DEVICE_ID],
            'trv-currency': currency,
            'trv-language': language,
            'trv-market': market?.marketCode,
            'trv-platform': platform,
            'trv-os': os,
            'trv-page': pageName,
            ...options.headers,
          },
    },
    verbose,
  };

  if (token && !isThirdPartyApi) {
    result.options.headers.Authorization = token;
  }

  return result;
};

export default requestInterceptor;
