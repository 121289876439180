import { ogpLogo } from 'media';

import { getTranslation } from '@travel/translation';

import { fetchAreaInfo } from 'store/areaDetails/actions';
import { fetchBanner } from 'store/banner/actions';
import { fetchGeneralContents } from 'store/generalContents/actions';
import { fetchHeroImages } from 'store/heroImages/actions';
import { fetchIntroductionBanner } from 'store/introductionBanner/actions';
import { fetchPromotionBanners } from 'store/promotionBanners/actions';
import { updateSEOData } from 'store/seo/actions';
import { fetchSpecialFeatureArticles } from 'store/specialFeatureArticles/actions';
import { fetchSubscription } from 'store/subscription/actions';

import paths from 'core/universalRouter/paths';
import { universalRouterProps } from 'core/universalRouter/types';

export default ({ store: { dispatch, getState } }: universalRouterProps) => {
  const pageName = 'top';
  const {
    _i18n: { dictionary },
    router: { location },
  } = getState();

  return Promise.all([
    dispatch(fetchBanner(pageName)),
    dispatch(fetchHeroImages(pageName)),
    dispatch(fetchGeneralContents(pageName)),
    dispatch(fetchIntroductionBanner(pageName)),
    dispatch(fetchPromotionBanners(pageName)),
    dispatch(fetchSpecialFeatureArticles(pageName)),
    dispatch(fetchAreaInfo({ pathId: 'cnt_japan' }, true)),
    dispatch(fetchSubscription()),
    dispatch(
      updateSEOData({
        title: getTranslation({ id: 'SEO.Top.Title' }, dictionary).join(''),
        description: getTranslation({ id: 'SEO.Top.Meta.Description' }, dictionary).join(''),
        isIndexedByDefault: location.location.pathname === paths.top.pathResolver(),
        canonicalPath: paths.top.canonicalPathResolver(),
        imageUrl: `https://travel.rakuten.com/${ogpLogo}`,
      }),
    ),
  ]);
};
