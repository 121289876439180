import { MOBILE_SCREEN } from '@travel/traveler-core/hooks';

import { fetchProviderInformation } from 'store/providerInformation/actions';
import {
  fetchProvideGuestReview,
  fetchProviderGuestReviewSEOData,
} from 'store/providerReview/actions';
import { getSsrDeviceType } from 'store/userAgent/selectors';

import { universalRouterProps } from 'core/universalRouter/types';

import {
  REVIEW_DEFAULT_LIMIT_FOR_MOBILE,
  REVIEW_DEFAULT_LIMIT_FOR_WEB,
} from '../../constants/review';

type SearchProps = {
  params: { pageIdentifier: string };
};

export default ({ store }: universalRouterProps, search: SearchProps) => {
  const { dispatch } = store;
  const isMobile = getSsrDeviceType(store.getState()) === MOBILE_SCREEN;
  const REVIEW_DEFAULT_LIMIT = isMobile
    ? REVIEW_DEFAULT_LIMIT_FOR_MOBILE
    : REVIEW_DEFAULT_LIMIT_FOR_WEB;
  return Promise.all([
    // TODO: will be removed once the pathId has been added to the review endpoint
    dispatch(fetchProviderInformation(search.params.pageIdentifier)),
    dispatch(
      fetchProvideGuestReview(search.params.pageIdentifier, {
        offset: 0,
        limit: REVIEW_DEFAULT_LIMIT,
      }),
    ),
  ]).then(() => dispatch(fetchProviderGuestReviewSEOData()));
};
