export const LAST_USED_LANGUAGE_KEY = 'trv-user-language';
export const LAST_USED_CURRENCY_KEY = 'trv-user-currency';
export const LAST_USED_MARKET_KEY = 'trv-user-market';
export const TOKEN_KEY = 'trv-token';
export const PREVIOUS_PAGE_NAME = 'prevPageName';
export const CURRENT_PAGE_NAME = 'currPageName';
export const EXCHANGE_TOKEN = 'trv-exchange-token';
export const EXCHANGE_TOKEN_PC = 'trv-web-exchange-token';
export const DEVICE_ID = 'trv-device-id';
export const IS_IOS_WEB_VIEW = 'trv-isioswebview';
export const RAN_COOKIE = 'trv-ranData';
export const IS_GUEST_BOOKING_USER = 'isGuestBookingUser';

export const ONE_TRUST_CONSENT = 'OptanonConsent';
export const ONE_TRUST_TRACKING_ON_VAL = 'C0004:1';
