import { postModificationStep2 } from 'store/modificationStep/actions';

import { universalRouterProps } from 'core/universalRouter/types';
import { PostModificationStep2 } from 'Modification-Types';

type SearchProps = {
  params: { reservationId: string };
  query: PostModificationStep2;
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  return Promise.all([dispatch(postModificationStep2(search.params.reservationId, search.query))]);
};
