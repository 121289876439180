import { ApiClient } from '@travel/api-client';

import { LegacyReservationList, LegacyReservationListPostBody } from 'LegacyReservationList-Types';

export const fetchLegacyReservationListItems = (
  http: ApiClient,
  req: LegacyReservationListPostBody,
): Promise<LegacyReservationList> =>
  http
    .post('/consumer/mypage/maui_reservation/list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      values: { ...req },
    })
    .then((data: LegacyReservationList) => {
      return data;
    });
