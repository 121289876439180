import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { RouterState } from './reducer';

export const getRouter = (state: RootState) => state.router;

export const getLocation = createSelector(
  getRouter,
  (state: RouterState) => state.location?.location || {},
);

export const getHost = createSelector(getRouter, (state: RouterState) => state.host);

export const getEnvironment = createSelector(getRouter, (state: RouterState) => state.environment);

export const getLocationAction = createSelector(
  getRouter,
  (state: RouterState) => state.location.action,
);

export const getProtocol = createSelector(
  getRouter,
  (state: RouterState) => state.location.protocol,
);

export const getLocationPending = createSelector(
  getRouter,
  (state: RouterState) => state.location.isPending,
);

export const getPreviousPageName = createSelector(
  getRouter,
  (state: RouterState) => state.previousPageName,
);

export const getIsSSR = createSelector(getRouter, (state: RouterState) => state.isSSR);
