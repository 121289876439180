import { ApiClient } from '@travel/api-client';

import { PostEsimCampaignRequestBody } from 'PostEsimCampaigneReview-Types';

export const postEsimCampaign = (http: ApiClient, req: PostEsimCampaignRequestBody): Promise<any> =>
  http
    .post(`/consumer/mypage/esim/apply`, {
      values: req,
      withAuthCode: false,
      ignoreCommonErrorHandler: true,
    })
    .catch((err: any) => err);
