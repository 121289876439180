import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { PostReviewResponse, ReviewOptions } from 'PostReview-Types';

import { fetchPostReviewAsync, fetchPostReviewOptionsAsync } from './actions';

export const response = createReducer({} as PostReviewResponse).handleAction(
  fetchPostReviewAsync.success,
  (_state, action) => action.payload,
);

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchPostReviewAsync.request], () => true)
  .handleAction([fetchPostReviewAsync.success, fetchPostReviewAsync.failure], () => false);

export const reviewOptions = createReducer({} as ReviewOptions).handleAction(
  fetchPostReviewOptionsAsync.success,
  (_state, action) => action.payload,
);

export const errors = createReducer([] as PostReviewResponse[]).handleAction(
  fetchPostReviewAsync.failure,
  (_state, action) => action.payload,
);

const postReviewReducer = combineReducers({
  response,
  reviewOptions,
  isFetching,
  errors,
});

export default postReviewReducer;
export type PostReviewState = ReturnType<typeof postReviewReducer>;
