import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { isEmptyArray, isNotEmptyArray } from '@travel/utils';

import {
  CouponData,
  Plan,
  ProviderRatePlanErrors,
  RatePlanFilter,
  RatePlanFilterFeature,
  RatePlanItems,
} from 'ProviderRatePlan-Types';

import { acquiredCoupon, fetchProviderRatePlanAsync, setRenderSimilarProviders } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchProviderRatePlanAsync.request], () => true)
  .handleAction(
    [fetchProviderRatePlanAsync.success, fetchProviderRatePlanAsync.failure],
    () => false,
  );

export const items = createReducer([] as RatePlanItems)
  .handleAction(fetchProviderRatePlanAsync.success, (_state, action) => action.payload.items)
  .handleAction(fetchProviderRatePlanAsync.failure, () => [])
  .handleAction(acquiredCoupon, (state, action) => {
    const newState = state.reduce((result: RatePlanItems, item) => {
      const newPlans = item.plans.reduce((planResult: Array<Plan>, plan) => {
        if (
          isEmptyArray(plan.price.salesPromotions) ||
          // sales promotions are max 2 elements (MAIN / SUB)
          (plan.price.salesPromotions?.[0]?.id !== action.payload.salesPromotionId &&
            plan.price.salesPromotions?.[1]?.id !== action.payload.salesPromotionId)
        ) {
          return [...planResult, plan];
        }

        return [
          ...planResult,
          {
            ...plan,
            price: {
              ...plan.price,
              salesPromotion: { ...plan.price.salesPromotions, acquired: true },
            },
          },
        ];
      }, []);

      return [...result, { ...item, plans: newPlans }];
    }, []);
    return newState;
  });

export const filters = createReducer([] as RatePlanFilter[]).handleAction(
  fetchProviderRatePlanAsync.success,
  (_state, action) => action.payload.filters,
);

export const selectedFeatures = createReducer([] as RatePlanFilterFeature[]).handleAction(
  fetchProviderRatePlanAsync.success,
  (_state, action) =>
    action.payload.filters.reduce((acc: Array<RatePlanFilterFeature>, curr: RatePlanFilter) => {
      if (isNotEmptyArray(curr.features)) {
        const toAdd = curr.features.filter(feature => feature.selected);
        return acc.concat(toAdd);
      }
      return acc;
    }, []),
);

export const errors = createReducer([] as ProviderRatePlanErrors[]).handleAction(
  fetchProviderRatePlanAsync.failure,
  (_state, action) => action.payload,
);

export const gottenCoupon = createReducer({} as CouponData).handleAction(
  acquiredCoupon,
  (state, action) => action.payload,
);

export const shouldRenderSimilarProviders = createReducer(false as boolean)
  .handleAction([fetchProviderRatePlanAsync.request], () => false)
  .handleAction([fetchProviderRatePlanAsync.failure], () => true)
  .handleAction(fetchProviderRatePlanAsync.success, (_, action) =>
    isEmptyArray(action.payload.items),
  )
  .handleAction(setRenderSimilarProviders, (_, action) => action.payload);

const providerRatePlanReducer = combineReducers({
  gottenCoupon,
  isFetching,
  items,
  filters,
  selectedFeatures,
  errors,
  shouldRenderSimilarProviders,
});

export default providerRatePlanReducer;
export type ProviderRatePlanState = ReturnType<typeof providerRatePlanReducer>;
