import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { PageLoading } from 'PageLoading-Types';

import { setLoadingStatus } from './actions';

export const pageLoading = createReducer({
  isPageLoading: false,
  pageName: '',
} as PageLoading).handleAction(setLoadingStatus, (status, action) => action.payload);

const pageLoadingReducer = combineReducers({
  pageLoading,
});

export default pageLoadingReducer;
export type PageLoadingState = ReturnType<typeof pageLoadingReducer>;
