import dayjs from 'dayjs';

import env from '@travel/env';
import {
  ONE_TRUST_CONSENT,
  ONE_TRUST_TRACKING_ON_VAL,
  RAN_COOKIE,
} from '@travel/traveler-core/constants';
import { getCookie, setCookie } from '@travel/utils';

import { Props, TealiumObject } from '../hooks/useTealium';

import { RatObject, RatParam } from '../types/analytics';

export const convertRatToTealium = (
  ratPropsToParam: RatParam,
  props: Props,
  ratObject: RatObject,
): TealiumObject => {
  let tealiumObject: TealiumObject = {
    ...ratObject.conversion,
    ...ratObject.custom,
    ...ratObject.generic,
  };

  const flatRatParameters = [
    ...ratPropsToParam.conversion,
    ...ratPropsToParam.custom,
    ...ratPropsToParam.generic,
  ];
  for (let i = 0; i < flatRatParameters.length; i++) {
    const { prop, id } = flatRatParameters[i];
    if (props[prop]) {
      tealiumObject[id] = props[prop];
    }
  }

  return tealiumObject;
};

export function callTealium(data: TealiumObject, isWidget?: boolean) {
  const consentCookie = getCookie(ONE_TRUST_CONSENT);
  const isTrackingAllowed = consentCookie?.includes(ONE_TRUST_TRACKING_ON_VAL);

  // check if consent cookie is there
  // if it's not check until it's there.
  if (!isTrackingAllowed) {
    // This function is called after the oneTrust options are confirmed.
    window.oneTrustCheck = () => {
      const consentCookie = getCookie(ONE_TRUST_CONSENT);
      const isTrackingAllowed = consentCookie?.includes(ONE_TRUST_TRACKING_ON_VAL);
      if (isTrackingAllowed) {
        callTealium(data);

        // check queryString if the RanData is there
        const params = new URLSearchParams(window.location.search);
        const ranSiteID = params.get('ranSiteID');
        const scid = params.get('scid');
        const ranDateNum = parseInt(`${params.get('ranDate')}`);
        const newDateTime = new Date(ranDateNum).getTime();
        const ranDate = isNaN(ranDateNum) || isNaN(newDateTime) ? Date.now() : newDateTime;
        const expiredDate = ranDate + 3600000 * 24 * 30;
        const daysAgo = dayjs().diff(ranDateNum, 'days');
        // If it's there set a cookie
        if (daysAgo <= 30 && ranSiteID) {
          setCookie(RAN_COOKIE, JSON.stringify({ ranSiteID, ranDate, scid }), {
            expires: new Date(expiredDate),
            sameSite: 'strict',
            path: '/',
          });
        }
      }
    };
    // Don't continue and return the function since the user has not consented to tracking.
    return;
  }

  if (window.utag) {
    // removing old data
    Object.keys(window.utag_data).forEach(key => {
      window.utag_data[key] = undefined;
    });
    // writing new data
    Object.keys(data).forEach(key => {
      window.utag_data[key] = data[key];
    });
    window.utag.view(data);
  } else {
    window.utag_data = { ...data };
    const tagUrl = !isWidget
      ? env('UNIVERSAL_TEALIUM_TAG_URL')
      : process.env.UNIVERSAL_TEALIUM_TAG_URL;

    if (tagUrl) {
      new Promise((resolve, reject) => {
        try {
          const tealiumUtagScript = document.createElement('script');
          tealiumUtagScript.setAttribute('src', tagUrl);
          tealiumUtagScript.setAttribute('type', 'text/javascript');
          tealiumUtagScript.setAttribute('defer', 'true');
          tealiumUtagScript.setAttribute('async', 'true');

          tealiumUtagScript.addEventListener('load', () => {
            resolve({ status: true });
          });

          tealiumUtagScript.addEventListener('error', () => {
            reject({
              status: false,
              message: `Failed to load the tealium script`,
            });
          });

          document.head.appendChild(tealiumUtagScript);
        } catch (err) {
          reject(err);
        }
      })
        .then(() => {
          window.utag.view(data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
}
