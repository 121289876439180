import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { MemberState } from './reducer';

export const getItem = (state: RootState) => state.member.item;
export const getErrors = (state: RootState) => state.member.error;

export const getMember = (state: RootState) => state.member;

// XXX: Update to your own implementation of computed state
export const getIsFetching = createSelector(getMember, (state: MemberState) => state.isFetching);
