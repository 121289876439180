import { AppThunk, createAsyncAction } from 'typesafe-actions';

import {
  SpecialFeatureArticlesErrors,
  SpecialFeatureArticlesItems,
} from 'SpecialFeatureArticles-Types';

import { fetchAll } from './apis';

export const fetchSpecialFeatureArticlesAsync = createAsyncAction(
  'FETCH_SPECIAL_FEATURE_ARTICLES_REQUEST',
  'FETCH_SPECIAL_FEATURE_ARTICLES_SUCCESS',
  'FETCH_SPECIAL_FEATURE_ARTICLES_FAILURE',
)<undefined, SpecialFeatureArticlesItems[], SpecialFeatureArticlesErrors[]>();

export const fetchSpecialFeatureArticles = (page: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const { _i18n } = getState();
    const response = await fetchAll(apiClient, page, _i18n.market?.marketCode, _i18n.language);
    dispatch(fetchSpecialFeatureArticlesAsync.success(response));
  } catch (error) {
    dispatch(fetchSpecialFeatureArticlesAsync.failure(error));
  }
};
