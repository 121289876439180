import { ApiClient } from '@travel/api-client';

import { NotificationItem } from 'NotificationDetails-Types';

export const fetchNotificationDetails = (
  http: ApiClient,
  notificationId: string,
): Promise<NotificationItem> =>
  http
    .get(`/consumer/mypage/notification/detail/${notificationId}`, {
      withAuthCode: true,
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .then((data: NotificationItem) => {
      return data;
    });
