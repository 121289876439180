import { useSelector } from 'react-redux';

import { getLanguage, getMarket } from '@travel/i18n';
import { getTranslation, Dictionary } from '@travel/translation';

type Link = {
  text: string;
  url: string;
  shouldAddQueryData: boolean;
};

type StandardKey =
  | 'privacyPolicy'
  | 'termsOfUse'
  | 'about'
  | 'cookiePolicy'
  | 'doNotSellInfo'
  | 'termsOfUserReview'
  | 'cookieSetting'
  | 'faq'
  | 'couponTerms'
  | 'taiwanMembershipTerms'
  | 'taiwanPrivacyPolicy'
  | 'taiwanPointTerms';

type StandardUrl = {
  [key in StandardKey]: Link;
};

const useStandardUrl = (dictionary?: Dictionary) => {
  const { marketCode } = useSelector(getMarket);
  const language = useSelector(getLanguage);

  const market = marketCode.toLowerCase();
  const languageSetting = language.toLowerCase();

  const data = { market_code: market, language_setting: languageSetting };
  const links = {
    privacyPolicy: 'Privacy_Policy',
    termsOfUse: 'Terms_Of_Use',
    about: 'About_Us',
    cookiePolicy: 'Cookie_Policy',
    doNotSellInfo: 'Do_Not_Sell_Info',
    termsOfUserReview: 'Terms_Of_User_Reviews',
    cookieSetting: 'Cookie_Setting',
    faq: 'Faq',
    couponTerms: 'Coupon_Terms',
    taiwanMembershipTerms: 'Taiwan_Ichiba.Membership_Terms',
    taiwanPrivacyPolicy: 'Taiwan_Ichiba.Privacy_Policy',
    taiwanPointTerms: 'Taiwan_Ichiba.Point_Terms',
  };

  return Object.entries(links).reduce((result, [key, value]) => {
    return {
      ...result,
      [key]: {
        shouldAddQueryData: key !== 'faq',
        text: `Traveler_Common.Standard_Link.${value}`,
        url: getTranslation(
          {
            id: `Traveler_Common.Standard_URL.${value}`,
            data: data,
          },
          dictionary,
        ).join(''),
      },
    };
  }, {}) as StandardUrl;
};

export default useStandardUrl;
