import { fetchAcquiredCoupons } from 'store/acquiredCoupons/actions';
import { setDefaultSEOData } from 'store/seo/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type SearchProps = {
  query: { offset?: number; limit?: number };
};

export const DEFAULT_LIMIT = 60;

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  const offsetLimitQuery = {
    offset: search.query.offset,
    limit: DEFAULT_LIMIT,
  };
  return Promise.all([
    dispatch(fetchAcquiredCoupons(offsetLimitQuery)),
    dispatch(setDefaultSEOData()),
  ]);
};
