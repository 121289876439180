import { ReactNode } from 'react';

export function sortOptionsByText(a: ReactNode[], b: ReactNode[]) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}
