import { Component, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  setUIError: () => void;
};

type ErrorInfo = {
  componentStack: string;
};

type State = {
  error?: Error;
  info?: ErrorInfo;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined, info: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch() {
    const { setUIError } = this.props;
    setUIError();
  }

  render() {
    const { children } = this.props;
    const { error, info } = this.state;
    if (error !== undefined) {
      console.error('::::Errorboundary logs::::', info);
      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
