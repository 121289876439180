import { fetchPendingGuestReview } from 'store/guestReviews/actions';
import { fetchPostReviewOptions } from 'store/postReview/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type SearchProps = {
  params: { reviewId: string };
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  return Promise.all([
    dispatch(fetchPostReviewOptions(search.params.reviewId)),
    dispatch(fetchPendingGuestReview(search.params.reviewId)),
  ]);
};
