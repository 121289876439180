import { createReducer } from 'typesafe-actions';

import { setCallbackUrl } from './actions';

// TODO set it for first rendering
export const callbackUrl = createReducer('');

const omniReducer = createReducer({
  callbackUrl,
}).handleAction(setCallbackUrl, (state, action) => ({ callbackUrl: action.payload }));

export default omniReducer;
export type OmniState = ReturnType<typeof omniReducer>;
