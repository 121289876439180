import React, { useMemo, useState } from 'react';

import { useKeyboard } from '@travel/traveler-core/hooks';
import ImagePlaceHolder from '@travel/ui/components/ImagePlaceHolder';
import { MediaWithRef as UIMedia } from '@travel/ui/components/Media';
import { cx, RakutenImageConverter } from '@travel/utils';

import styles from './media.module.scss';

const ric = new RakutenImageConverter();

export type MediaType = {
  type?: string;
  alt?: string;
  url?: string;
  videoId?: string;
  videoVendorId?: string;
  thumbnailUrl?: string;
};

export type Props = {
  /** Placeholder item className */
  placeholderClassName?: string;
  /** Media item className */
  wrapperClassName?: string;
  /** Media item className */
  className?: string;
  /** Media item */
  media?: MediaType | null;
  /** Flag to define if the media is video and playable */
  isPlayableVideo?: boolean;
  /** Custom number to define srcset level generation */
  sizesLevel?: number;
  /** Custom start width for srcset generator */
  srcsetStartWidth?: number;
  /** Callback to be called when the media is clicked */
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  /** Flag to disable srcSet cache */
  isDisableSrcSetCache?: boolean;
  /** Flag to define whether the image should be displayed or not */
  isDisableRender?: boolean;
  /** Flag to define whether the lazy load will be applied or not */
  isDisableLazyload?: boolean;
  /** Flag to define whether the RIC will be applied or not */
  isDisableRIC?: boolean;
  /** Estimated displayed area to be passed to Media as sizes (first priority) */
  sizes?: string;
  /** BRIGHTCOVE_ACCOUNT_ID from .env */
  videoAccountId?: string;
  /** BRIGHTCOVE_PLAYER_ID from .env */
  videoPlayerId?: string;
  /** Experimental img attribute https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/fetchPriority */
  fetchPriority?: 'high' | 'low' | 'auto';
  /** threshold for useInView */
  threshold?: number;
  /** Should not return a div only the media component */
  isMediaOnly?: boolean;
  /** ToAdd dynamic data-test-id */
  testId?: string;
  /** Should Pause Video or not */
  shouldPauseVideo?: boolean;
};

const SRCSET_OPTIONS = { isRIC: true };

function Media(props: Props) {
  const {
    media,
    isDisableLazyload,
    isDisableSrcSetCache,
    fetchPriority,
    isMediaOnly,
    testId = 'media-item',
    shouldPauseVideo = false,
    onClick,
  } = props;
  const classes = cx(props.className, styles.media);
  const [isRICError, setIsRICError] = useState(false);
  const isVideo = media?.type?.toLowerCase() === 'video';

  let url = media?.url;
  let thumbnailUrl = media?.thumbnailUrl;
  let imgSrcset: string | undefined = undefined;
  let imgSizes = props.sizes;
  let onImageError: (() => void) | undefined = undefined;

  if (!isRICError && !props.isDisableRIC) {
    const srcsetOptions =
      Number(props.sizesLevel) > 0 || Number(props.srcsetStartWidth) > 0
        ? {
            ...SRCSET_OPTIONS,
            sizesLevel: props.sizesLevel,
            width: props.srcsetStartWidth,
            isCacheEnabled: !isDisableSrcSetCache,
          }
        : { ...SRCSET_OPTIONS, isCacheEnabled: !isDisableSrcSetCache };

    if (!isVideo && ric.validated(media?.url)) {
      url = ric.resize(media?.url);
      imgSrcset = ric.srcset(url, srcsetOptions);
      onImageError = () => setIsRICError(true);
    } else if (isVideo && ric.validated(media?.thumbnailUrl)) {
      thumbnailUrl = ric.resize(media?.thumbnailUrl);
      imgSrcset = ric.srcset(thumbnailUrl, srcsetOptions);
    }
  }

  const placeholder = useMemo(
    () => <ImagePlaceHolder className={cx(styles.media, props.placeholderClassName)} />,
    [props.placeholderClassName],
  );

  const onKeyDown = useKeyboard(
    useMemo(
      () => ({
        Submit: (event: React.KeyboardEvent) => onClick?.(event),
      }),
      [onClick],
    ),
  );

  return (
    <UIMedia
      {...media}
      wrapperClassName={props.wrapperClassName}
      className={classes}
      url={url}
      thumbnailUrl={thumbnailUrl}
      srcset={imgSrcset}
      sizes={imgSizes}
      loading={!isDisableLazyload ? 'lazy' : undefined}
      isPlayable={isVideo && props.isPlayableVideo}
      onClick={onClick}
      onKeyDown={onClick ? onKeyDown : undefined}
      placeholder={placeholder}
      videoVendorId={media?.videoVendorId}
      videoAccountId={props.videoAccountId}
      videoPlayerId={props.videoPlayerId}
      data-testid={testId}
      onImageError={onImageError}
      fetchPriority={fetchPriority}
      isMediaOnly={isMediaOnly}
      shouldPauseVideo={shouldPauseVideo}
      tabIndex={onClick ? 0 : undefined}
    />
  );
}

export default React.memo(Media);
