import { AppThunk, createAction, createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  EXCHANGE_TOKEN,
  EXCHANGE_TOKEN_PC,
  IS_GUEST_BOOKING_USER,
  LAST_USED_CURRENCY_KEY,
  TOKEN_KEY,
} from '@travel/traveler-core/constants/cookies';
import { setStrictCookie } from '@travel/utils';

import { clearSubscription } from 'store/subscription/actions';

import { AuthCodeError, AuthCodeItem } from 'AuthCode-Types';

import { fetch, fetchMobile, logoutApi, refresh } from './apis';

export const setAuthCode = createStandardAction('SET_AUTH_CODE')<string>();
export const setIsGuestBookingUser = createStandardAction('SET_IS_GUEST_BOOKING_USER')<boolean>();
export const clearMember = createAction('CLEAR_MEMBER');

export const fetchAuthCodeAsync = createAsyncAction(
  'FETCH_AUTH_CODE_REQUEST',
  'FETCH_AUTH_CODE_SUCCESS',
  'FETCH_AUTH_CODE_FAILURE',
)<undefined, AuthCodeItem, AuthCodeError[]>();

export const fetchAuthCode = (
  code: string,
  marketCode: string,
  language: string,
  isMobileToken?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(fetchAuthCodeAsync.request());
    const authCode = isMobileToken
      ? await fetchMobile(apiClient, code, marketCode, language)
      : await fetch(apiClient, code, marketCode, language);

    if (
      authCode &&
      authCode.token &&
      process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined'
    ) {
      // max-age of cookie is set to 30 minutes.
      document.cookie = `${TOKEN_KEY}=${authCode.token};path=/;samesite=strict;max-age=1800;Secure`;
    }
    dispatch(fetchAuthCodeAsync.success(authCode));
  } catch (error) {
    dispatch(fetchAuthCodeAsync.failure(error));
  }
};

export const logoutAsync = createAsyncAction('LOGOUT_REQUEST', 'LOGOUT_SUCCESS', 'LOGOUT_FAILURE')<
  undefined,
  AuthCodeItem,
  AuthCodeError[]
>();

export const logout = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(logoutAsync.request());
  await logoutApi(apiClient);

  const memberCurrency = getState()._i18n.currency;
  if (memberCurrency) {
    setStrictCookie(LAST_USED_CURRENCY_KEY, memberCurrency);
  }

  document.cookie = `${EXCHANGE_TOKEN_PC}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  document.cookie = `${EXCHANGE_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    // clear cookie on local
    document.cookie = `${TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  dispatch(logoutAsync.success({ token: '' }));
  dispatch(clearMember());
  dispatch(clearSubscription());
};

export const guestLogout = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  document.cookie = `${EXCHANGE_TOKEN_PC}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  document.cookie = `${EXCHANGE_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  document.cookie = `${IS_GUEST_BOOKING_USER}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    // clear cookie on local
    document.cookie = `${TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  dispatch(logoutAsync.success({ token: '' }));
  dispatch(clearMember());
  dispatch(clearSubscription());
};

export const refreshAuthCode = (): AppThunk<Promise<boolean>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const {
      authCode: { item },
    } = getState();

    if (!item?.token) {
      throw new Error('CLEAR_MEMBER');
    }

    const authCode = await refresh(apiClient);
    dispatch(fetchAuthCodeAsync.success(authCode));
    return true;
  } catch {
    dispatch(clearMember());
    return false;
  }
};
