import React from 'react';

import { TranslateProps } from '@travel/translation';
import { useTranslationArr } from '@travel/traveler-core/hooks/useTranslation';

function Translate(props: TranslateProps): JSX.Element {
  const value = useTranslationArr({ ...props, data: props.data || {} });
  return React.createElement(
    'span',
    {
      'data-object-id': props.id,
      'data-testid': props.id,
      className: props.className,
    },
    React.Children.toArray(value),
  );
}

export default Translate;
