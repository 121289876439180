/** [stackoverflow] How to detect Safari, Chrome, IE, Firefox and Opera browser?
 * https://stackoverflow.com/a/9851769
 * Googling for browser reliable detection often results in checking the User agent string. This method is not reliable, because it's trivial to spoof this value.
 * I've written a method to detect browsers by duck-typing. Only use the browser detection method if it's truly necessary, such as showing browser-specific instructions to install an extension.
 * Use feature detection when possible.
 *
 * rf. https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgent
 * Browser identification based on detecting the user agent string is unreliable and is not recommended, as the user agent string is user configurable
 */

// Internet Explorer 6-11
export const isIE = () =>
  /*@cc_on!@ */ false ||
  // @ts-ignore
  (typeof window !== 'undefined' && !!window.document.documentMode);
