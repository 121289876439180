import React, { useEffect, useState } from 'react';

function useIsInViewport(ref: React.RefObject<HTMLDivElement | null>) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer =
      typeof window !== 'undefined' &&
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      });

    ref.current && observer && observer.observe(ref.current);

    return () => {
      observer && observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

export default useIsInViewport;
