import { ApiClient } from '@travel/api-client';

import { SubscriptionItem } from 'Subscription-Types';

export const fetch = (http: ApiClient, email?: string): Promise<SubscriptionItem> => {
  // const values = email && { values: { email: email } };
  return http.post(`/consumer/member/subscription/isSubscribed`, {
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    values: {
      email: email,
    },
  });
};

export const subscribe = (http: ApiClient, email: string, location: number) =>
  http.post(`/consumer/member/subscription/subscribe`, {
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    values: {
      email: email,
      location: location,
    },
  });

export const unsubscribe = (http: ApiClient, email: string) =>
  http.post(`/consumer/member/subscription/unsubscribe`, {
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    values: {
      email: email,
      // currently we can only unsubscribe from account setting
      location: 0,
    },
  });
