import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { getReservationDetails } from 'store/bookingStep/selectors';

import { FeatureConfigState } from './reducer';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const getErrors = (state: RootState) => state.featureConfig.errors;

export const getFeatureConfig = (state: RootState) => state.featureConfig;

export const getIsFetching = createSelector(
  getFeatureConfig,
  (state: FeatureConfigState) => state.isFetching,
);

export const getFeatureConfigs = (state: RootState) => state.featureConfig.appFeatureConfigs;

export const getCurrentDate = createSelector(
  getFeatureConfigs,
  featureConfigDetails => featureConfigDetails.currentDate,
);

export const getESimData = createSelector(
  getFeatureConfigs,
  featureConfigDetails => featureConfigDetails?.eSimBS3Banner,
);

export const getIsWithInCampaignPeriod = createSelector(
  getESimData,
  getCurrentDate,
  (eSimBS3Banner, currentDate) => {
    /**
     * The featureConfigDetails is expected to be hosted in the CDN server
     * stg-trv.r10s.com (staging) and trv.r10s.com (prod).
     * The dates are expected to be in the format of MM/DD/YYYY
     */
    if (!eSimBS3Banner || !currentDate) {
      return false;
    }
    const { campaign_start_date, campaign_end_date } = eSimBS3Banner;

    if (!campaign_start_date || !campaign_end_date) {
      return false;
    }

    return (
      dayjs(currentDate).isSameOrAfter(campaign_start_date, 'date') &&
      dayjs(currentDate).isSameOrBefore(campaign_end_date, 'date')
    );
  },
);

export const getShouldDisplayCampaignBanner = createSelector(
  getReservationDetails,
  getIsWithInCampaignPeriod,
  getESimData,
  (reservationDetails, isWithInCampaignPeriod, eSimBS3Banner) => {
    if (!isWithInCampaignPeriod || !eSimBS3Banner) {
      return false;
    }

    const { checkInDate } = reservationDetails;
    const formattedCheckInDate = dayjs(checkInDate);
    try {
      /**
       * The featureConfigDetails is expected to be hosted in the CDN server
       * stg-trv.r10s.com (staging) and trv.r10s.com (prod).
       * The dates are expected to be in the format of MM/DD/YYYY
       */
      const { check_in_start_date, check_in_end_date } = eSimBS3Banner;

      if (!isWithInCampaignPeriod || !check_in_start_date || !check_in_end_date) {
        return false;
      }

      const allowedCheckInStartDate = dayjs(check_in_start_date);
      const allowedCheckInDateEndDate = dayjs(check_in_end_date);

      const isWithInAllowedDates =
        dayjs(formattedCheckInDate).isSameOrAfter(allowedCheckInStartDate) &&
        dayjs(formattedCheckInDate).isSameOrBefore(allowedCheckInDateEndDate);

      return !!isWithInCampaignPeriod && !!isWithInAllowedDates;
    } catch (e) {
      return false;
    }
  },
);
