import { AppThunk, createAsyncAction } from 'typesafe-actions';

import {
  ReceiptPreview,
  ReceiptPreviewPostValue,
  Receipts,
  ReservationDetailsErrors,
  ReservationDetailsItems,
} from 'ReservationDetails-Types';

import {
  fetchReceiptPreview,
  fetchReceiptsDownload,
  fetchReservationDetailsItems,
  fetchReservationReceipts,
} from './api';

export const fetchReservationDetailsAsync = createAsyncAction(
  'FETCH_RESERVATION_DETAILS_REQUEST',
  'FETCH_RESERVATION_DETAILS_SUCCESS',
  'FETCH_RESERVATION_DETAILS_FAILURE',
)<undefined, ReservationDetailsItems, ReservationDetailsErrors[]>();

export const fetchReceiptsAsync = createAsyncAction(
  'FETCH_RECEIPTS_REQUEST',
  'FETCH_RECEIPTS_SUCCESS',
  'FETCH_RECEIPTS_FAILURE',
)<undefined, Receipts, ReservationDetailsErrors[]>();

export const fetchReceiptsPreviewAsync = createAsyncAction(
  'FETCH_RECEIPTS_PREVIEW_REQUEST',
  'FETCH_RECEIPTS_PREVIEW_SUCCESS',
  'FETCH_RECEIPTS_PREVIEW_FAILURE',
)<undefined, ReceiptPreview[], ReservationDetailsErrors[]>();

export const downloadAsync = createAsyncAction(
  'FETCH_RECEIPTS_DOWNLOAD_REQUEST',
  'FETCH_RECEIPTS_DOWNLOAD_SUCCESS',
  'FETCH_RECEIPTS_DOWNLOAD_FAILURE',
)<undefined, string, ReservationDetailsErrors[]>();

export const fetchReservationDetails = (reservationId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReservationDetailsAsync.request());
  try {
    const response = await fetchReservationDetailsItems(apiClient, reservationId);
    dispatch(fetchReservationDetailsAsync.success(response));
  } catch (error) {
    dispatch(fetchReservationDetailsAsync.failure(error));
  }
};

export const fetchReceipts = (bookingId: string, reservationId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReceiptsAsync.request());
  try {
    const response = await fetchReservationReceipts(apiClient, bookingId, reservationId);
    dispatch(fetchReceiptsAsync.success(response));
    const postValue = {
      bookingId: bookingId,
      reservations: response.reservations.map(item => {
        return { id: item.id, transitionId: item.transitionId, addressedTo: '' };
      }),
    };
    dispatch(fetchReceiptsPreview(postValue));
  } catch (error) {
    dispatch(fetchReceiptsAsync.failure(error));
  }
};

export const fetchReceiptsPreview = (postValue: ReceiptPreviewPostValue): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReceiptsPreviewAsync.request());
  try {
    const response = await fetchReceiptPreview(apiClient, postValue);
    dispatch(fetchReceiptsPreviewAsync.success(response));
  } catch (error) {
    dispatch(fetchReceiptsPreviewAsync.failure(error));
  }
};

export const downloadReceipts = (postValue: ReceiptPreviewPostValue): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(downloadAsync.request());
  try {
    fetchReceiptsDownload(apiClient, postValue)
      .then(response => {
        const reader = response?.body?.getReader();
        if (!reader) {
          dispatch(downloadAsync.failure(response));
        }
        const stream = new ReadableStream({
          start(controller) {
            function push() {
              reader.read().then(({ done, value }: { done: Boolean; value: Uint8Array }) => {
                if (done) {
                  controller.close();
                  return;
                }
                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                push();
              });
            }
            push();
          },
        });

        return new Response(stream, {
          headers: { 'Content-Type': 'application/pdf' },
        }).arrayBuffer();
      })
      .then((buffer: ArrayBuffer) => {
        const file = new Blob([buffer], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        dispatch(downloadAsync.success(fileURL));
      });
  } catch (error) {
    dispatch(downloadAsync.failure(error));
  }
};
