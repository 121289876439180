import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  VacancyCalendar,
  VacancyCalendarDetails,
  VacancyCalendarDetailsRequest,
  VacancyCalendarErrors,
  VacancyCalendarRequest,
} from 'VacancyCalendar-Types';

import { fetchAll, fetchDetails } from './apis';

export const fetchVacancyCalendarAsync = createAsyncAction(
  'FETCH_VACANCY_CALENDAR_REQUEST',
  'FETCH_VACANCY_CALENDAR_SUCCESS',
  'FETCH_VACANCY_CALENDAR_FAILURE',
)<undefined, VacancyCalendar, VacancyCalendarErrors[]>();

export const fetchMoreVacancyCalendarAsync = createAsyncAction(
  'FETCH_MORE_VACANCY_CALENDAR_REQUEST',
  'FETCH_MORE_VACANCY_CALENDAR_SUCCESS',
  'FETCH_MORE_VACANCY_CALENDAR_FAILURE',
)<undefined, VacancyCalendar, VacancyCalendarErrors[]>();

export const clearErrorMessage = createStandardAction('CLEAR_VACANCY_CALENDAR_ERROR')();

export const fetchVacancyCalendar = (req: VacancyCalendarRequest): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  try {
    dispatch(fetchVacancyCalendarAsync.request());
    const response = await fetchAll(apiClient, req);
    dispatch(fetchVacancyCalendarAsync.success(response));
  } catch (error) {
    dispatch(fetchVacancyCalendarAsync.failure(error));
  }
};

export const fetchMoreVacancyCalendar = (req: VacancyCalendarRequest): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  try {
    dispatch(fetchMoreVacancyCalendarAsync.request());
    const response = await fetchAll(apiClient, req);
    dispatch(fetchMoreVacancyCalendarAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreVacancyCalendarAsync.failure(error));
  }
};

export const fetchVacancyCalendarDetailsAsync = createAsyncAction(
  'FETCH_VACANCY_CALENDAR_DETAILS_REQUEST',
  'FETCH_VACANCY_CALENDAR_DETAILS_SUCCESS',
  'FETCH_VACANCY_CALENDAR_DETAILS_FAILURE',
)<undefined, VacancyCalendarDetails, VacancyCalendarErrors[]>();

export const fetchVacancyCalendarDetails = (req: VacancyCalendarDetailsRequest): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  try {
    dispatch(fetchVacancyCalendarDetailsAsync.request());
    const response = await fetchDetails(apiClient, req);
    dispatch(fetchVacancyCalendarDetailsAsync.success(response));
  } catch (error) {
    dispatch(
      fetchVacancyCalendarDetailsAsync.failure([
        { statusCode: error?.status, message: error?.statusText },
      ]),
    );
  }
};
