import { ApiClient } from '@travel/api-client';

import { DeleteResponse, Notifications, NotificationStatus } from 'Notifications-Types';

export const fetchNotificationItems = (
  http: ApiClient,
  req: any,
  ignoreCommonErrorHandler: boolean = false,
): Promise<Notifications> =>
  http
    .get('/consumer/mypage/notification/list', {
      withAuthCode: true,
      ignoreCommonErrorHandler,
      values: { ...req },
    })
    .then((data: Notifications) => {
      return data;
    });

export const deleteNotificationRequest = (
  http: ApiClient,
  notificationId: string,
): Promise<DeleteResponse> =>
  http
    .delete(`/consumer/mypage/notification/delete/${notificationId}`, {
      withAuthCode: true,
    })
    .then((data: DeleteResponse) => {
      return data;
    });

export const fetchNotificationStatus = (
  http: ApiClient,
  ignoreCommonErrorHandler: boolean = false,
): Promise<NotificationStatus[]> =>
  http
    .get('/consumer/mypage/notification/status', {
      withAuthCode: true,
      ignoreCommonErrorHandler,
    })
    .then((data: NotificationStatus[]) => {
      return data;
    });
