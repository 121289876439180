import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { CardErrors, CardItems } from 'Card-Types';

import { fetchCardAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchCardAsync.request], (state, action) => true)
  .handleAction([fetchCardAsync.success, fetchCardAsync.failure], (state, action) => false);

export const items = createReducer([] as CardItems[]).handleAction(
  fetchCardAsync.success,
  (state, action) => action.payload?.cards || [],
);

export const errors = createReducer([] as CardErrors[]).handleAction(
  fetchCardAsync.failure,
  (state, action) => action.payload,
);

const cardReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default cardReducer;
export type CardState = ReturnType<typeof cardReducer>;
