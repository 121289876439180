import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { AuthCodeError, AuthCodeItem } from 'AuthCode-Types';

import { fetchAuthCodeAsync, logoutAsync, setAuthCode, setIsGuestBookingUser } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchAuthCodeAsync.request], () => true)
  .handleAction([fetchAuthCodeAsync.success, fetchAuthCodeAsync.failure], () => false);

export const item = createReducer({} as AuthCodeItem)
  .handleAction(fetchAuthCodeAsync.success, (_state, action) => action.payload)
  .handleAction(logoutAsync.success, (_state, action) => action.payload)
  .handleAction(setAuthCode, (_state, action) => ({ token: action.payload }));

export const isGuestBookingUser = createReducer(false as boolean).handleAction(
  setIsGuestBookingUser,
  (_state, action) => action.payload,
);

export const error = createReducer([] as AuthCodeError[]).handleAction(
  fetchAuthCodeAsync.failure,
  (_state, action) => action.payload,
);

const authCodeReducer = combineReducers({
  isGuestBookingUser,
  isFetching,
  item,
  error,
});

export default authCodeReducer;
export type AuthCodeState = ReturnType<typeof authCodeReducer>;
