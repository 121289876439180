import React from 'react';
import { useSelector } from 'react-redux';

import { getMarket } from '../selectors';
import { getAddressFormat } from '../utils/l10n';

type Props = {
  market?: string;
  country: string;
  postcode: string;
  address: string;
};

function getL10nAddress(props: Props) {
  const addressFormat = getAddressFormat(props?.market || '');
  const address = Object.entries(props).reduce((acc, [key, value]) => {
    if (key && value) return acc.replace(`{${key}}`, value);
    return acc.replace(`{${key}},`, '');
  }, addressFormat);

  return address;
}

function L10nAddress(props: Props) {
  const marketFromStore = useSelector(getMarket)?.marketCode || '';
  return (
    <span>
      {getL10nAddress({
        ...props,
        market: props.market || marketFromStore,
      })}
    </span>
  );
}

export default L10nAddress;
