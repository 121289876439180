import React, { HTMLAttributes, ReactNode } from 'react';

import cx from '../../utils/classnames';

import styles from './tag.module.scss';

export type BADGE_COLOR =
  | 'purple'
  | 'red'
  | 'yellow'
  | 'green'
  | 'gray'
  | 'silver'
  | 'blue'
  | 'jet'
  | 'white'
  | 'scarlet'
  | 'lincolnGreen';

type Props = {
  /** Custom style from wrapper */
  className?: string;

  /** Outlined: sets color of border & text
   Filled: sets color of background */
  type: 'outlined' | 'filled';

  /** Color of text & border */
  color: BADGE_COLOR;

  /** children: I18n based text/node */
  children: ReactNode;
} & HTMLAttributes<HTMLElement>;

export default function Tag(props: Props) {
  const { className, type, color, children, ...rest } = props;
  return (
    <div className={cx(className, styles.tag, styles[`${type}-${color}`])} {...rest}>
      {children}
    </div>
  );
}
