import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { BannerErrors, BannerItem } from 'Banner-Types';

import { getEnvironment } from '../__router/selectors';
import { fetch } from './apis';

export const fetchBannerAsync = createAsyncAction(
  'FETCH_BANNER_REQUEST',
  'FETCH_BANNER_SUCCESS',
  'FETCH_BANNER_FAILURE',
)<undefined, BannerItem, BannerErrors[]>();

export const setWideBannerHeight = createStandardAction('SET_WIDE_BANNER_HEIGHT')<number>();

export const fetchBanner = (page: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const { _i18n } = getState();

    dispatch(fetchBannerAsync.request());
    const response = await fetch(apiClient, page, _i18n.market?.marketCode, _i18n.language);
    dispatch(fetchBannerAsync.success(response));
  } catch (error) {
    dispatch(fetchBannerAsync.failure(error));
  }
};
