import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { MarketType } from '@travel/i18n';
import { isNotEmptyArray } from '@travel/utils';

import { verifyRegion } from 'utils/market';

import { fetchByIPAddress } from './api';

export const fetchPreferredMarketAsync = createAsyncAction(
  'FETCH_PREFERRED_MARKET_REQUEST',
  'FETCH_PREFERRED_MARKET_SUCCESS',
  'FETCH_PREFERRED_MARKET_FAILURE',
)<undefined, MarketType, MarketType>();

export const setMarkets = createStandardAction('SET_MARKETS')<Array<MarketType>>();
export const setIpAddress = createStandardAction('SET_IP_ADDRESS')<string>();

export const fetchPreferredMarket = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { markets, _i18n } = getState();

  // we should fetch only once per session
  if (markets?.preferredMarket) {
    return;
  }

  dispatch(fetchPreferredMarketAsync.request());

  try {
    let marketByIpAddress = await fetchByIPAddress(apiClient, markets.ipAddress);

    if (marketByIpAddress && isNotEmptyArray(markets?.items)) {
      const supportedMarketCodes = markets.items.map(market => market.marketCode.toLowerCase());
      if (!verifyRegion(marketByIpAddress?.marketCode || '', supportedMarketCodes)) {
        marketByIpAddress = _i18n.market;
      }
    } else {
      marketByIpAddress = _i18n.market;
    }

    dispatch(fetchPreferredMarketAsync.success(marketByIpAddress));
  } catch (error) {
    // set current market as default
    dispatch(fetchPreferredMarketAsync.success(_i18n.market));
  }
};
