import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  ApplySpAndPoint,
  BookingForm,
  BookingStep3,
  BookingStepData,
  BookingStepErrors,
  CouponData,
} from 'BookingStep-Types';
import { ApplicableCouponRequestBody } from 'SalesPromotion-Types';

import {
  acquiredCoupon,
  getBookingStep2Async,
  postBookingSpAndPointAsync,
  postBookingStep1Async,
  postBookingStep2Async,
  postBookingStep3Async,
  saveBookingStep1FormData,
  savePaymentMethod,
} from './actions';
import {
  bookingDataHelper,
  bookingDataPostSpHelper,
  bookingFormHelper,
  bookingFormPostSpHelper,
  couponRequestBodyHelper,
  initialBookingFormSate,
} from './helpers';

export const isFetchingStep1 = createReducer(false as boolean)
  .handleAction([postBookingStep1Async.request], (state, action) => true)
  .handleAction(
    [postBookingStep1Async.success, postBookingStep1Async.failure],
    (state, action) => false,
  );

export const isFetchingStep3 = createReducer(false as boolean)
  .handleAction([postBookingStep3Async.request], (state, action) => true)
  .handleAction(
    [postBookingStep3Async.success, postBookingStep3Async.failure],
    (state, action) => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      postBookingStep1Async.request,
      postBookingStep2Async.request,
      getBookingStep2Async.request,
      postBookingStep3Async.request,
      postBookingSpAndPointAsync.request,
    ],
    (state, action) => true,
  )
  .handleAction(
    [
      postBookingStep1Async.success,
      postBookingStep1Async.failure,
      postBookingStep2Async.success,
      postBookingStep2Async.failure,
      getBookingStep2Async.success,
      getBookingStep2Async.failure,
      postBookingStep3Async.success,
      postBookingStep3Async.failure,
      postBookingSpAndPointAsync.success,
      postBookingSpAndPointAsync.failure,
    ],
    (state, action) => false,
  );

export const bookingData = createReducer({} as BookingStepData)
  .handleAction(
    [postBookingStep1Async.success, getBookingStep2Async.success, postBookingStep2Async.success],
    (state, action) => {
      return bookingDataHelper(state, action);
    },
  )
  .handleAction(postBookingSpAndPointAsync.success, (state, action) => {
    return bookingDataPostSpHelper(state, action);
  })
  .handleAction(savePaymentMethod, (state, action) => ({ ...state, ...action.payload }));

export const step3 = createReducer({} as BookingStep3).handleAction(
  postBookingStep3Async.success,
  (state, action) => action.payload,
);

export const errors = createReducer({} as BookingStepErrors)
  .handleAction(
    [
      postBookingStep1Async.failure,
      postBookingStep2Async.failure,
      getBookingStep2Async.failure,
      postBookingStep3Async.failure,
      postBookingSpAndPointAsync.failure,
    ],
    (state, action) => action.payload,
  )
  .handleAction(
    [
      postBookingStep1Async.success,
      postBookingStep2Async.success,
      getBookingStep2Async.success,
      postBookingStep3Async.success,
      postBookingSpAndPointAsync.success,
    ],
    (state, action) => {
      return {};
    },
  );

export const bookingForm = createReducer(initialBookingFormSate as BookingForm)
  .handleAction(postBookingStep1Async.success, (state, action: { payload: BookingStepData }) => {
    return bookingFormHelper(state, action);
  })
  .handleAction(
    postBookingSpAndPointAsync.success,
    (state, action: { payload: ApplySpAndPoint }) => {
      return bookingFormPostSpHelper(state, action);
    },
  )
  .handleAction(saveBookingStep1FormData, (state, action) => ({ ...state, ...action.payload }))
  .handleAction(postBookingStep3Async.success, () => initialBookingFormSate);

export const couponRequestBody = createReducer({} as ApplicableCouponRequestBody).handleAction(
  [postBookingStep1Async.success],
  (_state, action: { payload: BookingStepData }) => {
    return couponRequestBodyHelper(action);
  },
);

export const appliedCoupon = createReducer({} as CouponData).handleAction(
  acquiredCoupon,
  (state, action) => action.payload,
);

const bookingStepReducer = combineReducers({
  isFetching,
  bookingData,
  step3,
  errors,
  bookingForm,
  couponRequestBody,
  isFetchingStep1,
  isFetchingStep3,
  appliedCoupon,
});

export default bookingStepReducer;
export type BookingStepState = ReturnType<typeof bookingStepReducer>;
