import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { LegacyReservationListState } from './reducer';

export const getItems = (state: RootState) => state.legacyReservationList.items;
export const getErrors = (state: RootState) => state.legacyReservationList.errors;

export const getLegacyReservationList = (state: RootState) => state.legacyReservationList;

export const getIsFetching = createSelector(
  getLegacyReservationList,
  (state: LegacyReservationListState) => state.isFetching,
);

export const getSelectedStatusType = (state: RootState) =>
  state.legacyReservationList.selectedStatusType;

export const getIsFetchingMore = createSelector(
  getLegacyReservationList,
  (state: LegacyReservationListState) => state.isFetchingMore,
);
