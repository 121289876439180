import { JSONparse } from './helper';

export function sessionSetItem<T>(key: string, data: T) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.sessionStorage || !window.JSON || !key) {
    return;
  }

  return sessionStorage.setItem(key, JSON.stringify(data));
}

export function sessionGetItem<T>(key: string): T | void {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.sessionStorage || !window.JSON || !key) {
    return;
  }
  const item = sessionStorage.getItem(key);

  if (!item) {
    return;
  }

  return JSONparse<T>(item);
}

export function sessionRemoveItem(key: string) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.sessionStorage || !key) {
    return;
  }

  sessionStorage.removeItem(key);
}

export function sessionHasOwnProperty(key: string) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.sessionStorage || !key) {
    return;
  }

  return sessionStorage.hasOwnProperty(key);
}
