export const isNotNullish = (value: any) => {
  return value !== undefined && value !== null;
};

/** Return empty string if the value is nullish */
export const getNotNullishInputValue: (
  value: string | number | undefined | null,
) => string | number = value => {
  return typeof value === 'string' || typeof value === 'number' ? value : '';
};
