import React from 'react';

import { AttentionDisable } from '@travel/icons/ui';

import ErrorPage from 'pages/ErrorPage';

import { Translate } from 'core/translate';

import styles from './offlinePage.module.scss';

function OfflinePage() {
  return (
    <ErrorPage
      mainText={
        <>
          <Translate className={styles.text} id="Traveler_Server_Error.b.3.Message1" />
          <Translate className={styles.text} id="Traveler_Server_Error.b.3.Message2" />
        </>
      }
      icon={<AttentionDisable color="lightGrey" />}
    />
  );
}

export default OfflinePage;
