import { ApiClient } from '@travel/api-client';

import {
  VacancyCalendar,
  VacancyCalendarDetails,
  VacancyCalendarDetailsRequest,
  VacancyCalendarRequest,
} from 'VacancyCalendar-Types';

export const fetchAll = (http: ApiClient, req: VacancyCalendarRequest): Promise<VacancyCalendar> =>
  http.post(`/monterosa-api/plan-item-calendar`, {
    values: req,
    ignoreCommonErrorHandler: true,
  });

export const fetchDetails = (
  http: ApiClient,
  req: VacancyCalendarDetailsRequest,
): Promise<VacancyCalendarDetails> =>
  http.post(`/monterosa-api/plan-item-calendar/details`, {
    values: req,
    ignoreCommonErrorHandler: true,
  });
