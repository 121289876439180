import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { CardErrors, CardItems } from 'Card-Types';

import { fetch } from './api';

// XXX: Kindly update payloads to your own async implementation
export const fetchCardAsync = createAsyncAction(
  'FETCH_CARD_REQUEST',
  'FETCH_CARD_SUCCESS',
  'FETCH_CARD_FAILURE',
)<undefined, CardItems[], CardErrors[]>();

export const fetchCard = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchCardAsync.request());

  const response = await fetch(apiClient);
  dispatch(fetchCardAsync.success(response));
};
