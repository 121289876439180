import React from 'react';

import SwiperCore, { Props } from '@travel/traveler-core/components/Swiper';
import { EMPTY_ARRAY } from '@travel/traveler-core/constants';
import { useTranslation } from '@travel/traveler-core/hooks';

function Swiper(props: Props) {
  const leftButtonLabel = useTranslation({ id: 'Traveler_Common.Alt.Slider_Previous' });
  const rightButtonLabel = useTranslation({ id: 'Traveler_Common.Alt.Slider_Next' });
  return (
    <SwiperCore
      ariaLabelLeftButton={leftButtonLabel}
      ariaLabelRightButton={rightButtonLabel}
      {...props}
    />
  );
}

Swiper.defaultProps = {
  children: EMPTY_ARRAY,
  initialSlide: 0,
};

export default Swiper;
