import { ApiClient } from '@travel/api-client';

import { PromotionBannersItem } from 'PromotionBanners-Types';
import { fetchEditableContents, getValidatedContents } from 'utils/editableContents';

export const fetchAll = (
  http: ApiClient,
  page: string,
  market: string,
  language: string,
): Promise<PromotionBannersItem[]> =>
  fetchEditableContents(http, page, 'mainbanner', market, language).then(
    data => getValidatedContents(data) || ([] as PromotionBannersItem[]),
  );
