import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { SeoData } from 'Seo-Types';

import { TealiumObject } from '../../hooks/useTealium';
import { resetDataLayer, setDataLayer, setDefaultSEOData, updateSEOData } from './actions';

export const data = createReducer({} as SeoData).handleAction(
  [updateSEOData, setDefaultSEOData],
  (_state, action) => action.payload,
);
export const dataLayer = createReducer({} as TealiumObject).handleAction(
  [setDataLayer, resetDataLayer],
  (_state, action) => action.payload,
);

const seoReducer = combineReducers({
  data,
  dataLayer,
});

export default seoReducer;
export type SeoState = ReturnType<typeof seoReducer>;
