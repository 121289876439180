import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { setAuthCode, setIsGuestBookingUser } from 'store/authCode/actions';

import {
  ReservationEntranceErrors,
  ReservationEntranceItems,
  ReservationEntranceRequestType,
} from 'ReservationEntrance-Types';

import { fetchAuthentication } from './api';

export const fetchReservationEntranceAsync = createAsyncAction(
  'FETCH_RESERVATION_ENTRANCE_REQUEST',
  'FETCH_RESERVATION_ENTRANCE_SUCCESS',
  'FETCH_RESERVATION_ENTRANCE_FAILURE',
)<undefined, ReservationEntranceItems, ReservationEntranceErrors>();

export const fetchReservationEntrance = (
  req: ReservationEntranceRequestType,
): AppThunk<Promise<string | null>> => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchReservationEntranceAsync.request());
  try {
    const response = await fetchAuthentication(apiClient, req);

    if (response && response.token) {
      // TODO: If storeMiddleware can handle storing token, below code can be removed
      dispatch(setAuthCode(response.token));
      dispatch(setIsGuestBookingUser(true));
    }

    dispatch(fetchReservationEntranceAsync.success(response));
    dispatch(fetchReservationEntranceAsync.failure({ status: 0, message: '' }));
    return response.token;
  } catch (error) {
    dispatch(fetchReservationEntranceAsync.failure(error));
    return null;
  }
};
