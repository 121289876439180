import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { as } from '@travel/utils';

import {
  ReceiptPreview,
  Receipts,
  ReservationDetailsErrors,
  ReservationDetailsItems,
} from 'ReservationDetails-Types';

import {
  downloadAsync,
  fetchReceiptsAsync,
  fetchReceiptsPreviewAsync,
  fetchReservationDetailsAsync,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchReservationDetailsAsync.request], (state, action) => true)
  .handleAction(
    [fetchReservationDetailsAsync.success, fetchReservationDetailsAsync.failure],
    (state, action) => false,
  );

export const isFetchingReceipts = createReducer(false as boolean)
  .handleAction(
    [fetchReceiptsAsync.request, fetchReceiptsPreviewAsync.request, downloadAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchReceiptsAsync.success,
      fetchReceiptsAsync.failure,
      fetchReceiptsPreviewAsync.success,
      fetchReceiptsPreviewAsync.failure,
      downloadAsync.success,
      downloadAsync.failure,
    ],
    (state, action) => false,
  );

export const items = createReducer({} as ReservationDetailsItems).handleAction(
  fetchReservationDetailsAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const receipts = createReducer({} as Receipts).handleAction(
  fetchReceiptsAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const receiptPreviews = createReducer({} as ReceiptPreview[]).handleAction(
  fetchReceiptsPreviewAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const errors = createReducer([] as ReservationDetailsErrors[]).handleAction(
  fetchReservationDetailsAsync.failure,
  (state, action) => action.payload,
);

export const receiptErrors = createReducer([] as ReservationDetailsErrors[]).handleAction(
  fetchReservationDetailsAsync.failure,
  (state, action) => action.payload,
);

export const downloadReceipts = createReducer(as<string>(null)).handleAction(
  downloadAsync.success,
  (state, action) => action.payload,
);

const reservationDetailsReducer = combineReducers({
  isFetching,
  items,
  errors,
  isFetchingReceipts,
  receipts,
  receiptPreviews,
  receiptErrors,
  downloadReceipts,
});

export default reservationDetailsReducer;
export type ReservationDetailsState = ReturnType<typeof reservationDetailsReducer>;
