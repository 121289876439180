import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { HeroImagesErrors, HeroImagesItems } from 'HeroImages-Types';
import { fetchEditableContents, getValidatedContents } from 'utils/editableContents';

import { getEnvironment } from '../__router/selectors';

export const fetchHeroImageCountAsync = createAsyncAction(
  'FETCH_HERO_IMAGES_REQUEST',
  'FETCH_HERO_IMAGES_SUCCESS',
  'FETCH_HERO_IMAGES_FAILURE',
)<undefined, HeroImagesItems, HeroImagesErrors[]>();

export const fetchHeroImages = (page: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient: http },
) => {
  try {
    const { _i18n } = getState();

    dispatch(fetchHeroImageCountAsync.request());
    const response = await fetchEditableContents(
      http,
      page,
      'heroimage',
      _i18n.market?.marketCode,
      _i18n.language,
    );

    const validatedContent = getValidatedContents(response);
    dispatch(fetchHeroImageCountAsync.success(validatedContent || ([] as HeroImagesItems)));
  } catch (e) {
    dispatch(fetchHeroImageCountAsync.failure(e));
  }
};
