import { SupportedCurrency } from '@travel/i18n';
import { isEmptyArray } from '@travel/utils';

export const verifyRegion = (regionCode: string, supportedMarkets: Array<string>) => {
  return regionCode && supportedMarkets.includes(regionCode ? regionCode.toLowerCase() : '')
    ? regionCode
    : null;
};

export const verifyLanguage = (langCode: string, supportedLanguages: string[]) => {
  if (!langCode || !(langCode.length === 5 || langCode.length === 2)) {
    return null;
  }

  const isMatched = supportedLanguages.some(
    supportedLang => supportedLang.toLowerCase() === langCode.toLowerCase(),
  );
  return isMatched ? langCode : null;
};

export const verifyCurrency = (currencyCode: string, supportedCurrencies?: SupportedCurrency[]) => {
  if (!currencyCode || isEmptyArray(supportedCurrencies)) {
    return null;
  }

  const isMatched = supportedCurrencies.some(
    supportedCurrency => supportedCurrency.currency === currencyCode,
  );
  return isMatched ? currencyCode : null;
};
