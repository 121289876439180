import { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';

import { fetchGuestReviews, fetchPendingGuestReviews } from 'store/guestReviews/actions';
import { getLatestPageName } from 'store/pageLoading/selectors';
import { setDefaultSEOData } from 'store/seo/actions';
import { getSsrDeviceType } from 'store/userAgent/selectors';

import { universalRouterProps } from 'core/universalRouter/types';

export const DEFAULT_LIMIT = 5;
export const DEFAULT_OFFSET = 0;

type SearchProps = {
  params: { pageIdentifier: string };
};

export default ({ store }: universalRouterProps, search: SearchProps) => {
  const dispatch = store.dispatch;
  const state = store.getState();

  const req = {
    limit: DEFAULT_LIMIT,
    offset:
      state?.guestReviews?.items?.offset &&
      getSsrDeviceType(state) !== MOBILE_SCREEN &&
      getLatestPageName(state) === 'guestReviewDetail'
        ? state.guestReviews.items.offset
        : DEFAULT_OFFSET,
  };

  return Promise.all([
    dispatch(fetchPendingGuestReviews(req)),
    dispatch(fetchGuestReviews(req)),
    dispatch(setDefaultSEOData()),
  ]);
};
