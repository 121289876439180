import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ProviderFeature } from '@travel/traveler-core/types/providerList';
import { GalleryMedia } from '@travel/ui/components/Gallery';
import { isNotEmptyArray } from '@travel/utils';

import { DefaultQueryParams } from 'pages/ProviderListPage/resolver';

import { updateIsBookmarked } from 'store/bookmarks/actions';

import { FILTER_GROUP_IDS } from 'constants/provider';
import {
  Groups,
  ProviderFilter,
  ProviderItem,
  ProviderList,
  ProviderListErrors,
} from 'ProviderList-Types';

import {
  clearProviderList,
  fetchMoreProvidersAsync,
  fetchProviderFiltersAsync,
  fetchProviderListAsync,
  updateAreaName,
  updateMapExpansionState,
  updateQueryParams,
  updateRequestOffset,
  updateScrollPosition,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchMoreProvidersAsync.request], () => true)
  .handleAction([fetchMoreProvidersAsync.success, fetchMoreProvidersAsync.failure], () => false);

export const isFetchingList = createReducer(false as boolean)
  .handleAction([fetchProviderListAsync.request], () => true)
  .handleAction([fetchProviderListAsync.success, fetchProviderListAsync.failure], () => false);

export const isFetchingFilters = createReducer(false as boolean)
  .handleAction([fetchProviderFiltersAsync.request], () => true)
  .handleAction(
    [fetchProviderFiltersAsync.success, fetchProviderFiltersAsync.failure],
    () => false,
  );

export const pageRequestOffsets = createReducer({} as { [key: number]: number }).handleAction(
  updateRequestOffset,
  (state, { payload }) => {
    // reset every time we fetch page 1
    if (payload.page <= 1) {
      return { 1: 0 };
    }

    return {
      ...state,
      [payload.page]: payload.offset,
    };
  },
);

export const items = createReducer({} as ProviderList)
  .handleAction(updateAreaName, (state, action) => ({
    ...state,
    areaName: action.payload,
  }))

  .handleAction(fetchProviderListAsync.success, (state, { payload }) => {
    const { areaName, providers, ...restResponse } = payload.response;
    const modifiedProviders = providers.map((provider: ProviderItem) => {
      const {
        features,
        media,
        ...rest
      }: { features: Array<ProviderFeature>; media: Array<GalleryMedia> } = provider;
      //TODO: Remove the slice part once API implementation is done https://jira.rakuten-it.com/jira/browse/TIDP-30604
      return { features: features.slice(0, 10), media: media.slice(0, 20), ...rest };
    });
    const page = payload.options?.page || 1;

    return {
      ...restResponse,
      providers: modifiedProviders,
      page,
      areaName: areaName,
    };
  })

  .handleAction(fetchMoreProvidersAsync.success, (state, action) => {
    const modifiedProviders = action.payload.providers.map((provider: ProviderItem) => {
      const {
        features,
        media,
        ...rest
      }: { features: Array<ProviderFeature>; media: Array<GalleryMedia> } = provider;
      //TODO: Remove the slice part once API implementation is done https://jira.rakuten-it.com/jira/browse/TIDP-30604
      return { features: features.slice(0, 10), media: media.slice(0, 20), ...rest };
    });
    return {
      ...state,
      page: Number(state.page || 1) + 1,

      offset: action.payload.offset,
      nextOffset: action.payload.nextOffset,
      limit: action.payload.limit,
      total: action.payload.total,
      providers: state.providers.concat(modifiedProviders),
    };
  })

  .handleAction(
    fetchProviderListAsync.failure,
    () => ({ total: 0, limit: 0, offset: 0, page: 1 } as ProviderList),
  )

  .handleAction(clearProviderList, (state, action) => ({
    ...state,
    offset: 0,
    location: {
      lat: action.payload.lat, //update new longitude when drag on map
      lng: action.payload.lng, //update new latitude when drag on map
      zoomLevel: action.payload.zoomLevel, //update new zoomLevel when zoom on map
    },
    limit: 0,
    total: 0,
    providers: [],
  }))

  .handleAction(updateIsBookmarked, (state, action) => {
    if (state && isNotEmptyArray(state.providers)) {
      return {
        ...state,
        providers: state.providers.map(el => {
          if (el.id === action.payload.id) {
            return { ...el, isBookmarked: action.payload.isBookmarked };
          }
          return el;
        }),
      };
    } else {
      return state;
    }
  });

export const isMapExpanded = createReducer(false as boolean).handleAction(
  updateMapExpansionState,
  (_state, { payload }) => payload,
);

export const filters = createReducer([] as ProviderFilter[]).handleAction(
  fetchProviderFiltersAsync.success,
  (_state, { payload }) => payload.filters,
);

export const filtersTotal = createReducer(0 as number).handleAction(
  fetchProviderFiltersAsync.success,
  (_state, { payload }) => payload.total,
);

export const isFilterSelected = createReducer(false).handleAction(
  fetchProviderFiltersAsync.success,
  (_state, { payload }) =>
    payload.filters.some((filter: ProviderFilter) => {
      switch (filter.groupId) {
        case FILTER_GROUP_IDS.RATING:
        case FILTER_GROUP_IDS.REVIEW_SCORE:
          return Boolean(filter.rating?.some(rating => rating.selected));
        case FILTER_GROUP_IDS.CANCELLATION_POLICY:
          return Boolean(filter.freeCancellation?.selected);
        default:
          return filter.features
            ? Boolean(filter.features.some(feature => feature.selected))
            : false;
      }
    }),
);

export const selectedFeatures = createReducer([] as Array<Groups>).handleAction(
  fetchProviderFiltersAsync.success,
  (_state, { payload }) =>
    payload.filters.reduce((acc: Array<Groups>, curr: ProviderFilter) => {
      if (isNotEmptyArray(curr.features)) {
        const toAdd = curr.features.filter(feature => feature.selected);
        return acc.concat(toAdd);
      }
      return acc;
    }, []),
);

export const errors = createReducer([] as ProviderListErrors[])
  .handleAction(fetchProviderListAsync.failure, (_state, action) => action.payload)
  .handleAction(fetchMoreProvidersAsync.failure, (_state, action) => action.payload);

export const scrollPosition = createReducer(0 as number).handleAction(
  updateScrollPosition,
  (_state, { payload }) => payload,
);

// this keep the previous areaName if areaName is empty in next search
export const areaName = createReducer('' as string)
  .handleAction(updateAreaName, (state, action) => action.payload)
  .handleAction(fetchProviderListAsync.success, (state, { payload }) => {
    const { areaName } = payload.response;
    const { isSuggestionSearch } = payload?.options || {};
    return !isSuggestionSearch && areaName ? areaName : state;
  });

export const queryParams = createReducer({} as DefaultQueryParams).handleAction(
  updateQueryParams,
  (state, action) => action.payload,
);

const providerListReducer = combineReducers({
  isFilterSelected,
  isFetching,
  isFetchingFilters,
  items,
  filters,
  filtersTotal,
  selectedFeatures,
  errors,
  isFetchingList,
  scrollPosition,
  pageRequestOffsets,
  isMapExpanded,
  areaName,
  queryParams,
});

export default providerListReducer;
export type ProviderListState = ReturnType<typeof providerListReducer>;
