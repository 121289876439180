import React from 'react';

import { TranslateProps } from '@travel/translation';
import TranslateFromLib from '@travel/traveler-core/components/Translate';

export function Translate(props: TranslateProps) {
  return <TranslateFromLib {...props} />;
}
Translate.defaultProps = {
  data: {},
};

export { useTranslate } from '@travel/translation';
