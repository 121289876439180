import { RoomFormQuery } from 'pages/ProviderListPage/components/SearchForm';
import { getDefaultRoomFormQueryParams } from 'pages/ProviderListPage/resolver';

import { fetchAreaDetails, fetchSEOdata } from 'store/areaDetails/actions';
import { fetchBanner } from 'store/banner/actions';
import { fetchPromotionBanners } from 'store/promotionBanners/actions';
import { fetchSpecialFeatureArticles } from 'store/specialFeatureArticles/actions';
import { fetchSubscription } from 'store/subscription/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type SearchProps = {
  params: { pathId: string };
  query: RoomFormQuery;
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  const pageName = 'areapage';

  return Promise.all([
    dispatch(fetchBanner(pageName)),
    dispatch(fetchSpecialFeatureArticles(pageName)),
    dispatch(fetchPromotionBanners(pageName)),
    dispatch(fetchSubscription()),
    dispatch(
      fetchAreaDetails({ ...search.params, ...getDefaultRoomFormQueryParams(search.query) }),
    ),
  ]).then(() => dispatch(fetchSEOdata(search.params.pathId)));
};
