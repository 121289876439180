import { Store } from 'typesafe-actions';

import loggerGenerator from '@travel/logger';
import { noticeError } from '@travel/tracker';

import { setCommonError } from 'store/commonError/actions';

const logger = loggerGenerator();

const setCommonErrorHandler = (store: Store) => ({
  status,
  url,
  response,
  request,
}: {
  status: number;
  url: string;
  response?: Response;
  request?: Request;
}) => {
  const state = store.getState();
  logger.error('status', status, url, state.userAgent?.userAgent?.source || 'no-user-agent');
  store.dispatch(setCommonError({ message: 'test error message', httpStatusCode: status }));
  response
    ?.json()
    .then(json => {
      const errorJson = {
        url: response.url,
        req: request,
        res: response,
        // setting req.useragent in the store copies the object inside the userAgent attribute in state
        userAgent: state.userAgent?.userAgent?.source,
        json,
      };
      if (/^50/.test(String(response.status))) {
        noticeError('API 50x Error', errorJson);
      } else if (/^40/.test(String(response.status))) {
        noticeError('API 40x Error', errorJson);
      } else {
        noticeError(`${status} error`, errorJson);
      }
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      logger.log('error', e);
      noticeError(`${status} error`, {
        url,
        req: request,
        res: response,
        userAgent: state.userAgent?.userAgent.source,
        error: e,
      });
    });
};

export default setCommonErrorHandler;
