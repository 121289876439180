import { ApiClient } from '@travel/api-client';

import {
  ApplicableCouponDetails,
  ApplicableCouponRequestBody,
  CouponDetails,
} from 'SalesPromotion-Types';

export const requestCouponDetails = (
  http: ApiClient,
  id: string,
  shouldIgnoreCommonError?: boolean,
): Promise<CouponDetails> =>
  http.get(`/sales-promotion-core/coupons/${id}/details`, {
    values: { id, direct: true },
    ignoreCommonErrorHandler: shouldIgnoreCommonError ?? true,
  });

export const requestCouponApplicable = (
  http: ApiClient,
  req?: ApplicableCouponRequestBody,
): Promise<ApplicableCouponDetails> =>
  http
    .post(`/sales-promotion-core/mycoupon/applicable`, {
      values: req,
      ignoreCommonErrorHandler: true,
      withAuthCode: true,
    })
    .then(data => data);

export const checkBin = (
  http: ApiClient,
  coupon: string = '',
  bin: string = '',
): Promise<{ isUsable: boolean }> =>
  http.get(`/sales-promotion-core/cc-bin-check`, { values: { coupon, bin } });
