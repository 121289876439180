import React, { HTMLAttributes } from 'react';

import cx from '../../utils/classnames';

import styles from './imagePlaceHolder.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Callback function when clicked */
  onClick?: () => void;
  size?: number;
} & HTMLAttributes<HTMLElement>;

function ImagePlaceHolder(props: Props) {
  const { className, onClick, size = 60, ...rest } = props;

  return (
    <div
      className={cx(className, styles.container)}
      data-testid="imagePlaceholder-wrapper"
      {...rest}
      onClick={onClick}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="30" cy="30" r="29" stroke="#CCC" strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.125 33.857l4.375-4.375 6.25 6.25V16.25h-27.5v15.732l7.5-7.5 9.375 9.375zM16.25 43.749h27.5v-4.482l-6.25-6.25-2.608 2.607 2.402 2.402-1.768 1.767L23.75 28.017l-7.5 7.5v8.232zm-1.25-30h30c.69 0 1.25.56 1.25 1.25v30c0 .692-.56 1.25-1.25 1.25H15A1.25 1.25 0 0113.75 45V15c0-.69.56-1.25 1.25-1.25zM37.5 27.5a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25z"
          fill="#CCC"
        />
      </svg>
    </div>
  );
}

export default ImagePlaceHolder;
