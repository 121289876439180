import { ApiClient } from '@travel/api-client';

import { ProviderPlanList } from 'ProviderPlanList-Types';

import { SearchRequestBody } from '../../pages/ProviderInformationPage/resolver';

export const fetchPlanList = (http: ApiClient, req: SearchRequestBody): Promise<ProviderPlanList> =>
  http.post(`/monterosa-api/providers/plan-item-summaries`, {
    values: req,
    ignoreCommonErrorHandler: true,
  });
