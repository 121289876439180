// eslint-disable-next-line simple-import-sort/imports
import './polyfill';
// public-path must be imported before router
// eslint-disable-next-line simple-import-sort/imports
import './public-path';

import { loadableReady } from '@loadable/component';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { createBrowserHistory } from 'history';
import configureStore from 'store';

import { ApiClientProvider } from '@travel/api-client';
import UIComponentContext from '@travel/ui/contexts';

import { getSsrDeviceType } from 'store/userAgent/selectors';

import { createApiClient } from 'core/httpClient';
import commonErrorHandler from 'core/httpClient/commonErrorHandler';
import requestInterceptor from 'core/httpClient/requestInterceptor';
import responseInterceptor from 'core/httpClient/responseInterceptor';
import universalRouter from 'core/universalRouter';
import ClientRouter from 'core/universalRouter/client/Router';
import { Link } from 'core/universalRouter/Link';

const apiClient = createApiClient();

const history = createBrowserHistory({
  basename: window.PRELOADED_STATE.router.baseUrl,
});
const store = configureStore(window.PRELOADED_STATE, history, undefined, undefined, apiClient);
const ssrDeviceType = getSsrDeviceType(store.getState());

// Allow the passed state to be garbage-collected
delete window.PRELOADED_STATE;

apiClient.setRequestInterceptor(requestInterceptor(store));
apiClient.setResponseInterceptor(responseInterceptor(store));
apiClient.setCommonErrorHandler(commonErrorHandler(store));

loadableReady(async () => {
  // For matching html from SSR
  const router = universalRouter({ store });

  const children = await router.resolve({
    ...history.location,
    skipResolver: true,
  });
  hydrate(
    <UIComponentContext.Provider
      value={{
        ssrDeviceType: ssrDeviceType,
        LinkComponent: ({ href, children, ...rest }) => (
          <Link to={href || ''} {...rest}>
            {children}
          </Link>
        ),
      }}
    >
      <ApiClientProvider apiClient={apiClient}>
        <ReduxProvider store={store}>
          <ClientRouter history={history} router={router}>
            {children}
          </ClientRouter>
        </ReduxProvider>
      </ApiClientProvider>
    </UIComponentContext.Provider>,
    document.getElementById('root'),
  );
});
