import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { isNotEmptyArray } from '@travel/utils';

import { CountriesItems } from 'Countries-Types';

import { fetchAll } from './apis';

export const fetchCountriesAsync = createAsyncAction(
  'FETCH_COUNTRIES_REQUEST',
  'FETCH_COUNTRIES_SUCCESS',
  'FETCH_COUNTRIES_FAILURE',
)<undefined, CountriesItems, undefined>();

// this is saved remotely
// (will be saved until the next restart)
let countries: CountriesItems = {};

export const fetchCountries = (): AppThunk => async (dispatch, _getState, { apiClient }) => {
  if (isNotEmptyArray(Object.values(countries))) {
    dispatch(fetchCountriesAsync.success(countries));
    return;
  }

  try {
    const response = await fetchAll(apiClient);

    const items = response?.content?.reduce(
      (result, { alpha2Code, alpha3Code, callingCode }) => ({
        ...result,
        [alpha3Code || alpha2Code]: {
          alpha2Code,
          alpha3Code,
          callingCode,
        },
      }),
      {},
    );

    countries = items;
    dispatch(fetchCountriesAsync.success(items));
  } catch (error) {
    // do nothing
  }
};
