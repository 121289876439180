import dayjs from 'dayjs';

import { ApiClient } from '@travel/api-client';
import env from '@travel/env';
import { DEFAULT_SITE_LANGUAGE, DEFAULT_SITE_MARKET } from '@travel/traveler-core/constants/common';
import { getEditableContents, setEditableContents } from '@travel/traveler-core/utils';
import { isEmptyArray } from '@travel/utils';

type EditableContentValidationType = {
  display_order: number;
  publication_period_from?: number;
  publication_period_to?: number;
};

export const isContentActive = (data: EditableContentValidationType) => {
  const today = dayjs();
  if (data?.publication_period_from && data?.publication_period_to) {
    return (
      today.isAfter(dayjs(data.publication_period_from)) &&
      today.isBefore(dayjs(data.publication_period_to))
    );
  } else if (data?.publication_period_from) {
    return today.isAfter(dayjs(data.publication_period_from));
  } else if (data?.publication_period_to) {
    return today.isBefore(dayjs(data?.publication_period_to));
  }
  return true;
};

/**
 * Function for validate the given content if it's allowed to be displayed
 * @param data
 * @returns Validated contents
 */
export const getValidatedContent = (data: EditableContentValidationType) => {
  if (!data || !isContentActive(data)) {
    return null;
  }
  return data;
};

/**
 * Function for sorting and filtering out the non-related period of Array contents
 * @param data
 * @returns Validated contents
 */
export const getValidatedContents = (data: Array<EditableContentValidationType>) => {
  if (isEmptyArray(data)) {
    return null;
  }
  const activeContents = data.filter(item => isContentActive(item)).filter(item => item);
  return activeContents.sort((itemA, itemB) => itemA.display_order - itemB.display_order);
};

/**
 * Function to fetch the editable contents
 * @param page - specific page name
 * @param frame - specific frame name
 * @param market - base market
 * @param [language = FALLBACK_LANGUAGE] - base language
 * @returns <Promise>
 */
export const generateFileURL = (
  page: string,
  frame: string,
  market?: string,
  language?: string,
) => {
  const baseAPIUrl =
    env('UNIVERSAL_API_MOCK_SERVER') === 'TRUE'
      ? env('UNIVERSAL_MOCK_BU_CONTENTS_API_SERVER_URL')
      : process.env.UNIVERSAL_ENV === 'server'
      ? env('UNIVERSAL_BU_CONTENTS_API_SERVER_URL')
      : env('UNIVERSAL_BU_CONTENTS_API_CLIENT_URL');

  return `${baseAPIUrl}/${(market || DEFAULT_SITE_MARKET).toLowerCase()}/${(
    language || DEFAULT_SITE_LANGUAGE
  ).toLowerCase()}/config/${page}-${frame}.json`;
};

/**
 * Function to fetch the editable contents
 * @param http - API client
 * @param page - specific page name
 * @param frame - specific frame name
 * @param market - base market
 * @param language - base language
 * @param fileExtension
 * @returns <Promise>
 */
export const fetchEditableContents = (
  http: ApiClient,
  page: string,
  frame: string,
  market: string,
  language: string,
) => {
  const isServerSide = process.env.UNIVERSAL_ENV === 'server';
  const primeUrl = generateFileURL(page, frame, market, language);
  const fallBackUrl = generateFileURL(page, frame, market);

  if (isServerSide) {
    const primeValue = getEditableContents(primeUrl);
    if (primeValue) {
      return Promise.resolve(primeValue);
    }
  }

  if (isServerSide) {
    const primeValue = getEditableContents(primeUrl);
    if (primeValue) {
      return Promise.resolve(primeValue);
    }
  }

  return http
    .get(primeUrl, {
      ignoreCommonErrorHandler: true,
      isThirdPartyApi: true,
      cache: true,
    })
    .then(res => {
      setEditableContents(primeUrl, res);
      return res;
    })
    .catch(error => {
      if (language === DEFAULT_SITE_LANGUAGE) {
        throw error;
      }
      if (isServerSide) {
        const fallBackValue = getEditableContents(fallBackUrl);
        if (fallBackValue) {
          return Promise.resolve(fallBackValue);
        }
      }
      return http
        .get(fallBackUrl, {
          ignoreCommonErrorHandler: true,
          isThirdPartyApi: true,
          cache: true,
        })
        .then(res => {
          setEditableContents(fallBackUrl, res);
          return res;
        });
    });
};
