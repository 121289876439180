import { getBookingStep2AtStepId } from 'store/bookingStep/actions';
import { fetchSubscription } from 'store/subscription/actions';

import { universalRouterProps } from 'core/universalRouter/types';

export default ({ store }: universalRouterProps, stepId: string) => {
  const state = store.getState();
  const dispatch = store.dispatch;

  const subscribeEmail = state.bookingStep?.bookingForm?.applicant?.email;

  return Promise.all([
    dispatch(getBookingStep2AtStepId(stepId)),
    dispatch(fetchSubscription(subscribeEmail)),
  ]);
};
