import React, { ReactNode } from 'react';

import { Translate } from 'core/translate';
import { STATUS_TYPE } from 'ReservationList-Types';

export const SORT_OPTIONS = [
  {
    labelId: 'Traveler_Enumeration.Sort.Reservation_Reservation_Date.New_Old',
    value: 'BOOKING_DATE_DESC',
  },
  // {
  //   labelId: 'Traveler_Enumeration.Sort.Reservation_Reservation_Date.Old_New',
  //   value: 'BOOKING_DATE_ASC',
  // },
  // {
  //   labelId: 'Traveler_Enumeration.Sort.Reservation_Check_In_Date.Old_New',
  //   value: 'CHECKIN_DATE_DESC',
  // },
  {
    labelId: 'Traveler_Enumeration.Sort.Reservation_Check_In_Date.New_Old',
    value: 'CHECKIN_DATE_ASC',
  },
]; // Missing label for the text

export const UPCOMING = 'UPCOMING';
export const BOOKED_AS_GUEST = 'BOOKED_AS_GUEST';

export const STATUS_KEYS: STATUS_TYPE[] = ['UPCOMING', 'PAST', 'CANCELED', 'BOOKED_AS_GUEST'];

export const STATUS_MAP: Record<STATUS_TYPE, ReactNode> = {
  UPCOMING: <Translate id="Reservation_List.Header.List_Indicator.Upcoming" />,
  PAST: <Translate id="Reservation_List.Header.List_Indicator.Past" />,
  CANCELED: <Translate id="Reservation_List.Header.List_Indicator.Canceled" />,
  BOOKED_AS_GUEST: <Translate id="Reservation_List.Booked_As_A_Guest" />,
};
