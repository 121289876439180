import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { CancelStep1Items, CancelStep2Items, CancelStepErrors } from 'CancelStep-Types';

import { getCancelStep1Async, postCancelStep2Async } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [postCancelStep2Async.request, getCancelStep1Async.request],
    (state, action) => true,
  )
  .handleAction(
    [
      postCancelStep2Async.success,
      postCancelStep2Async.failure,
      getCancelStep1Async.success,
      getCancelStep1Async.failure,
    ],
    (state, action) => false,
  );

export const step1 = createReducer({} as CancelStep1Items).handleAction(
  getCancelStep1Async.success,
  (state, action) => {
    return action.payload;
  },
);
export const step2 = createReducer({} as CancelStep2Items).handleAction(
  postCancelStep2Async.success,
  (state, action) => {
    return action.payload;
  },
);

export const errors = createReducer({} as CancelStepErrors)
  .handleAction(postCancelStep2Async.failure, (state, action) => action.payload)
  .handleAction([postCancelStep2Async.request, getCancelStep1Async.request], () => {
    return {};
  });

const cancelStepReducer = combineReducers({
  isFetching,
  step1,
  step2,
  errors,
});

export default cancelStepReducer;
export type CancelStepState = ReturnType<typeof cancelStepReducer>;
