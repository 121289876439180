import { ApiClient } from '@travel/api-client';

import {
  AreaDetailsItems,
  AreaInfoResponse,
  AreaInfoSearchRequestBody,
  AreaRelatedItems,
  AreaRelatedRequestBody,
  SearchRequestBody,
} from 'AreaDetails-Types';

export const fetchAreaDetailsItems = (
  http: ApiClient,
  req: SearchRequestBody,
  ignoreCommonErrorHandler?: boolean,
): Promise<AreaDetailsItems> =>
  http.post('/monterosa-api/area-details', {
    method: 'POST',
    req: { ...req },
    values: { ...req },
    ignoreCommonErrorHandler: ignoreCommonErrorHandler,
  });

export const fetchAreaInfoItems = (
  http: ApiClient,
  req: AreaInfoSearchRequestBody,
  ignoreCommonErrorHandler?: boolean,
): Promise<AreaInfoResponse> =>
  http.post('/monterosa-api/area', {
    method: 'POST',
    req: { ...req },
    values: { ...req },
    ignoreCommonErrorHandler: ignoreCommonErrorHandler,
  });

export const fetchAreaRelatedItems = (
  http: ApiClient,
  req: AreaRelatedRequestBody,
  ignoreCommonErrorHandler?: boolean,
): Promise<AreaRelatedItems> =>
  http.post('/monterosa-api/area-related', {
    method: 'POST',
    req: { ...req },
    values: { ...req },
    ignoreCommonErrorHandler: ignoreCommonErrorHandler,
  });
