//import { track } from '@travel/tracker';
//import { Tracer, ExplicitContext, BatchRecorder, jsonEncoder } from 'zipkin';
//import wrapFetch from 'zipkin-instrumentation-fetch';
import AbortController from 'abort-controller';

import loggerGenerator from '@travel/logger';

import {
  getApiUrl,
  getOptions,
  noticeApiError,
  noticeApiStatusError,
  addPrefixToObjectKeys,
} from './utils';
import ApiClientProvider from './ApiClientProvider';
import useApiClient from './useApiClient';
import { noticeError } from '@travel/tracker';
export { ApiClientProvider, useApiClient };
export const DEFAULT_TIMEOUT = 20000;
const logger = loggerGenerator();

export class ApiClient {
  constructor(config) {
    this.server = config.server || false;
    this.baseURLServer = config.baseURLServer;
    this.baseURLClient = config.baseURLClient;
    this.headers = config.headers || {};
    this.verbose = false;
    this.timeout = config.timeout;
  }

  requestInterceptor = ({ url, options }) => ({
    url,
    options,
    verbose: false,
  });

  fetchApi(
    path,
    {
      // http method
      method = 'GET',
      // fetch header
      headers = {},
      // for GET request, values will be appended to query string
      // for other request (like POST), values will be appended to fetch body
      values = {},
      // when withAuthCode is true, the in-house token stored in redux will be attached to fetch header
      withAuthCode = false,
      // timeout
      timeout = this.timeout ?? DEFAULT_TIMEOUT,
      // when cache is true, for GET request, a field '_' with random value will be attached to query,
      // in order to prevent from being cached by browser
      cache = false,
      // flag to define whether the request is to 3rd party API or not
      isThirdPartyApi = false,
      // TODO: make this option deprecated
      ignoreCommonErrorHandler = false,
    },
  ) {
    // eslint-disable-next-line consistent-return
    return new Promise(async (resolve, reject) => {
      const httpRequestDetail = {
        method,
        values,
        path,
      };
      const timeoutResponse = {
        httpRequestDetail,
        status: 408,
        statusText: 'TIMEOUT',
      };
      const controller = new AbortController();
      const baseOptions = getOptions({ values, method, headers });

      // if the raw path is a relative path, which means it doesn't start with 'http' here, combine it with base url
      // otherwise just use the raw path
      let fullPath;
      if (path.startsWith('http')) {
        fullPath = path;
      } else {
        const baseURL = this.server ? this.baseURLServer : this.baseURLClient;
        fullPath = baseURL + path;
      }

      const { options, url, verbose } = this.requestInterceptor({
        options: baseOptions,
        path,
        url: fullPath,
        withAuthCode,
        isThirdPartyApi,
      }) || { options: baseOptions, url: fullPath, verbose: false };

      const apiUrl = method === 'GET' ? getApiUrl(url, values, cache) : url;

      const fetchHeaders = {
        ...this.headers,
        ...options.headers,
      };

      if (verbose) {
        logger.info(`API Headers: ${path} (${apiUrl})`, fetchHeaders, options, values);
      }
      // TODO: Andres: understand the functionality and try to simplify
      const requestOptions = {
        ...options,
        headers: fetchHeaders,
      };

      let spanId, traceId;

      const timeOut = setTimeout(() => {
        noticeApiError('Client request aborted error', {
          url: apiUrl,
          req: requestOptions,
          res: timeoutResponse,
          traceId,
          spanId,
        });
        if (this.server) {
          controller.abort();
        } else {
          reject(timeoutResponse);
        }
      }, timeout);

      //track('API Request', { url: apiUrl });
      let fetchApiCall = (url, options, proxyOptions) => {
        if (!this.server) {
          return fetch(url, { ...options, signal: controller.signal });
        }
        const ProxyAgent = require('proxy-agent');
        const nodeFetch = require('node-fetch').default;

        return nodeFetch(url, {
          ...options,
          agent: new ProxyAgent(proxyOptions),
          signal: controller.signal,
        });
      };

      try {
        const response = await fetchApiCall(apiUrl, requestOptions);
        clearTimeout(timeOut);
        const contentType = response.headers.get('Content-Type');
        if (!response.ok) {
          if (verbose) {
            logger.info(`API ${path} (${apiUrl}) Response status not 200: `, response.status);
          }

          if (this.responseInterceptor) {
            this.responseInterceptor({ response, reject, resolve });
          }

          if (!ignoreCommonErrorHandler && this.commonErrorHandler) {
            this.commonErrorHandler({
              status: response.status,
              url: apiUrl,
              request: values,
              response,
            });
            reject(response);
          } else {
            const commonErrorHandler = this.commonErrorHandler.bind(this, {
              status: response.status,
              url: apiUrl,
              request: values,
              response,
            });

            if (contentType && contentType.includes('application/json')) {
              // default error handling. try to remove it (breaking change)
              try {
                const json = await response.json();

                if (verbose) {
                  logger.info(`API ${path} (${apiUrl}) Response status not 200: `, json);
                }
                noticeApiStatusError(apiUrl, requestOptions, response, json, traceId, spanId);
                reject({
                  httpRequestDetail,
                  ...json,
                  status: response.status,
                  commonErrorHandler,
                });
              } catch (reason) {
                if (verbose) {
                  logger.info(`API reject ${path} (${apiUrl}) with reason:`, reason);
                }
                noticeApiStatusError(apiUrl, requestOptions, response, reason, traceId, spanId);
                reject({
                  httpRequestDetail,
                  status: response.status,
                  commonErrorHandler,
                });
              }
            } else {
              const reqHeaders = addPrefixToObjectKeys('req.headers', requestOptions.headers);
              noticeError('Unprocessed Error: response content type not json', {
                // and response not passed here because it is always '{}'
                ...reqHeaders,
                url: apiUrl,
                traceId,
                spanId,
              });
              reject({
                httpRequestDetail,
                status: response.status,
                commonErrorHandler,
              });
            }
          }
        } else {
          if (contentType && contentType.includes('application/json')) {
            try {
              const json = await response.json();

              if (verbose) {
                logger.info(`API ${method} ${path} (${apiUrl}) `, json);
              }

              if (this.responseInterceptor) {
                this.responseInterceptor({ response, reject, resolve });
              }
              if (json.errors && json.errors.length) {
                noticeApiStatusError(apiUrl, requestOptions, response, json, traceId, spanId);
                reject(json);
              } else {
                resolve(json);
              }
            } catch (reason) {
              if (verbose) {
                logger.info(`API ${method} reject ${path} (${apiUrl}) with reason:`, reason);
              }
              noticeApiStatusError(apiUrl, requestOptions, response, reason, traceId, spanId);
              reject(response);
            }
          } else {
            resolve(response);
          }
        }
      } catch (e) {
        if (this.responseInterceptor) {
          this.responseInterceptor({ response: timeoutResponse, reject, resolve });
        }
        const reqHeaders = addPrefixToObjectKeys('req.headers', requestOptions.headers);
        noticeError('API client error', {
          ...reqHeaders,
          'req.body': JSON.stringify(requestOptions.body || ''),
          error: e,
          url: apiUrl,
          res: timeoutResponse,
          traceId,
          spanId,
        });
        // network error
        clearTimeout(timeOut);
        logger.error(apiUrl, e);
        reject(timeoutResponse);
      }
    });
  }

  setRequestInterceptor(interceptor) {
    this.requestInterceptor = interceptor;
  }

  setResponseInterceptor(interceptor) {
    this.responseInterceptor = interceptor;
  }

  setCommonErrorHandler(commonErrorHandler) {
    this.commonErrorHandler = commonErrorHandler;
  }

  get(path, config) {
    return this.fetchApi(path, { ...config, method: 'GET' });
  }

  post(path, config) {
    return this.fetchApi(path, { ...config, method: 'POST' });
  }

  put(path, config) {
    return this.fetchApi(path, { ...config, method: 'PUT' });
  }

  patch(path, config) {
    return this.fetchApi(path, { ...config, method: 'PATCH' });
  }

  delete(path, config) {
    return this.fetchApi(path, { ...config, method: 'DELETE' });
  }
}
