import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { DEVICE_ID } from '@travel/traveler-core/constants';
import { BookingStep1FormData } from '@travel/traveler-core/types/bookingStep';
import { isEmptyObject } from '@travel/utils';

import { pushLocation } from 'store/__router/actions';
import { fetchProviderInformation } from 'store/providerInformation/actions';
import { getItem } from 'store/providerInformation/selectors';
import { fetchCouponDetails } from 'store/salesPromotion/actions';

import {
  ApplySpAndPoint,
  BookingStep3,
  BookingStepData,
  BookingStepErrors,
  CouponData,
} from 'BookingStep-Types';
import paths from 'core/universalRouter/paths';
import { getPreviousPurchase, setPreviousPurchase } from 'utils/analytics';

import {
  applySpAndPoint,
  bookingStep1,
  bookingStep2,
  bookingStep3,
  getBookingStep2,
  PostBodyApplySpAndPoint,
  PostBodyStep3,
} from './api';

export type Step1QueryParam = {
  checkInDate: string;
  checkOutDate: string;
  childAges?: string;
  itemId: string;
  noOfAdults: number;
  ratePlanId: string;
  providerId: string;
  itemRatePlanId: string;
  roomCount: number;
  appliedSalesPromotionId?: string | null;
  perRoomPriceBeforeDiscount: number;
  perRoomPriceAfterDiscount: number | null;
};

export type PaymentInfo = {
  selectedPaymentMethod: string;
};

export const postBookingStep1Async = createAsyncAction(
  'POST_BOOKING_STEP1_REQUEST',
  'POST_BOOKING_STEP1_SUCCESS',
  'POST_BOOKING_STEP1_FAILURE',
)<undefined, BookingStepData, BookingStepErrors>();

export const postBookingSpAndPointAsync = createAsyncAction(
  'POST_BOOKING_SP_AND_POINT_REQUEST',
  'POST_BOOKING_SP_AND_POINT_SUCCESS',
  'POST_BOOKING_SP_AND_POINT_FAILURE',
)<undefined, ApplySpAndPoint, BookingStepErrors>();

export const savePaymentMethod = createStandardAction('SET_BOOKING_STEP2_PAYMENT_DATA')<
  PaymentInfo
>();

export const saveBookingStep1FormData = createStandardAction('SET_BOOKING_STEP1_FORM_DATA')<
  BookingStep1FormData
>();

export const postBookingStep1 = (postBody: Step1QueryParam): any => async (
  dispatch: any,
  _getState: any,
  { apiClient }: any,
) => {
  dispatch(postBookingStep1Async.request());
  try {
    const childAges = postBody.childAges ? postBody.childAges?.split(',') : [];
    const formattedPostBody = { ...postBody, childAges };
    const response = await bookingStep1(apiClient, formattedPostBody);
    dispatch(postBookingStep1Async.success(response));
  } catch (error) {
    dispatch(postBookingStep1Async.failure({ status: error?.status, errors: error?.errors }));
  }
};

export const postBookingStep2Async = createAsyncAction(
  'POST_BOOKING_STEP2_REQUEST',
  'POST_BOOKING_STEP2_SUCCESS',
  'POST_BOOKING_STEP2_FAILURE',
)<undefined, BookingStepData, BookingStepErrors>();

export const postBookingStep2 = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  const state = getState();

  dispatch(postBookingStep2Async.request());
  try {
    const postBody = {
      ...state.bookingStep.bookingForm,
      stepId: state.bookingStep.bookingData.stepId,
      providerQuestions: state.bookingStep.bookingForm.providerQuestions?.map(item => {
        return {
          id: item.id,
          answer: item.answer,
        };
      }),
      itemPlans: state.bookingStep.bookingForm.itemPlans.map(item => {
        return {
          ...item,
          guests: item.guests.filter(
            guest => guest.firstName.trim() !== '' && guest.lastName.trim() !== '',
          ),
        };
      }),
    };
    const response = await bookingStep2(apiClient, postBody);
    dispatch(postBookingStep2Async.success(response));
    dispatch(
      pushLocation({
        pathname: paths.bookingStep2.path,
        search: `stepId=${response.stepId}`,
      }),
    );
  } catch (error) {
    dispatch(postBookingStep2Async.failure({ status: error?.status, errors: error?.errors }));
  }
};

export const getBookingStep2Async = createAsyncAction(
  'GET_BOOKING_STEP2_REQUEST',
  'GET_BOOKING_STEP2_SUCCESS',
  'GET_BOOKING_STEP2_FAILURE',
)<undefined, BookingStepData, BookingStepErrors>();

export const getBookingStep2AtStepId = (stepId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(getBookingStep2Async.request());
  try {
    const response = await getBookingStep2(apiClient, stepId);
    if (response.appliedBinRestrictedCouponId) {
      dispatch(fetchCouponDetails(response.appliedBinRestrictedCouponId, true, true));
    }
    dispatch(getBookingStep2Async.success(response));
    // incase providerInfo is empty on reload we fill it again so we have the data for our analytic (RAT)
    const state = getState();
    const providerItem = getItem(state);
    if (isEmptyObject(providerItem)) {
      await dispatch(fetchProviderInformation(response.pricingDetails[0].providerId));
    }
  } catch (error) {
    dispatch(getBookingStep2Async.failure({ status: error?.status, errors: error?.errors }));
  }
};

const formatSpAndPointBody = (
  bookingForm: BookingStep1FormData,
  stepId: string = '',
  selectedItemId: string = '',
  newSalePromotionId: string = '',
  newSubSalePromotionID: string = '',
  acquiredSalesPromotionId: string = '',
): PostBodyApplySpAndPoint => {
  const itemPlans = bookingForm.itemPlans.map(
    ({
      echoId,
      itemId,
      itemRatePlanId,
      appliedSalesPromotionId,
      appliedSubSalesPromotionId,
      totalPointsUsed,
      appliedExtraRates,
    }) => ({
      echoId,
      itemId,
      itemRatePlanId,
      appliedSalesPromotionId:
        selectedItemId && echoId === selectedItemId
          ? newSalePromotionId
          : appliedSalesPromotionId || '',
      totalPointsUsed,
      appliedSubSalesPromotionId:
        selectedItemId && echoId === selectedItemId
          ? newSubSalePromotionID
          : appliedSubSalesPromotionId || '',
      appliedExtraRates,
    }),
  );
  return {
    stepId: stepId || bookingForm.stepId,
    itemPlans,
    acquiredSalesPromotionId,
  };
};

export const postSpAndPoint = (
  updatedBookingForm: BookingStep1FormData,
  itemId?: string,
  salePromotionId?: string,
  subSalePromotionId?: string,
  acquiredSalesPromotionId?: string,
): AppThunk<Promise<ApplySpAndPoint | undefined>> => async (dispatch, getState, { apiClient }) => {
  const state = getState();
  const stepId = state.bookingStep.bookingData.stepId;
  dispatch(postBookingSpAndPointAsync.request());
  try {
    const response = await applySpAndPoint(
      apiClient,
      formatSpAndPointBody(
        updatedBookingForm,
        stepId,
        itemId,
        salePromotionId,
        subSalePromotionId,
        acquiredSalesPromotionId,
      ),
    );
    dispatch(postBookingSpAndPointAsync.success(response));
    return response;
  } catch (error) {
    dispatch(postBookingSpAndPointAsync.failure({ status: error?.status, errors: error?.errors }));
  }
};

export const postBookingStep3Async = createAsyncAction(
  'POST_BOOKING_STEP3_REQUEST',
  'POST_BOOKING_STEP3_SUCCESS',
  'POST_BOOKING_STEP3_FAILURE',
)<undefined, BookingStep3, BookingStepErrors>();

export const postBookingStep3 = (postBody: PostBodyStep3): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(postBookingStep3Async.request());
  try {
    const response = await bookingStep3(apiClient, postBody);
    dispatch(postBookingStep3Async.success(response));
    dispatch(savePaymentMethod({ selectedPaymentMethod: postBody.payment.method }));
    const { bookingId, reservations } = response;
    dispatch(
      pushLocation({
        pathname: paths.bookingStep3.path,
        search: `bookingId=${bookingId}&reservationId=${reservations[0].reservationId}`,
      }),
    );
    // save a cookie on the device that this user made a purchase
    // get current value of cookie
    const state = getState();
    const trackId = state.member?.item?.trackId;
    const { normalizedCookies } = state._httpRequest;
    const deviceId = normalizedCookies[DEVICE_ID];
    const pastPurchaseObj = getPreviousPurchase();
    if (trackId || deviceId) {
      pastPurchaseObj[trackId || deviceId] = true;
    }
    setPreviousPurchase(pastPurchaseObj);
  } catch (error) {
    dispatch(postBookingStep3Async.failure({ status: error?.status, errors: error?.errors }));
  }
};

export const acquiredCoupon = createStandardAction('APPLY_ACQUIRED_COUPON')<CouponData>();
