import { ApiClient } from '@travel/api-client';
import env from '@travel/env';

const isServer = process.env.UNIVERSAL_ENV === 'server';

const TIMEOUT_SERVER = 10000;

function createApiClient() {
  let apiClient;

  if (env('UNIVERSAL_API_MOCK_SERVER') === 'TRUE') {
    const mockApiServerURL = env('UNIVERSAL_MOCK_API_SERVER_URL');
    if (!mockApiServerURL) {
      throw new Error(
        'Environment variable "UNIVERSAL_MOCK_API_SERVER_URL" must be set when starting app with mock server(UNIVERSAL_API_MOCK_SERVER=true)',
      );
    }
    apiClient = new ApiClient({
      baseURLServer: mockApiServerURL,
      baseURLClient: mockApiServerURL,
      server: isServer,
    });
  } else {
    const baseURLServer = env('UNIVERSAL_API_BASE_URL_SERVER');
    const baseURLClient = env('UNIVERSAL_API_BASE_URL_CLIENT');
    if (!baseURLServer || !baseURLClient) {
      throw new Error(
        'Environment variable "UNIVERSAL_API_BASE_URL_SERVER" and "UNIVERSAL_API_BASE_URL_CLIENT" must be set',
      );
    }
    apiClient = new ApiClient({
      baseURLServer,
      baseURLClient,
      server: isServer,
      ...(isServer && { timeout: TIMEOUT_SERVER }),
    });
  }

  return apiClient;
}

export { createApiClient };
