import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { AMOUNT_PER_LOAD } from 'pages/LegacyReservationListPage';

import {
  LegacyReservationList,
  LegacyReservationListErrors,
  STATUS_TYPE,
} from 'LegacyReservationList-Types';

import {
  fetchLegacyReservationListAsync,
  fetchMoreLegacyReservationsAsync,
  setLastStatusType,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchLegacyReservationListAsync.request], (state, action) => true)
  .handleAction(
    [fetchLegacyReservationListAsync.success, fetchLegacyReservationListAsync.failure],
    (state, action) => false,
  );

export const isFetchingMore = createReducer(false as boolean)
  .handleAction([fetchMoreLegacyReservationsAsync.request], (state, action) => true)
  .handleAction(
    [fetchMoreLegacyReservationsAsync.success, fetchMoreLegacyReservationsAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as LegacyReservationList)
  .handleAction(fetchLegacyReservationListAsync.success, (state, action) => {
    return {
      ...action.payload,
      isEnd: AMOUNT_PER_LOAD > action.payload.reservations.length,
    };
  })
  .handleAction(fetchMoreLegacyReservationsAsync.success, (state, action) => {
    return {
      offset: action.payload.offset,
      isEnd: AMOUNT_PER_LOAD > action.payload.reservations.length,
      reservations: state.reservations.concat(action.payload.reservations),
    };
  });

export const errors = createReducer([] as LegacyReservationListErrors[]).handleAction(
  fetchLegacyReservationListAsync.failure,
  (state, action) => action.payload,
);

export const selectedStatusType = createReducer('UPCOMING' as STATUS_TYPE).handleAction(
  setLastStatusType,
  (state, action) => action.payload,
);

const legacyReservationListReducer = combineReducers({
  isFetching,
  items,
  errors,
  selectedStatusType,
  isFetchingMore,
});

export default legacyReservationListReducer;
export type LegacyReservationListState = ReturnType<typeof legacyReservationListReducer>;
