import { AppThunk, createAsyncAction } from 'typesafe-actions';

import {
  PostEsimCampaignFailResponse,
  PostEsimCampaignRequestBody,
  PostEsimCampaignSuccessResponse,
} from 'PostEsimCampaigneReview-Types';

import { postEsimCampaign } from './apis';

export const fetchPostEsimCampaignAsync = createAsyncAction(
  'FETCH_ESIM_CAMPAIGN_REQUEST',
  'FETCH_ESIM_CAMPAIGN_SUCCESS',
  'FETCH_ESIM_CAMPAIGN_FAILURE',
)<undefined, PostEsimCampaignSuccessResponse, PostEsimCampaignFailResponse>();

export const PostEsimCampaign = (req: PostEsimCampaignRequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchPostEsimCampaignAsync.request());

  const response = await postEsimCampaign(apiClient, req);

  if (!response.status) {
    dispatch(fetchPostEsimCampaignAsync.success(response));
  } else {
    dispatch(
      fetchPostEsimCampaignAsync.failure({
        error: { code: response?.error.code, message: response?.error.message },
      }),
    );
  }
};
