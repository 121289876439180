import React from 'react';

import Media from 'components/Media';
import Money from 'components/Money';

import { Translate } from 'core/translate';
import { ProviderItem as Provider } from 'ProviderList-Types';

import ReviewLink from '../../ProviderListPage/components/ReviewLink';

import styles from './providerItem.module.scss';

type Props = {
  market: string;
  provider: Provider;
};

function ProviderItem({ provider, market }: Props) {
  const {
    name,
    media,
    price,
    reviews: { score, count, label },
  } = provider;

  return (
    <div className={styles.itemContainer} data-testid="guideUserPage-providerItem-wrapper">
      <p className={styles.title} data-testid="guideUserPage-providerItem-name">
        {name}
      </p>
      <ReviewLink
        className={styles.review}
        market={market}
        reviewScore={score}
        totalReviews={count}
        reviewLabel={label}
      />
      <div className={styles.priceContainer}>
        <Media media={media[0]} className={styles.image} />
        <div className={styles.price} data-testid="guideUserPage-providerItem-price">
          <p className={styles.priceTitle}>
            <Translate id="Guide_User_Inventory.Item_Starting_At" />
          </p>
          <Money value={price?.total} />
        </div>
      </div>
    </div>
  );
}

export default ProviderItem;
