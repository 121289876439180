import React, { RefObject } from 'react';

import { cx } from '@travel/utils';

import useDeviceType from '../../utils/useDeviceType';
import NavigationButton from '../NavigationButton';

import styles from './contentsCarousel.module.scss';

export const DISPLAY_CARDS = {
  PC: 4,
};
const PC_MAX = 1200;
const SPACING_BASE = 8;
const INITIAL_TRANSLATE_X = '0';

export const getPrevTranslateX = (contentsRef: RefObject<HTMLDivElement>) => {
  const regex = /-?\d+/;
  const prev = (contentsRef &&
    contentsRef.current &&
    contentsRef.current.style.cssText.match(regex)) || [INITIAL_TRANSLATE_X];
  return Number(prev[0]);
};

export const setCondition = (
  deviceType: string,
  currentNumber: number,
  cardWidth: number,
  wrapperRef: RefObject<HTMLDivElement>,
  contentsRef: RefObject<HTMLDivElement>,
  setWrapperHeight: (height: number) => void,
  setContentHeight: (height: number) => void,
  setCardWidth: (width: number) => void,
  setDisplayItemCount: (count: number) => void,
  updateTranslateX: (x: number) => void,
  fixedCardWidth?: number,
  fixedDisplayCount?: number,
) => {
  if (contentsRef.current) setWrapperHeight(contentsRef.current.clientHeight + SPACING_BASE * 2);
  if (contentsRef.current) setContentHeight(contentsRef.current.clientHeight);
  if (deviceType !== 'sp') {
    // for PC: carouselWidth isn't over max-width for PC, and cardWidth is changed when parent Component resized
    const carouselWidth =
      wrapperRef.current && wrapperRef.current.clientWidth <= PC_MAX
        ? wrapperRef.current.clientWidth
        : PC_MAX;
    const nextCardWidth = fixedCardWidth ? fixedCardWidth : carouselWidth / DISPLAY_CARDS.PC;
    setCardWidth(nextCardWidth);
    setDisplayItemCount(
      fixedDisplayCount
        ? fixedDisplayCount
        : fixedCardWidth
        ? Math.floor(carouselWidth / nextCardWidth)
        : DISPLAY_CARDS.PC,
    );

    const left = -cardWidth * currentNumber;
    if (left !== getPrevTranslateX(contentsRef)) {
      updateTranslateX(left);
    }
  }
};

type Props = {
  current: number;
  length: number;
  displayItemCount: number;
  onClick: (direction: string) => void;
  buttonClassName?: string;
  children: React.ReactNode;
  ariaLabelLeftButton?: string;
  ariaLabelRightButton?: string;
};

export const CarouselNavButtonContainer = ({
  current = 0,
  length = 0,
  onClick = () => {},
  displayItemCount = DISPLAY_CARDS.PC,
  buttonClassName,
  children,
  ariaLabelLeftButton,
  ariaLabelRightButton,
}: Props) => {
  const hasNavigationButtons = length > displayItemCount;
  const shouldRenderButtons = useDeviceType() !== 'sp' && hasNavigationButtons;

  const handleClickLeft = () => onClick('left');

  const handleClickRight = () => onClick('right');

  const buttonFlag = {
    left: current !== 0,
    right: current !== length - displayItemCount,
  };
  return (
    <div className={styles.buttonWrapper}>
      {shouldRenderButtons && buttonFlag.left && (
        <NavigationButton
          direction="left"
          navigationType="dark"
          className={cx(buttonClassName, styles.left)}
          onClick={handleClickLeft}
          ariaLabel={ariaLabelLeftButton}
        />
      )}
      {children}
      {shouldRenderButtons && buttonFlag.right && (
        <NavigationButton
          direction="right"
          navigationType="dark"
          className={cx(buttonClassName, styles.right)}
          onClick={handleClickRight}
          ariaLabel={ariaLabelRightButton}
        />
      )}
    </div>
  );
};
