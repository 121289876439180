import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCookie, isEmptyObject } from '@travel/utils';

import { updateShortUrl } from '../store/ranStore/actions';
import { fetchShortUrl } from '../store/ranStore/apis';
import { getRanData, getShortUrl } from '../store/ranStore/selectors';
import { RanData } from '../store/ranStore/types';

import { ONE_TRUST_CONSENT, ONE_TRUST_TRACKING_ON_VAL, RAN_COOKIE } from '../constants';
import { JSONparse } from '../utils/helper';

const useShortUrlQr = (storeName: string, isOpen: boolean) => {
  const dispatch = useDispatch();

  const ranData = useSelector(getRanData);
  const { iOS: iOSShortUrl, android: androidShortUrl } = useSelector(getShortUrl);

  const shouldCustomQRUrlFunc = useCallback(() => {
    const consentCookie = getCookie(ONE_TRUST_CONSENT);
    const isTrackingAllowed = consentCookie?.includes(ONE_TRUST_TRACKING_ON_VAL);
    const ranDataToAdd: RanData | null =
      JSONparse<RanData>(getCookie(RAN_COOKIE)) || ranData || null;

    return isTrackingAllowed && !isEmptyObject(ranDataToAdd);
  }, [ranData]);

  const [shouldCustomQRUrl, setShouldCustomQRUrl] = useState(shouldCustomQRUrlFunc());

  useEffect(() => {
    // on open check if we should show the short URL
    if (isOpen) {
      setShouldCustomQRUrl(shouldCustomQRUrlFunc());
    }
  }, [isOpen, shouldCustomQRUrlFunc]);

  useEffect(() => {
    let urlToShorten = iOSShortUrl.url;
    let key = 'iOS';
    let shouldCall = !iOSShortUrl.hasShort;
    if (storeName === 'playStore') {
      urlToShorten = androidShortUrl.url;
      key = 'android';
      shouldCall = !androidShortUrl.hasShort;
    }

    if (isOpen && shouldCustomQRUrl && urlToShorten && shouldCall) {
      fetchShortUrl(urlToShorten).then(str => {
        const parsedXML = new DOMParser().parseFromString(str, 'application/xml');
        const shortUrl = parsedXML.getElementsByTagName('url')[0].innerHTML;
        dispatch(updateShortUrl({ [key]: { hasShort: true, url: shortUrl } }));
      });
    }
  }, [isOpen, storeName, shouldCustomQRUrl, iOSShortUrl, androidShortUrl, dispatch]);

  return shouldCustomQRUrl;
};

export default useShortUrlQr;
