import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLoadingStatus } from 'store/pageLoading/actions';
import { getIsLoading, getLatestPageName } from 'store/pageLoading/selectors';

// The hooks cannot preserve its state on URL changed (including query string updated).
// the pageName is expected to handle the loader appearance.
function usePageLoaderOnInitial(pageName: string, isLoading: boolean) {
  const dispatch = useDispatch();
  const prevLoadedPageName = useSelector(getLatestPageName);
  const prevIsLoading = useSelector(getIsLoading);

  useEffect(() => {
    if (prevLoadedPageName !== pageName || (prevLoadedPageName === pageName && prevIsLoading)) {
      dispatch(setLoadingStatus({ isPageLoading: isLoading, pageName }));
    }
  }, [dispatch, isLoading, pageName, prevIsLoading, prevLoadedPageName]);
}

export default usePageLoaderOnInitial;
