import { universalRouterProps } from 'core/universalRouter/types';

import { getNonMemberVer } from '../../store/member/actions';

type SearchProps = {
  params: { token: string; deviceId: string };
};

export default async ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  return await dispatch(getNonMemberVer(search.params.deviceId, search.params.token));
};
