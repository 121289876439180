import React from 'react';
import { useSelector } from 'react-redux';

import { getMarket } from '@travel/i18n';
import { getL10nNumber } from '@travel/i18n/src/components/L10nNumber';
import { Coupon } from '@travel/icons/ui';

import Money from 'components/Money';

import { INCENTIVE_CALCULATION_METHOD, INCENTIVE_METHOD } from 'constants/salesPromotion';
import { Translate } from 'core/translate';
import { IncentiveAmount } from 'SalesPromotion-Types';

type Props = Partial<IncentiveAmount> & {
  currency: string;
  localCurrency?: string;
  isShowCouponIcon?: boolean;
  className?: string;
  localAmountClassName?: string;
};

export const IncentiveValueTitle = (props: Props) => {
  const marketFromStore = useSelector(getMarket)?.marketCode || '';

  const isDiscountPromotion = props?.incentiveMethod === INCENTIVE_METHOD.DISCOUNT;
  const isPercentValue = props?.incentiveCalcMethod === INCENTIVE_CALCULATION_METHOD.PERCENT;
  const status = isDiscountPromotion ? 'discount_promotion' : 'point_promotion';

  const isShowLocalAmount =
    !isPercentValue &&
    ((isDiscountPromotion && props.localCurrency && props.currency !== props.localCurrency) ||
      (!isDiscountPromotion && props.pointCurrency && props.currency !== props.pointCurrency));

  const percentage = props?.percentage
    ? getL10nNumber(marketFromStore, props?.percentage || 0, undefined, true)
    : 0;

  return (
    <div className={props.className} data-testid="incentiveValueTitle-wrapper">
      <div data-testid="incentiveValueTitle-userAmount">
        {props.isShowCouponIcon && <Coupon size={20} />}
        <Translate
          id="Coupon_Details.Coupon.Value_And_Suffix"
          condition={() => `status == ${status}`}
          data={{
            percentage: isPercentValue ? (
              <span>{`${percentage}%`}</span>
            ) : (
              <Money value={props?.amount || 0} />
            ),
            point_back: isPercentValue ? (
              <span>{`${percentage}%`}</span>
            ) : (
              <Money value={props?.amount || 0} />
            ),
          }}
        />
        {isShowLocalAmount && <span>&nbsp;*</span>}
      </div>
      {isShowLocalAmount && (
        <div data-testid="incentiveValueTitle-localAmount" className={props.localAmountClassName}>
          {isDiscountPromotion ? (
            <Translate
              id="Coupon_Details.Maximum_Discount.Local_Amount.Displayed_Information"
              condition={() => `status == different_currency`}
              data={{
                local_amount: (
                  <Money value={props?.localAmount || 0} currency={props?.localCurrency} />
                ),
              }}
            />
          ) : (
            <Translate
              id="Coupon_List.Coupon.Local_Amount"
              condition={() => `status == different_currency`}
              data={{
                local_amount: (
                  <Translate
                    id="Coupon_Details.Coupon.Value_And_Suffix"
                    condition={() => `status == ${status}`}
                    data={{
                      point_back: <Money value={props?.localAmount || 0} />,
                    }}
                  />
                ),
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default IncentiveValueTitle;
