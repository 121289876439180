import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLanguage } from '@travel/i18n';
import { Hotel } from '@travel/icons/service';
import { ArrowLeft } from '@travel/icons/ui';
import NavigationTab from '@travel/traveler-core/components/NavigationTab';
import { useDeviceType } from '@travel/traveler-core/hooks';
import { IconTextLink } from '@travel/ui';
import { as, isNotEmptyArray } from '@travel/utils';

import DataLayer from 'components/DataLayer';
import InfiniteScroll from 'components/InfiniteScroll';
import WithSkeletonLoading from 'components/WithSkeletonLoading';
import { BOOKED_AS_GUEST, STATUS_KEYS, STATUS_MAP } from 'pages/ReservationListPage/constants';

import {
  fetchLegacyReservationList,
  fetchMoreLegacyReservationList,
  setLastStatusType,
} from 'store/legacyReservationList/actions';
import {
  getIsFetching,
  getIsFetchingMore,
  getItems,
  getSelectedStatusType,
} from 'store/legacyReservationList/selectors';
import { getLatestPageName } from 'store/pageLoading/selectors';

import { PAGE_NAMES } from 'constants/reservationList';
import { Translate } from 'core/translate';
import { Link } from 'core/universalRouter/Link';
import paths from 'core/universalRouter/paths';
import usePageLoaderOnInitial from 'hooks/usePageLoaderOnInitial';
import { LegacyReservationListPostBody, STATUS_TYPE } from 'LegacyReservationList-Types';

import ReservationItem from './components/ReservationItem';

import styles from './legacyReservationListPage.module.scss';

type Query = {
  category: STATUS_TYPE;
};

type Props = {
  query: Query;
};

export const AMOUNT_PER_LOAD = 5;

function LegacyReservationListPage(props: Props) {
  const items = useSelector(getItems);
  const isFetching = useSelector(getIsFetching);
  const isFetchingMore = useSelector(getIsFetchingMore);
  const lastStatusSelected = useSelector(getSelectedStatusType);
  const latestPage = useSelector(getLatestPageName);

  const language = useSelector(getLanguage);
  const dispatch = useDispatch();
  const isSP = useDeviceType() === 'sp';

  const [selectedTab, setSelectedTab] = useState(
    as<STATUS_TYPE>(
      props?.query?.category ||
        (latestPage === 'legacyReservationDetail' ? lastStatusSelected : 'UPCOMING'),
    ),
  );

  usePageLoaderOnInitial('legacyReservationList', isFetching);

  useEffect(() => {
    const init = {
      offset: 0,
      limit: AMOUNT_PER_LOAD,
      reservationList: selectedTab,
    };
    dispatch(fetchLegacyReservationList(init));
    dispatch(setLastStatusType(selectedTab));
  }, [dispatch, selectedTab]);

  const req = useCallback(
    (selectedTab: STATUS_TYPE): LegacyReservationListPostBody => ({
      offset: 0,
      limit: AMOUNT_PER_LOAD,
      reservationList: selectedTab,
    }),
    [],
  );

  const onChangeTab = useCallback(
    (event: ChangeEvent) => {
      setSelectedTab(as<STATUS_TYPE>(event.currentTarget.id));
      const onChangeTabReq = req(as<STATUS_TYPE>(event.currentTarget.id));
      dispatch(fetchLegacyReservationList(onChangeTabReq));
    },
    [dispatch, req],
  );

  const loadMoreItems = useCallback(() => {
    if (!isFetchingMore) {
      dispatch(
        fetchMoreLegacyReservationList({
          offset: items.offset + AMOUNT_PER_LOAD,
          limit: AMOUNT_PER_LOAD,
          reservationList: selectedTab,
        }),
      );
    }
  }, [dispatch, isFetchingMore, items.offset, selectedTab]);

  const content = (
    <div className={styles.list}>
      <div className={styles.reservationWrapper}>
        <WithSkeletonLoading
          isLoading={isFetching}
          count={1}
          customStyle="text"
          className={styles.skeletonItem}
        >
          {isNotEmptyArray(items?.reservations) ? (
            <InfiniteScroll
              next={loadMoreItems}
              isFetching={isFetchingMore}
              hasMore={!items.isEnd}
              loader={
                <WithSkeletonLoading
                  isLoading={isFetchingMore}
                  count={1}
                  customStyle="text"
                  className={styles.skeletonItem}
                />
              }
            >
              {items.reservations.map(item =>
                isSP ? (
                  <Link
                    to={paths.legacyReservationDetail.pathResolver(item.reservationId)}
                    key={`go-to-reservation-detail-${item.reservationId}`}
                    className={styles.reservationItemLink}
                  >
                    <ReservationItem
                      key={item.reservationId}
                      selectedTab={selectedTab}
                      language={language}
                      {...item}
                    />
                  </Link>
                ) : (
                  <ReservationItem
                    key={item.reservationId}
                    selectedTab={selectedTab}
                    language={language}
                    {...item}
                  />
                ),
              )}
            </InfiniteScroll>
          ) : (
            <div
              className={styles.noItemContainer}
              data-testid="legacyReservationList-noReservations"
            >
              <Hotel size={isSP ? 60 : 100} color="pastelGray" />
              <Translate id="Reservation_List.No_Resevations" />
            </div>
          )}
        </WithSkeletonLoading>
      </div>

      <DataLayer
        pageName={PAGE_NAMES[selectedTab]}
        pageType="top"
        siteSection="reservations_details"
        updateData={selectedTab}
      />
    </div>
  );

  const tabs = STATUS_KEYS.filter(key => key !== BOOKED_AS_GUEST).map((status: string) => ({
    id: status,
    title: STATUS_MAP[as<STATUS_TYPE>(status)],
    content: content,
  }));

  const tabProps = {
    tabs: tabs,
    onClick: onChangeTab,
    isSwipePanel: false,
    tabMenuClassName: styles.tabMenu,
    selectedTab: selectedTab,
    hasTouchEvents: false,
  };

  return (
    <div data-testid="legacyReservationListPage-wrapper">
      <Link to={paths.reservationList.path}>
        <IconTextLink
          className={styles.detailLink}
          icon={<ArrowLeft size={16} color={'cilantro'} />}
          text={<Translate id="Reservation_Details.Header.Back" />}
          iconPosition={'left'}
        />
      </Link>

      <div className={styles.title}>
        <Translate id="MAUI_Legacy.Reservation_List_Section.Title" />
      </div>

      <NavigationTab {...tabProps} />
    </div>
  );
}

export default LegacyReservationListPage;
