import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { fetchFeatureConfigs } from './apis';

type KeyValuePair = {
  [key: string]: any;
};

export const fetchFeatureConfigAsync = createAsyncAction(
  'FETCH_FEATURE_CONFIG_REQUEST',
  'FETCH_FEATURE_CONFIG_SUCCESS',
  'FETCH_FEATURE_CONFIG_FAILURE',
)<undefined, KeyValuePair, unknown>();

export const setFeatureConfigFromCDN = createStandardAction('SET_FEATURE_CONFIG_FROM_CDN')<
  KeyValuePair
>();

export const fetchFeatureConfigurations = (fileName: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchFeatureConfigAsync.request());
    const response = await fetchFeatureConfigs(apiClient, fileName);
    dispatch(fetchFeatureConfigAsync.success(response));
  } catch (error) {
    dispatch(fetchFeatureConfigAsync.failure(error));
  }
};
