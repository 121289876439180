import { ApiClient } from '@travel/api-client';

import {
  CancelStep1Items,
  CancelStep2Items,
  GetCancelStep1,
  NewCreditCard,
  PostCancelStep2,
} from 'CancelStep-Types';

export const cancelStep1 = (http: ApiClient, req: GetCancelStep1): Promise<CancelStep1Items> =>
  http.get('/consumer/mypage/cancel-step1/' + req.reservationId, {
    headers: {},
  });

export const cancelStep2 = (
  http: ApiClient,
  req: PostCancelStep2,
  data?: NewCreditCard,
): Promise<any> =>
  http
    .post('/consumer/mypage/cancel-step2/' + req.reservationId, {
      headers: {},
      ignoreCommonErrorHandler: true,
      values: data,
    })
    .then((data: CancelStep2Items) => data)
    .catch((err: any) => err);
