import { FeatureGroup } from 'BookingStep-Types';

export function normalizeFeatureGroups(featureGroups: FeatureGroup[]) {
  const normalizedFeatureGroups: FeatureGroup[] = featureGroups.map(
    (featureGroup: FeatureGroup) => {
      const normalizedFeatureGroup: FeatureGroup = {
        type: featureGroup.type || '',
        name: featureGroup.name || '',
        id: featureGroup.id || '',
        features: featureGroup.features || [],
      };

      return normalizedFeatureGroup;
    },
  );

  return normalizedFeatureGroups;
}
