import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { pushLocation } from 'store/__router/actions';

import paths from 'core/universalRouter/paths';
import {
  LegacyCancelStep2Item,
  LegacyCancelStepErrors,
  PostCancelStep2,
} from 'LegacyCancelStep-Types';

import { cancelStep2 } from './api';

export const postLegacyCancelStep2Async = createAsyncAction(
  'POST_LEGACY_CANCEL_STEP2_REQUEST',
  'POST_LEGACY_CANCEL_STEP2_SUCCESS',
  'POST_LEGACY_CANCEL_STEP2_FAILURE',
)<undefined, LegacyCancelStep2Item, LegacyCancelStepErrors>();

export const postLegacyCancelStep2 = (
  req: PostCancelStep2,
  data: { email: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(postLegacyCancelStep2Async.request());
  const response = await cancelStep2(apiClient, req, data);
  if (!response.status) {
    dispatch(postLegacyCancelStep2Async.success(response));
    dispatch(pushLocation(paths.legacyCancellationStep2.pathResolver(response.reservationId)));
  } else {
    dispatch(
      postLegacyCancelStep2Async.failure({ status: response?.status, errors: response?.errors }),
    );
  }
};
