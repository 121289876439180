import { ApiClient } from '@travel/api-client';

import {
  ModificationStep2,
  ModificationStep3,
  PostModificationStep2,
  PostModificationStep3,
} from 'Modification-Types';

export const modificationStep2 = (
  http: ApiClient,
  reservationId: string,
  req: PostModificationStep2,
): Promise<ModificationStep2> =>
  http
    .post(`/consumer/mypage/shorten-step2/` + reservationId, { values: req })
    .then((data: ModificationStep2) => data);

export const modificationStep3 = (
  http: ApiClient,
  reservationId: string,
  req: PostModificationStep3,
): Promise<any> =>
  http
    .post('/consumer/mypage/shorten-step3/' + reservationId, {
      values: req,
      ignoreCommonErrorHandler: true,
    })
    .then((data: ModificationStep3) => data)
    .catch((err: any) => err);
