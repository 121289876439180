import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrency, L10nDate, L10nNumber } from '@travel/i18n';
import { Checkmark, Coupon, RakutenPoints } from '@travel/icons/ui';
import { ACQUIRED_STATUS } from '@travel/traveler-core/constants/salesPromotion';
import { IconText, IconTextList } from '@travel/ui';
import { cx } from '@travel/utils';

import IncentiveValueTitle from 'components/IncentiveValueTitle';
import Media from 'components/Media';
import Money from 'components/Money';

import { OFFER_TYPE } from 'constants/salesPromotion';
import { Translate } from 'core/translate';
import { Plan } from 'ProviderRatePlan-Types';

import { mappedIconTextListData } from '../../ProviderInformationPage/helpers';

import styles from './planItem.module.scss';

type Props = {
  plan: Plan;
};

function PlanItem({ plan }: Props) {
  const { name, media, facilities, cancellationPenalties, points, price } = plan;
  const featureIcons = mappedIconTextListData(facilities?.[0]?.features, styles.iconSize);
  const currency = useSelector(getCurrency);

  const freeUntil = cancellationPenalties?.find(item => item.type === 'NO_PENALTY')?.deadline;
  const salesPromotions = price?.salesPromotions;
  const isCoupon = salesPromotions?.some(
    salesPromotion => salesPromotion.offerType === OFFER_TYPE.COUPON,
  );

  const hasDiscount = price.base !== price.total;

  const discountAmount = (
    <IncentiveValueTitle
      percentage={salesPromotions?.[0].incentive?.value}
      amount={salesPromotions?.[0].incentive?.value}
      incentiveMethod={salesPromotions?.[0].incentive?.method}
      incentiveCalcMethod={salesPromotions?.[0].incentive?.calculationMethod}
      currency={currency}
    />
  );

  return (
    <div className={styles.itemContainer} data-testid="guideUserPage-planItem-wrapper">
      <p className={styles.title} data-testid="guideUserPage-planItem-name">
        {name}
      </p>
      <div className={styles.priceContainer}>
        <Media media={media[0]} className={styles.image} />
        <div className={styles.price}>
          {hasDiscount && (
            <>
              {salesPromotions?.[0].representativeFeatureName && (
                <div className={styles.tag} data-testid="guideUserPage-planItem-discountTag">
                  {salesPromotions?.[0].representativeFeatureName}
                </div>
              )}

              <p
                className={cx(styles.priceTitle, styles.delLine)}
                data-testid="guideUserPage-planItem-basePrice"
              >
                <Money value={price.base} />
              </p>
            </>
          )}
          <p
            className={hasDiscount ? styles.scarlet : ''}
            data-testid="guideUserPage-planItem-totalPrice"
          >
            <Money value={price.total} />
          </p>
          <p className={styles.priceTitle}>
            <Translate id="Guide_User_Inventory.Plan_Per_Room" />
          </p>
        </div>
      </div>

      <div>
        {freeUntil && (
          <IconText
            data-testid="guideUserPage-planItem-freeUntil"
            className={styles.planItemFreeUntil}
            text={
              <div>
                <p>
                  <Translate
                    id="Cancellation_Policy_Common.Provider_Pages.Free_Cancellation"
                    exist={true}
                  />
                </p>
                <Translate
                  id="Cancellation_Policy_Common.Provider_Pages.Until"
                  data={{
                    cancellation_date: (
                      <L10nDate value={freeUntil} format="MD" isDisplayAsLocalTime />
                    ),
                    cancellation_time: null,
                  }}
                  className={styles.date}
                />
              </div>
            }
            icon={<Checkmark size={16} />}
            spaceBetween={4}
          />
        )}

        {Number(points?.amount) > 0 && (
          <span className={styles.planItemPoint} data-testid="guideUserPage-planItem-earnPoint">
            <RakutenPoints size={12} />
            <Translate
              id="Provider_Info.Room_List.Plan_Info.Points"
              data={{
                points_calculation: <L10nNumber value={points?.amount || 0} />,
                currency: `${points?.currency || ''}`,
              }}
            />
          </span>
        )}

        {isCoupon && (
          <div data-testid="guideUserPage-planItem-coupon" className={styles.couponWrapper}>
            <span className={styles.text}>
              <span className={styles.subtitle}>
                <Coupon size={16} color="appleRed" className={styles.couponIcon} />
                {salesPromotions?.[0]?.acquiredStatus === ACQUIRED_STATUS.APPLIED_AUTOMATICALLY ||
                salesPromotions?.[0]?.acquiredStatus === ACQUIRED_STATUS.ACQUIRED_BY_USER ? (
                  discountAmount
                ) : (
                  <>
                    <Translate
                      id="Provider_Info.Room_List.Plan_Info.Coupon.Unacquired"
                      data={{
                        priceafterdiscount: (
                          <span className={styles.highlight}>
                            <Money value={price.total} />
                          </span>
                        ),
                      }}
                    />
                    &nbsp;
                    <span className={styles.subtitle}>({discountAmount})</span>
                  </>
                )}
              </span>
            </span>
          </div>
        )}

        <IconTextList
          iconTextSpacing={5}
          spaceBetween={16}
          items={featureIcons}
          iconTextClassName={styles.iconSize}
          data-testid="guideUserPage-planItem-features"
        />
      </div>
    </div>
  );
}

export default PlanItem;
