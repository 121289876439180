import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { NotificationDetailsErrors, NotificationItem } from 'NotificationDetails-Types';

import { fetchNotificationDetails } from './api';

export const fetchNotificationDetailsAsync = createAsyncAction(
  'FETCH_NOTIFICATION_DETAILS_REQUEST',
  'FETCH_NOTIFICATION_DETAILS_SUCCESS',
  'FETCH_NOTIFICATION_DETAILS_FAILURE',
)<undefined, NotificationItem, NotificationDetailsErrors[]>();

export const fetchNotificationItem = (notificationId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchNotificationDetailsAsync.request());
  try {
    const response = await fetchNotificationDetails(apiClient, notificationId);
    dispatch(fetchNotificationDetailsAsync.success(response));
  } catch (error) {
    dispatch(fetchNotificationDetailsAsync.failure(error));
  }
};
