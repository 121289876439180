export const capitalize = (text: string, separator?: string): string => {
  if (!text || typeof text !== 'string') return text;

  if (!separator) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  } else {
    return text
      .split(separator)
      .map(t => capitalize(t))
      .join(separator);
  }
};
