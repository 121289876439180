import React, { ReactNode } from 'react';

import useDeviceType from '@travel/traveler-core/hooks/useDeviceType';

import ContentsCarousel from 'components/ContentsCarousel';

import { Items } from '../index';

import styles from './itemContainer.module.scss';

// please update accordingly if there is any changes on scss
const TL_IMAGE_WIDTH = 172;
const PC_IMAGE_WIDTH = 278;

type ItemContainerProps = {
  title: ReactNode;
  children: ReactNode;
  items: Items;
  currentItems: string;
};

export default function ItemContainer({
  title,
  children,
  items,
  currentItems,
}: ItemContainerProps) {
  const isPC = useDeviceType() === 'pc';
  const isSP = useDeviceType() === 'sp';

  const cardWidth = isPC ? PC_IMAGE_WIDTH : TL_IMAGE_WIDTH;

  return isSP ? (
    items === currentItems ? (
      <div className={styles.card}>
        <p className={styles.cardTitle}>{title}</p>
        {children}
      </div>
    ) : null
  ) : (
    <div className={styles.section}>
      <p className={styles.sectionTitle}>{title}</p>
      <ContentsCarousel
        fixedCardWidth={cardWidth}
        className={styles.carousel}
        navButtonClassName={styles.carouselButton}
      >
        {children}
      </ContentsCarousel>
    </div>
  );
}
