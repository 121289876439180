import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { isEmptyArray, isNotEmptyArray } from '@travel/utils';

import { Plan, ProviderPlanListErrors, RoomItem } from 'ProviderPlanList-Types';
import { RatePlanFilter, RatePlanFilterFeature } from 'ProviderRatePlan-Types';

import { acquiredCoupon } from '../providerRatePlan/actions';
import { fetchProviderPlanListAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchProviderPlanListAsync.request], () => true)
  .handleAction(
    [fetchProviderPlanListAsync.success, fetchProviderPlanListAsync.failure],
    () => false,
  );

export const items = createReducer([] as Array<Plan>)
  .handleAction(fetchProviderPlanListAsync.success, (_state, action) => action.payload.plans)
  .handleAction(fetchProviderPlanListAsync.failure, () => [])
  .handleAction(acquiredCoupon, (state, action) => {
    const newState = state.reduce((result: Array<Plan>, plan) => {
      const newPlans = plan.items.reduce((planResult: Array<RoomItem>, plan) => {
        if (
          isEmptyArray(plan.price.salesPromotions) ||
          // sales promotions are max 2 elements (MAIN / SUB)
          (plan.price.salesPromotions?.[0]?.id !== action.payload.salesPromotionId &&
            plan.price.salesPromotions?.[1]?.id !== action.payload.salesPromotionId)
        ) {
          return [...planResult, plan];
        }

        return [
          ...planResult,
          {
            ...plan,
            price: {
              ...plan.price,
              salesPromotion: { ...plan.price.salesPromotions, acquired: true },
            },
          },
        ];
      }, []);

      return [...result, { ...plan, plans: newPlans }];
    }, []);
    return newState;
  });

export const filters = createReducer([] as RatePlanFilter[]).handleAction(
  fetchProviderPlanListAsync.success,
  (_state, action) => action.payload.filters,
);

export const selectedFeatures = createReducer([] as RatePlanFilterFeature[]).handleAction(
  fetchProviderPlanListAsync.success,
  (_state, action) =>
    action.payload.filters.reduce((acc: Array<RatePlanFilterFeature>, curr: RatePlanFilter) => {
      if (isNotEmptyArray(curr.features)) {
        const toAdd = curr.features.filter(feature => feature.selected);
        return acc.concat(toAdd);
      }
      return acc;
    }, []),
);

export const errors = createReducer([] as ProviderPlanListErrors[]).handleAction(
  fetchProviderPlanListAsync.failure,
  (_state, action) => action.payload,
);

const providerPlanListReducer = combineReducers({
  isFetching,
  items,
  filters,
  selectedFeatures,
  errors,
});

export default providerPlanListReducer;
export type ProviderPlanListState = ReturnType<typeof providerPlanListReducer>;
