import dayjs from 'dayjs';

import { getL10nDate } from '@travel/i18n';

export function formatOption(checkInTime: {
  startCheckInTime: string;
  endCheckInTime: string;
}): { text: string; value: string }[] {
  // calc time range as per 30 min = 1 unit
  const startHour = parseInt(checkInTime?.startCheckInTime?.split(':')[0]);
  const startMin = parseInt(checkInTime?.startCheckInTime?.split(':')[1]);
  const endHour = parseInt(checkInTime?.endCheckInTime?.split(':')[0]);
  const endMin = parseInt(checkInTime?.endCheckInTime?.split(':')[1]);

  const diff = endHour - startHour;
  const diffMin = () => {
    if (endMin - startMin > 0) {
      return 1;
    } else if (endMin - startMin < 0) {
      return -1;
    } else {
      return 0;
    }
  };
  const timeUnitLength = diff * 2 + diffMin();
  const timeRange = [];

  for (let i = 0; i <= timeUnitLength; i++) {
    if (i % 2 === 0) {
      const calcStartHour = startHour + i / 2;
      const calcStartHourFormat = getStartHourFormat(calcStartHour);

      if (startMin) {
        timeRange.push({
          value: `${calcStartHourFormat}:${startMin}`,
          text: `${calcStartHourFormat}:${startMin}`,
        });
      } else {
        timeRange.push({
          value: `${calcStartHourFormat}:00`,
          text: `${calcStartHourFormat}:00`,
        });
      }
    } else {
      const ceilStartHour = startHour + Math.ceil(i / 2);
      const floorStartHour = startHour + Math.floor(i / 2);

      const ceilStartHourFormat = getStartHourFormat(ceilStartHour);
      const floorStartHourFormat = getStartHourFormat(floorStartHour);

      if (startMin) {
        timeRange.push({
          value: `${ceilStartHourFormat}:00`,
          text: `${ceilStartHourFormat}:00`,
        });
      } else {
        timeRange.push({
          value: `${floorStartHourFormat}:30`,
          text: `${floorStartHourFormat}:30`,
        });
      }
    }
  }

  return timeRange;
}

// H:MM is not support on safari for L10nDate Library, so we need force format HH:MM.
const getStartHourFormat = (startHour: number) =>
  startHour.toString().length === 1 ? `0${startHour}` : startHour;

export function formatTime(date: string, time: string, language: string, market: string) {
  // temporary fix until moment.js update to day.js
  const [h, m] = time.split(':').map(val => Number(val));
  const newDate = dayjs(date)
    .hour(h)
    .minute(m);

  return getL10nDate(language, newDate.format('YYYY-MM-DD HH:mm'), 'LT', false, market);
}

const NUM_OF_MONTHS = 23;

export function generateMonthArray(startDate: string = '', numOfMonths: number = NUM_OF_MONTHS) {
  const startMonth = startDate ? dayjs(startDate) : dayjs();
  const endMonth = startDate
    ? dayjs(startDate).add(numOfMonths, 'month')
    : dayjs().add(numOfMonths, 'month');

  const monthArray = [] as string[];

  let currentMonth = startMonth.clone();

  while (currentMonth.isBefore(endMonth) || currentMonth.isSame(endMonth, 'month')) {
    monthArray.push(currentMonth.format('YYYY-MM'));
    currentMonth = currentMonth.add(1, 'month');
  }

  // get months array as ['2023-11', '2023-12', ...]
  return monthArray;
}
