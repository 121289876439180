import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { NotificationDetailsErrors, NotificationItem } from 'NotificationDetails-Types';

import { fetchNotificationDetailsAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchNotificationDetailsAsync.request], (state, action) => true)
  .handleAction(
    [fetchNotificationDetailsAsync.success, fetchNotificationDetailsAsync.failure],
    (state, action) => false,
  );

export const item = createReducer({} as NotificationItem).handleAction(
  fetchNotificationDetailsAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const errors = createReducer([] as NotificationDetailsErrors[]).handleAction(
  fetchNotificationDetailsAsync.failure,
  (state, action) => action.payload,
);

const notificationDetailsReducer = combineReducers({
  isFetching,
  item,
  errors,
});

export default notificationDetailsReducer;
export type NotificationDetailsState = ReturnType<typeof notificationDetailsReducer>;
