import { fetchAuthCode } from 'store/authCode/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type Props = {
  query: { token?: string };
};

export default ({ store: { dispatch, getState } }: universalRouterProps, props: Props) => {
  const state = getState();
  const language = state._i18n.language;
  const { marketCode } = state._i18n.market;

  if (props?.query?.token) {
    return Promise.all([dispatch(fetchAuthCode(props.query.token, marketCode, language, true))]);
  }
};
