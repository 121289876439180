import { AppThunk, createAsyncAction } from 'typesafe-actions';

import {
  DEVICE_ID,
  IS_GUEST_BOOKING_USER,
  TOKEN_KEY,
} from '@travel/traveler-core/constants/cookies';
import { getCookie } from '@travel/utils';

import { clearMember, refreshAuthCode } from 'store/authCode/actions';
import { fetchCard } from 'store/card/actions';

import { MemberError, MemberItem } from 'Member-Types';

import { fetch, nonMemberEmailVerification, nonMemberVerification } from './apis';

export const fetchMemberAsync = createAsyncAction(
  'FETCH_MEMBER_REQUEST',
  'FETCH_MEMBER_SUCCESS',
  'FETCH_MEMBER_FAILURE',
)<undefined, MemberItem, MemberError[]>();

export const fetchMember = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  const state = getState();
  const tokenKey = TOKEN_KEY;
  const isSSR = typeof window === 'undefined';
  const isGuestBookingUser = getCookie(IS_GUEST_BOOKING_USER);

  const shouldNotFetch = isSSR
    ? !state._httpRequest?.normalizedCookies?.[tokenKey]
    : !state.authCode.item.token;

  try {
    if (state.authCode.isGuestBookingUser || isGuestBookingUser || shouldNotFetch) {
      return;
    }

    dispatch(fetchMemberAsync.request());

    const response = await fetch(apiClient);
    dispatch(fetchMemberAsync.success(response as MemberItem));
    dispatch(fetchCard());
  } catch (error) {
    // will refresh if user was active on browser (same browser session)
    if (error.status === 401 && state.authCode?.item?.token) {
      const isSuccessfullyRefresh = await dispatch(refreshAuthCode());
      if (isSuccessfullyRefresh) {
        await dispatch(fetchMember());
      }
    } else {
      dispatch(clearMember());
      dispatch(fetchMemberAsync.failure([error as MemberError]));
    }
  }
};

export const nonMemberEmailVer = (url: string, email?: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const { normalizedCookies } = getState()._httpRequest;
  const devId = normalizedCookies[DEVICE_ID];
  const newUrl = `${url}/${devId}`;

  try {
    const response = await nonMemberEmailVerification(apiClient, newUrl, email);
    const redirectUrl = await response.text();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  } catch {
    console.error('nonMemberEmailVer failed');
  }
};

export const getNonMemberVer = (deviceId: string, token: string): AppThunk => async (
  _dispatch,
  _getState,
  { apiClient },
) => {
  return nonMemberVerification(apiClient, deviceId, token);
};
