import { useGetTranslation } from '../hooks';

/**
 * Get proper payment type label for translation
 * @param currentValue - payment value
 * @param localPaymentValue - An enum value from API which is defined for local payment
 */
export function getPaymentTypeLabel(currentValue: string, localPaymentValue: string = 'LOCAL') {
  const getTranslation = useGetTranslation();

  return getTranslation({
    id: 'Coupon_Details.Payment.Displayed_Information',
    condition: () =>
      `status == ${currentValue === localPaymentValue ? 'pay_at_local' : 'pay_through_rakuten'}`,
  });
}
