import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { MediaResponse, NewMedia } from 'Media-Types';

import { removeMedia as requestRemoveMedia, uploadNewMedia } from './apis';

export const uploadMediaAsync = createAsyncAction(
  'UPLOAD_MEDIA_REQUEST',
  'UPLOAD_MEDIA_SUCCESS',
  'UPLOAD_MEDIA_FAILURE',
)<undefined, NewMedia, MediaResponse[]>();

export const removeMediaAsync = createAsyncAction(
  'REMOVE_MEDIA_REQUEST',
  'REMOVE_MEDIA_SUCCESS',
  'REMOVE_MEDIA_FAILURE',
)<undefined, MediaResponse, MediaResponse[]>();

export const uploadMedia = (file: File): AppThunk<Promise<NewMedia>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(uploadMediaAsync.request());

  try {
    const response = await uploadNewMedia(apiClient, file);
    dispatch(uploadMediaAsync.success(response));
    return response;
  } catch (error) {
    dispatch(uploadMediaAsync.failure([error] as MediaResponse[]));
    return error;
  }
};

export const removeMedia = (mediaId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(removeMediaAsync.request());

  try {
    const response = await requestRemoveMedia(apiClient, mediaId);
    dispatch(removeMediaAsync.success(response));
  } catch (error) {
    dispatch(removeMediaAsync.failure([error]));
  }
};
