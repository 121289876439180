import { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';

import { SalesPromotionFormQuery } from 'components/SalesPromotionInput';

import { fetchBanner } from 'store/banner/actions';
import { fetchProviderInformation, fetchSEOdata } from 'store/providerInformation/actions';
import { fetchProviderRatePlan } from 'store/providerRatePlan/actions';
import { fetchReviewList } from 'store/providerReview/actions';
import { fetchCouponDetails } from 'store/salesPromotion/actions';
import { getCouponDetails } from 'store/salesPromotion/selectors';
import { getSsrDeviceType } from 'store/userAgent/selectors';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants/defaultValue';
import { SORT_OPTIONS } from 'constants/provider';
import { universalRouterProps } from 'core/universalRouter/types';
import { RoomFormRequestBodyProviderList } from 'ProviderList-Types';
import { CouponDetails } from 'SalesPromotion-Types';

import {
  REVIEW_DEFAULT_LIMIT_FOR_MOBILE,
  REVIEW_DEFAULT_LIMIT_FOR_WEB,
} from '../../constants/review';
import { RoomFormQuery } from '../ProviderListPage/components/SearchForm';
import { getDefaultRoomFormQueryParams } from '../ProviderListPage/resolver';

export type SearchRequestBody = {
  id?: string;
  features?: Array<string>;
  sortOrder?: string;
  minPrice?: number | string | null;
  maxPrice?: number | string | null;
  coupon?: {
    id: string;
    ratePlanIds?: Array<string>;
  };
} & RoomFormRequestBodyProviderList;

type PlanFilterQuery = {
  minPrice?: number | string | null;
  maxPrice?: number | string | null;
  freeCancellation?: string;
  features?: Array<string> | string;
  sortKey?: string;
};

export type CategoryFilterParam = { type: string; values: string[] };
export type URLParams = { pageIdentifier: string; locationPath: string };
export type QueryParams = RoomFormQuery & PlanFilterQuery & SalesPromotionFormQuery;

type SearchProps = {
  params: URLParams;
  query?: QueryParams;
};

export function getRatePlanRequestBody(id: string, query: QueryParams, coupon?: CouponDetails) {
  const sortKey = query?.sortKey || 'price_low_to_high';
  const isCouponFlow = Boolean(query.salesPromotionId && coupon);

  // features
  const queryFeatures: Array<string> = Array.isArray(query.features)
    ? query.features
    : query.features?.split(',') || EMPTY_ARRAY;

  const { features, featureManagementNames } = queryFeatures.reduce(
    (result: { features: Array<string>; featureManagementNames: Array<string> }, feature) => {
      if (!feature) {
        return result;
      }
      if (feature.match(/^\d+$/)) {
        return { ...result, features: [...result.features, feature] };
      }
      return { ...result, featureManagementNames: [...result.featureManagementNames, feature] };
    },
    { features: EMPTY_ARRAY, featureManagementNames: EMPTY_ARRAY },
  );

  return {
    ...getDefaultRoomFormQueryParams(query),
    id,
    features,
    featureManagementNames,
    minPrice: query.minPrice,
    maxPrice: query.maxPrice,
    freeCancellation: query.freeCancellation === 'true' ? true : false,
    sortOrder: SORT_OPTIONS[sortKey]?.queries.order || SORT_OPTIONS.recommended.queries.order,
    ...(isCouponFlow && {
      coupon: {
        id: query.salesPromotionId || '',
        ratePlanIds: coupon?.applicabilities?.[0]?.ratePlanIds,
      },
    }),
  };
}

export default async ({ store }: universalRouterProps, search: SearchProps) => {
  // currently we use top banner on provider info as well
  const pageName = 'top';
  const { dispatch } = store;
  const query = (search && search.query) || (EMPTY_OBJECT as RoomFormQuery);
  const couponId = search.query?.salesPromotionId;
  const isMobile = getSsrDeviceType(store.getState()) === MOBILE_SCREEN;

  const REVIEW_DEFAULT_LIMIT = isMobile
    ? REVIEW_DEFAULT_LIMIT_FOR_MOBILE
    : REVIEW_DEFAULT_LIMIT_FOR_WEB;

  let couponDetail;

  if (couponId) {
    await dispatch(fetchCouponDetails(couponId));
    couponDetail = getCouponDetails(couponId)(store.getState());
  }
  const requestBody = getRatePlanRequestBody(search.params.pageIdentifier, query, couponDetail);

  return Promise.all([
    dispatch(fetchBanner(pageName)),
    dispatch(fetchProviderInformation(search.params.pageIdentifier)),
    dispatch(fetchProviderRatePlan(requestBody)),
    dispatch(
      fetchReviewList(search.params.pageIdentifier, {
        offset: 0,
        limit: REVIEW_DEFAULT_LIMIT,
      }),
    ),
  ]).then(() => dispatch(fetchSEOdata(true)));
};
