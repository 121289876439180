import React from 'react';
import { Skeleton } from '@travel/ui';
import { cx } from '@travel/utils';

import styles from './withSkeletonLoading.module.scss';

export type Props = {
  className?: string;
  /** Flag to define whether the skeleton paragraph will be displayed or not */
  isLoading?: boolean;
  /** Number to define the row for paragraph */
  row?: number;
  /** It define paragraph length */
  length?: 'short' | 'long';
  /** Number to define the count of skeleton component */
  count?: number;
  /** Node to be rendered when isLoading is false */
  children?: React.ReactNode;
  /** Skeleton type */
  customStyle?: 'text' | 'textWithImageRow' | 'textWithImageColumn' | 'image';
  /** Skeleton item className */
  skeletonItemClassName?: string;
  /** Skeleton item's image className */
  skeletonImageClassName?: string;
  /** Direction of flex wrapper */
  direction?: 'column' | 'row';
};

// to override min-height/min-width default <Skeleton /> inline style
const OVERRIDE_PROP = { width: 'unset', height: 'unset' };

function WithSkeletonLoading(props: Props) {
  if (!props.isLoading) return <>{props.children}</>;

  return (
    <div
      className={cx(props.className, styles.wrapper, styles[`direction--${props.direction}`])}
      data-testid="withSkeletonLoading-wrapper"
    >
      {Array(props.count)
        .fill(0)
        .map((_item, index) => (
          <div
            key={index}
            className={cx(
              props.skeletonItemClassName,
              styles.item,
              styles[`layout-style--${props.customStyle}`],
            )}
          >
            {props.customStyle !== 'text' && (
              <Skeleton
                data-testid="skeleton-wrapper"
                className={cx(
                  styles.image,
                  props.skeletonImageClassName,
                  styles[`image-style--${props.customStyle}`],
                )}
                type="image"
                {...OVERRIDE_PROP}
              />
            )}
            {props.customStyle !== 'image' && (
              <Skeleton
                data-testid="skeleton-wrapper"
                key={index}
                type="paragraph"
                row={props.row}
                length={props.length}
                {...OVERRIDE_PROP}
              />
            )}
          </div>
        ))}
    </div>
  );
}

WithSkeletonLoading.defaultProps = {
  length: 'short',
  count: 1,
  spaceBetween: 0,
  children: null,
  customStyle: 'text',
  direction: 'column',
};

export default WithSkeletonLoading;
