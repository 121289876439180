import { ratPropsToParams } from '../../constants/ratAnalytics';
import { PropMap, RatObject } from '../../types/analytics';
import { AnalyticParams as RatProps } from '.';

export const getRatParamsMap = (ratParamValues: RatObject) => {
  let ratParamsMap = JSON.parse(JSON.stringify(ratPropsToParams));
  for (let [key, paramObj] of Object.entries(ratParamValues)) {
    for (let [id, value] of Object.entries(paramObj)) {
      ratParamsMap[key].unshift({
        prop: '',
        id,
        value,
      });
    }
  }

  return ratParamsMap;
};

export const generateParamString = (paramObj: PropMap[], props: RatProps) => {
  return paramObj
    .map((param: PropMap) => {
      const paramValue = param.value ? param.value : props[param.prop];
      return props[param.prop] || param.value
        ? `'${param.id}':` + (typeof paramValue === 'string' ? `'${paramValue}'` : paramValue)
        : null;
    })
    .filter(params => params)
    .join(',');
};
