import React, { useCallback } from 'react';

type KeyHandlers = {
  Submit?: (event: React.KeyboardEvent) => void;
  ArrowUp?: (event: React.KeyboardEvent) => void;
  ArrowRight?: (event: React.KeyboardEvent) => void;
  ArrowDown?: (event: React.KeyboardEvent) => void;
  ArrowLeft?: (event: React.KeyboardEvent) => void;
  Escape?: (event: React.KeyboardEvent) => void;
};

const useKeyboard = (keyHandlers: KeyHandlers) => {
  const keyboardHandler = useCallback(
    (event: React.KeyboardEvent) => {
      for (const [keyboardKey, keyboardHandler] of Object.entries(keyHandlers)) {
        const key = keyboardKey === 'Submit' ? ['Enter', ' '] : [keyboardKey];
        if (key.some(k => k === event.key)) {
          event.preventDefault();
          keyboardHandler?.(event);
        }
      }
    },
    [keyHandlers],
  );
  return keyboardHandler;
};

export default useKeyboard;
