import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { PageLoading } from 'PageLoading-Types';

import { PageLoadingState } from './reducer';

export const getPageLoadingState = (state: RootState) => state.pageLoading;

export const getPageLoading = createSelector(
  getPageLoadingState,
  (state: PageLoadingState) => state.pageLoading,
);

export const getIsLoading = createSelector(
  getPageLoading,
  (state: PageLoading) => state.isPageLoading,
);

export const getLatestPageName = createSelector(
  getPageLoading,
  (state: PageLoading) => state.pageName,
);
