import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  LegacyReservationDetailsErrors,
  LegacyReservationDetailsItems,
} from 'LegacyReservationDetails-Types';

import { fetchLegacyReservationDetailsAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchLegacyReservationDetailsAsync.request], (state, action) => true)
  .handleAction(
    [fetchLegacyReservationDetailsAsync.success, fetchLegacyReservationDetailsAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as LegacyReservationDetailsItems).handleAction(
  fetchLegacyReservationDetailsAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const errors = createReducer({} as LegacyReservationDetailsErrors)
  .handleAction(fetchLegacyReservationDetailsAsync.failure, (state, action) => action.payload)
  .handleAction(fetchLegacyReservationDetailsAsync.success, (state, action) => {
    return { code: '', message: '' };
  });

const legacyReservationDetailsReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default legacyReservationDetailsReducer;
export type LegacyReservationDetailsState = ReturnType<typeof legacyReservationDetailsReducer>;
