import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePortal from 'react-useportal';

import { getCurrency, getLanguage, getMarket } from '@travel/i18n';
import { getRatParamsMap } from '@travel/traveler-core/components/DataLayer/ratParamsMap';
import useDeviceType from '@travel/traveler-core/hooks/useDeviceType';
import { getRanData } from '@travel/traveler-core/store/ranStore/selectors';
import { PropMap, RatObject } from '@travel/traveler-core/types/analytics';
import { ranDataToSendForConversation } from '@travel/traveler-core/utils/helper';
import { getCookie } from '@travel/utils';

import { setIsSSR, setPreviousPageName } from 'store/__router/actions';
import { getPreviousPageName } from 'store/__router/selectors';
import { getMember } from 'store/member/selectors';

import { useTealium } from 'hooks/useTealium';

import { getPreviousPurchase } from '../../utils/analytics';

const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Generic and custom parameter PRD is updating in progress
 * Generic: https://confluence.rakuten-it.com/confluence/display/EVEREST/%28V1.1%29+Everest+website+RAT+generic+parameters
 * Custom: https://confluence.rakuten-it.com/confluence/display/EVEREST/%28V1.1%29+Everest+website+RAT+custom+parameters
 * Site Section: https://confluence.rakuten-it.com/confluence/display/EVEREST/Everest+RAT+page+names+and+site+sections
 */
const useRat = (updateData?: string) => {
  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        let event;
        if (typeof window.Event === 'function') {
          event = new Event('SINGLE_PAGE_APPLICATION_LOAD');
          document.dispatchEvent(event);
        } else {
          try {
            event = document.createEvent('Event');
            event.initEvent('SINGLE_PAGE_APPLICATION_LOAD', true, true);
            document.dispatchEvent(event);
          } catch (e) {
            console.error(e);
          }
        }
      }
    }, 100);

    // I only want to dispatch when one of these props changes else we will get performance issues
  }, [updateData]);
};

export type DataLayerProps = {
  siteSection: string;
  pageName: string;
  updateData?: string /* pass the changed data if params need to be updated on data change */;
} & { [key: string]: string | number | undefined };

const DataLayer = (props: DataLayerProps) => {
  const dispatch = useDispatch();
  //Constant/Formatted param values
  const { isMember: isLogin, item } = useSelector(getMember);

  let hasPastPurchaseOnDevice: boolean = false;
  const pastPurchaseObj = getPreviousPurchase();

  const trackId: string | undefined = item?.trackId;
  const deviceId = getCookie('trv-device-id');
  const pastPurchaseKey = trackId || deviceId;
  if (pastPurchaseKey) {
    hasPastPurchaseOnDevice = Boolean(pastPurchaseObj[pastPurchaseKey]);
  }

  const ranData = useSelector(getRanData);
  const ranDataToSend = ranDataToSendForConversation(ranData);

  const marketCode = useSelector(getMarket)?.marketCode;
  const previousPageName = useSelector(getPreviousPageName);

  const ratPageLayout = useDeviceType();
  const ratLanguage = useSelector(getLanguage);
  const ratCurrencyCode = useSelector(getCurrency);
  const ratCountryCode = marketCode;

  const login_status = isLogin ? 'on' : 'off';
  const market_code = marketCode;
  const service_start_date = props.checkIn ? dayjs(props.checkIn).format(DATE_FORMAT) : '';
  const service_end_date = props.checkOut ? dayjs(props.checkOut).format(DATE_FORMAT) : '';
  const past_purchase_on_device = hasPastPurchaseOnDevice ? 'past purchase' : 'no past purchase';
  const previous_page_name = previousPageName !== props.pageName && previousPageName;
  const device_id = deviceId;

  const login = isLogin && 1;
  const landing = !previousPageName && 1;
  const ranSiteID = ranDataToSend?.siteId;

  const ratObject: RatObject = {
    generic: {
      ratPageLayout,
      ratLanguage,
      ratCurrencyCode,
      ratCountryCode,
    },
    custom: {
      login_status,
      market_code,
      service_start_date,
      service_end_date,
      past_purchase_on_device,
      ...(previous_page_name
        ? {
            previous_page_name,
          }
        : {}),
      ...(device_id ? { device_id } : {}),
      ...(ranSiteID ? { affiliate_id: ranSiteID } : {}),
    },
    conversion: {
      ...(login ? { login } : {}),
      ...(landing ? { landing } : {}),
    },
  };

  const ratParamsMap = getRatParamsMap(ratObject);

  const { Portal } = usePortal();

  // action
  useRat(props.updateData || props.pageName);

  useEffect(() => {
    props.pageName && dispatch(setPreviousPageName(props.pageName));
    dispatch(setIsSSR(false));
  }, [dispatch, props.pageName]);

  const generateParamString = (paramObj: PropMap[]) => {
    return paramObj
      .map((param: PropMap) => {
        const paramValue = param.value ? param.value : props[param.prop];
        return props[param.prop] || param.value
          ? `'${param.id}':` + (typeof paramValue === 'string' ? `'${paramValue}'` : paramValue)
          : null;
      })
      .filter(params => params)
      .join(',');
  };

  // integrate Tealium that will be used as a data layer replacing RAT
  useTealium(props, ratObject, props.updateData || props.pageName);

  return (
    <Portal>
      {/* -- Generic Parameters -- */}
      {ratParamsMap.generic.map((genericParam: PropMap) =>
        props[genericParam.prop] || genericParam.value ? (
          <input
            key={genericParam.id}
            type="hidden"
            name="rat"
            id={genericParam.id}
            value={genericParam.value ? genericParam.value : props[genericParam.prop]}
          />
        ) : null,
      )}

      {/*-- Custom Parameters -- */}
      <input
        type="hidden"
        name="rat"
        id="ratCustomParameters"
        value={`{${generateParamString(ratParamsMap.custom)}}`}
      />

      {/*-- Conversion Parameters -- */}
      <input
        type="hidden"
        name="rat"
        id="ratCvEvent"
        value={`{${generateParamString(ratParamsMap.conversion)}}`}
      />
    </Portal>
  );
};

export default DataLayer;
