import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { PromotionBannersErrors, PromotionBannersItem } from 'PromotionBanners-Types';

import { fetchPromotionBannersAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchPromotionBannersAsync.request], () => true)
  .handleAction(
    [fetchPromotionBannersAsync.success, fetchPromotionBannersAsync.failure],
    () => false,
  );

export const items = createReducer([] as PromotionBannersItem[])
  .handleAction(fetchPromotionBannersAsync.success, (_state, action) => action.payload)
  .handleAction(fetchPromotionBannersAsync.failure, () => [] as PromotionBannersItem[]);

export const errors = createReducer([] as PromotionBannersErrors[]).handleAction(
  fetchPromotionBannersAsync.failure,
  (_state, action) => action.payload,
);

const promotionBannersReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default promotionBannersReducer;
export type PromotionBannersState = ReturnType<typeof promotionBannersReducer>;
