import { ApiClient } from '@travel/api-client';

import { AcquiredCouponsItems } from 'AcquiredCoupons-Types';

type LinkCoupon = {
  salesPromotionId: string;
};

const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 10;

export const fetchAcquiredCouponsItems = (
  http: ApiClient,
  offset: number | undefined,
  limit: number | undefined,
): Promise<AcquiredCouponsItems> => {
  return http.get('/sales-promotion-core/mycoupon/acquired', {
    withAuthCode: true,
    values: {
      offset: offset || DEFAULT_OFFSET,
      limit: limit || DEFAULT_LIMIT,
    },
  });
};

export const linkNewCouponItem = (
  http: ApiClient,
  couponRef: string,
  generated: boolean,
): Promise<LinkCoupon> => {
  return http.post('/sales-promotion-core/mycoupon/link', {
    values: { couponRef, generated },
    ignoreCommonErrorHandler: true,
  });
};

export const deleteCoupon = (http: ApiClient, couponId: string) => {
  return http.delete(`/sales-promotion-core/mycoupon/${couponId}/delete`, {
    withAuthCode: true,
  });
};
