import React, { ReactNode } from 'react';

import { Translate } from 'core/translate';

export type STATUS_TYPE = 'BOOKING' | 'MEMBER' | 'GENERAL';

export const STATUS_KEYS: STATUS_TYPE[] = ['BOOKING', 'MEMBER', 'GENERAL'];

export const STATUS_MAP: Record<STATUS_TYPE, ReactNode> = {
  BOOKING: <Translate id="Notification_List.Section_Title.Booking" />,
  MEMBER: <Translate id="Notification_List.Section_Title.Offer" />,
  GENERAL: <Translate id="Notification_List.Section_Title.General" />,
};
