import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { getLanguage, getMarket } from '@travel/i18n';
import { isNotEmptyArray } from '@travel/utils';

import { getHost } from 'store/__router/selectors';
import { getSEOAlternatePaths } from 'store/markets/selectors';

import { getURLPagePathName } from 'core/universalRouter/paths';

type Props = {
  /** String to specify the title of web page */
  title?: string;
  /** String to summarize the web page, will be displayed below the title tag in search result */
  description?: string;
  /** Flag to define whether the page will be indexed by default or not */
  isIndexedByDefault?: boolean;
  /** Array of string to specify what the topic of this page is */
  keywords?: Array<string>;
  /** Image url to show when the page is shared via OGP   */
  imageUrl?: string;
  /** URL string to specify which url we expected to be crawled and indexed */
  /** For more information: https://support.google.com/webmasters/answer/139066?hl=en  */
  canonicalPath?: string;
};

function SEO(props: Props) {
  const host = useSelector(getHost);
  const { marketCode } = useSelector(getMarket);
  const language = useSelector(getLanguage);
  const alternatePaths = useSelector(getSEOAlternatePaths);

  const { title, description, keywords, imageUrl, canonicalPath } = props;

  // canonical url
  const origin = `https://${host}`;
  const marketLangPath = `${marketCode}/${language}`;
  let canonicalUrl = canonicalPath
    ? `${origin}${getURLPagePathName(marketCode, language, canonicalPath)}`
    : '';

  // SEO target to be indexed
  const isSEOTarget = Boolean(alternatePaths[marketLangPath]);
  const isIndexed = isSEOTarget && props.isIndexedByDefault;

  return (
    <Helmet>
      <meta http-equiv="content-language" content={language} />
      <title>{title || 'Rakuten travel'}</title>
      {description && <meta name="description" content={description}></meta>}
      {isNotEmptyArray(keywords) && <meta name="keywords" content={keywords.join(',')} />}

      {/* open graph */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      {/* indexed and alternative urls */}
      {!isIndexed && <meta name="robots" content="noindex" />}

      {/* <React.fragment> can not be used here */}
      {isIndexed && canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {isIndexed &&
        canonicalPath &&
        Object.values(alternatePaths).map(alt => (
          <link
            key={alt.marketCode + alt.langCode}
            rel="alternate"
            hrefLang={alt.hreflang}
            href={`${origin}${getURLPagePathName(alt.marketCode, alt.langCode, canonicalPath)}`}
          />
        ))}
    </Helmet>
  );
}

export default SEO;
