import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { Notifications, NotificationsErrors, NotificationStatus } from 'Notifications-Types';

import { STATUS_KEYS, STATUS_TYPE } from '../../pages/NotificationsPage/constants';
import {
  fetchMoreNotificationsAsync,
  fetchNotificationsAsync,
  fetchUnreadNotificationsAsync,
  saveNotificationReqType,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchNotificationsAsync.request, fetchUnreadNotificationsAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchNotificationsAsync.success,
      fetchNotificationsAsync.failure,
      fetchUnreadNotificationsAsync.success,
      fetchUnreadNotificationsAsync.failure,
    ],
    (state, action) => false,
  );

export const isFetchingMore = createReducer(false as boolean)
  .handleAction([fetchMoreNotificationsAsync.request], (state, action) => true)
  .handleAction(
    [fetchMoreNotificationsAsync.success, fetchMoreNotificationsAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as Notifications)
  .handleAction(fetchNotificationsAsync.success, (state, action) => {
    return action.payload;
  })
  .handleAction(fetchMoreNotificationsAsync.success, (state, action) => {
    return {
      offset: action.payload.offset,
      limit: action.payload.limit,
      total: action.payload.total,
      unread: action.payload.unread,
      notifications: state.notifications.concat(action.payload.reservations),
    };
  });

export const status = createReducer([] as NotificationStatus[]).handleAction(
  fetchUnreadNotificationsAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const errors = createReducer([] as NotificationsErrors[]).handleAction(
  fetchNotificationsAsync.failure,
  (state, action) => action.payload,
);

export const reqType = createReducer(STATUS_KEYS[0] as STATUS_TYPE).handleAction(
  saveNotificationReqType,
  (state, action) => {
    return action.payload;
  },
);

const notificationReducer = combineReducers({
  isFetching,
  isFetchingMore,
  items,
  status,
  errors,
  reqType,
});

export default notificationReducer;
export type NotificationState = ReturnType<typeof notificationReducer>;
