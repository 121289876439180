import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { AcquiredCouponsErrors, AcquiredCouponsItems } from 'AcquiredCoupons-Types';

import {
  fetchAcquiredCouponsAsync,
  fetchMoreAcquiredCouponsAsync,
  removeExpiredCoupon,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchAcquiredCouponsAsync.request], () => true)
  .handleAction(
    [fetchAcquiredCouponsAsync.success, fetchAcquiredCouponsAsync.failure],
    () => false,
  );

export const isFetchingMore = createReducer(false as boolean)
  .handleAction([fetchMoreAcquiredCouponsAsync.request], () => true)
  .handleAction(
    [fetchMoreAcquiredCouponsAsync.success, fetchMoreAcquiredCouponsAsync.failure],
    () => false,
  );

export const items = createReducer({} as AcquiredCouponsItems)
  .handleAction(fetchAcquiredCouponsAsync.success, (_state, action) => action.payload)
  .handleAction(fetchMoreAcquiredCouponsAsync.success, (state, action) => ({
    ...state,
    ...action.payload,
    acquiredCoupons: [...state.acquiredCoupons, ...action.payload.acquiredCoupons],
    expiredCoupons: [...state.expiredCoupons, ...action.payload.expiredCoupons],
  }))
  .handleAction(removeExpiredCoupon, (state, action) => {
    return {
      ...state,
      ...action.payload,
      total: state.total - 1,
      expiredCoupons: state.expiredCoupons.filter(
        expiredCoupon => expiredCoupon.couponId !== action.payload,
      ),
    };
  });

export const errors = createReducer([] as AcquiredCouponsErrors[]).handleAction(
  fetchAcquiredCouponsAsync.failure,
  (_state, action) => action.payload,
);

const acquiredCouponsReducer = combineReducers({
  isFetching,
  isFetchingMore,
  items,
  errors,
});

export default acquiredCouponsReducer;
export type AcquiredCouponsState = ReturnType<typeof acquiredCouponsReducer>;
