import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { PromotionBannersErrors, PromotionBannersItem } from 'PromotionBanners-Types';

import { fetchAll } from './apis';

export const fetchPromotionBannersAsync = createAsyncAction(
  'FETCH_PROMOTION_BANNERS_REQUEST',
  'FETCH_PROMOTION_BANNERS_SUCCESS',
  'FETCH_PROMOTION_BANNERS_FAILURE',
)<undefined, PromotionBannersItem[], PromotionBannersErrors[]>();

export const fetchPromotionBanners = (page: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const { _i18n } = getState();
    const response = await fetchAll(apiClient, page, _i18n.market?.marketCode, _i18n.language);
    dispatch(fetchPromotionBannersAsync.success(response));
  } catch (error) {
    dispatch(fetchPromotionBannersAsync.failure([error]));
  }
};
