import { fetchNotificationItem } from 'store/notificationDetails/actions';
import { setDefaultSEOData } from 'store/seo/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type SearchProps = {
  params: { notificationId: string };
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  return Promise.all([
    dispatch(fetchNotificationItem(search.params.notificationId)),
    dispatch(setDefaultSEOData()),
  ]);
};
