import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  PostEsimCampaignFailResponse,
  PostEsimCampaignSuccessResponse,
} from 'PostEsimCampaigneReview-Types';

import { fetchPostEsimCampaignAsync } from './actions';

export const response = createReducer({} as PostEsimCampaignSuccessResponse)
  .handleAction(fetchPostEsimCampaignAsync.success, (_state, action) => action.payload)
  .handleAction(
    fetchPostEsimCampaignAsync.failure,
    (_state, action) => ({} as PostEsimCampaignSuccessResponse),
  );

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchPostEsimCampaignAsync.request], () => true)
  .handleAction(
    [fetchPostEsimCampaignAsync.success, fetchPostEsimCampaignAsync.failure],
    () => false,
  );

export const errors = createReducer({} as PostEsimCampaignFailResponse)
  .handleAction(fetchPostEsimCampaignAsync.failure, (_state, action) => action.payload)
  .handleAction(
    fetchPostEsimCampaignAsync.success,
    (_state, action) => ({} as PostEsimCampaignFailResponse),
  );

const postEsimCampaignReducer = combineReducers({
  response,
  isFetching,
  errors,
});

export default postEsimCampaignReducer;
export type PostEsimCampaignState = ReturnType<typeof postEsimCampaignReducer>;
