import { getCancelStep1 } from 'store/cancelStep/actions';
import { setDefaultSEOData } from 'store/seo/actions';

import { universalRouterProps } from 'core/universalRouter/types';

export type CancelStep1Query = {
  id: string;
};

type SearchProps = {
  params: { reservationId: string };
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  return Promise.all([
    dispatch(
      getCancelStep1({
        reservationId: search.params.reservationId,
      }),
    ),
    dispatch(setDefaultSEOData()),
  ]);
};
