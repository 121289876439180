import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ModificationItemError, ModificationStep2, ModificationStep3 } from 'Modification-Types';

import { postModificationStep2Async, postModificationStep3Async } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([postModificationStep2Async.request], (state, action) => true)
  .handleAction(
    [postModificationStep2Async.success, postModificationStep2Async.failure],
    () => false,
  );

export const errors = createReducer({} as ModificationItemError)
  .handleAction(
    [postModificationStep3Async.failure, postModificationStep2Async.failure],
    (state, action) => action.payload,
  )
  .handleAction([postModificationStep3Async.request, postModificationStep2Async.request], () => {
    return {};
  });

export const step3 = createReducer({} as ModificationStep3).handleAction(
  postModificationStep3Async.success,
  (_state, action) => action.payload,
);

export const step2 = createReducer({} as ModificationStep2).handleAction(
  postModificationStep2Async.success,
  (_state, action) => action.payload,
);

const modificationItemReducer = combineReducers({
  isFetching,
  step2,
  step3,
  errors,
});

export default modificationItemReducer;
export type ModificationItemState = ReturnType<typeof modificationItemReducer>;
