import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { CouponData, ProviderRatePlan, ProviderRatePlanErrors } from 'ProviderRatePlan-Types';

import { SearchRequestBody } from '../../pages/ProviderInformationPage/resolver';
import { fetchAll } from './apis';

export const fetchProviderRatePlanAsync = createAsyncAction(
  'FETCH_RATE_PLAN_LIST_REQUEST',
  'FETCH_RATE_PLAN_LIST_SUCCESS',
  'FETCH_RATE_PLAN_LIST_FAILURE',
)<undefined, ProviderRatePlan, ProviderRatePlanErrors[]>();

export const fetchProviderRatePlan = (req: SearchRequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchProviderRatePlanAsync.request());
    const response = await fetchAll(apiClient, req);
    dispatch(fetchProviderRatePlanAsync.success(response));
  } catch (error) {
    dispatch(fetchProviderRatePlanAsync.failure(error));
  }
};

export const acquiredCoupon = createStandardAction('ACQUIRED_COUPON')<CouponData>();

export const setRenderSimilarProviders = createStandardAction('SET_RENDER_SIMILAR_PROVIDERS')<
  boolean
>();
