import React from 'react';

import { AttentionDisable } from '@travel/icons/ui';
import { cx } from '@travel/utils';

import ErrorPage from 'pages/ErrorPage';

import styles from './busyPage.module.scss';

const BusyPage = () => (
  <ErrorPage
    mainText={
      <>
        We're experiencing exceptionally high traffic at the moment.
        <br />
        Please try again a couple of minutes later.
      </>
    }
    subText={
      <p className={cx(styles.descriptionBox, styles.textCenter)}>
        <span className={styles.description}>
          Hang on tight as we try to get you right back in! We apologize for any inconvenience
          caused.
        </span>
      </p>
    }
    icon={<AttentionDisable color="lightGrey" />}
  />
);

export default BusyPage;
