import { ApiClient } from '@travel/api-client';

import { BookmarksItems } from 'Bookmarks-Types';

export const fetchBookmarksItems = (
  http: ApiClient,
  offset: number,
  limit: number,
): Promise<BookmarksItems> => {
  return http.get('/bookmarks/', {
    values: {
      offset: offset,
      limit: limit,
    },
  });
};

export const addToBookmarks = (http: ApiClient, id: string, shouldIgnoreErrorHandler?: boolean) => {
  return http.post('/bookmarks/', {
    ignoreCommonErrorHandler: shouldIgnoreErrorHandler ?? false,
    values: {
      id: id,
    },
  });
};

export const deleteBookmarkItem = (
  http: ApiClient,
  id: string,
  shouldIgnoreErrorHandler?: boolean,
) => {
  return http.delete(`/bookmarks/?id=${id}`, {
    ignoreCommonErrorHandler: shouldIgnoreErrorHandler ?? false,
  });
};
