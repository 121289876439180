import { fetchNotifications } from 'store/notifications/actions';
import { getLatestPageName } from 'store/pageLoading/selectors';
import { setDefaultSEOData } from 'store/seo/actions';

import { universalRouterProps } from 'core/universalRouter/types';

import { STATUS_KEYS } from './constants';

export const DEFAULT_LIMIT = 30;
export const DEFAULT_OFFSET = 0;

export default ({ store }: universalRouterProps) => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const isMember = state.member.isMember;
  const defaultKey = isMember ? STATUS_KEYS[0] : STATUS_KEYS[2];

  const req = {
    offset:
      getLatestPageName(state) === 'notificationDetails'
        ? state.notifications.items.offset
        : DEFAULT_OFFSET,
    limit: DEFAULT_LIMIT,
    notificationType:
      getLatestPageName(state) === 'notificationDetails' ? state.notifications.reqType : defaultKey,
  };
  return Promise.all([dispatch(fetchNotifications(req)), dispatch(setDefaultSEOData())]);
};
