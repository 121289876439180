import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  ProviderReviewDetail,
  ProviderReviewList,
  ProviderSEOReviewList,
  ReviewErrors,
  ReviewScoreSummary,
} from 'ProviderReview-Types';

import {
  clearError,
  fetchMoreProviderGuestReviewAsync,
  fetchMoreReviewListAsync,
  fetchProviderGuestReviewAsync,
  fetchReviewItemAsync,
  fetchReviewListAsync,
  updaterReportReviewAsync,
  updaterVoteReviewAsync,
} from './actions';

const mapScoreSummary = (payload: ProviderReviewList) => ({
  averageRating: payload.averageRating,
  total: payload.total || 0,
  averageScore: payload.averageScore,
});

const getReview = (reviews: Array<ProviderReviewDetail>, reviewId: string) => {
  const index = reviews.findIndex(review => review.reviewId === reviewId);
  return reviews[index];
};

const updateReview = (
  reviews: Array<ProviderReviewDetail>,
  reviewId: string,
  payload: Partial<ProviderReviewDetail>,
) => {
  return reviews.map(review => {
    return review.reviewId === reviewId ? { ...review, ...payload } : review;
  });
};

export const scoreSummary = createReducer({} as ReviewScoreSummary)
  .handleAction(fetchReviewListAsync.success, (_state, action) => mapScoreSummary(action.payload))
  .handleAction(fetchProviderGuestReviewAsync.success, (_state, action) =>
    mapScoreSummary(action.payload),
  );

/**
 * Provider info page
 */
export const isFetching = createReducer(false as boolean)
  // ReviewList
  .handleAction([fetchReviewListAsync.request], () => true)
  .handleAction([fetchReviewListAsync.success, fetchReviewListAsync.failure], () => false)
  // ProviderReviewInfo
  .handleAction([fetchProviderGuestReviewAsync.request], () => true)
  .handleAction(
    [fetchProviderGuestReviewAsync.success, fetchProviderGuestReviewAsync.failure],
    () => false,
  );

export const isFetchingMore = createReducer(false as boolean)
  // ReviewList
  .handleAction([fetchMoreReviewListAsync.request], () => true)
  .handleAction([fetchMoreReviewListAsync.success, fetchMoreReviewListAsync.failure], () => false)
  // ProviderReviewInfo
  .handleAction([fetchMoreProviderGuestReviewAsync.request], () => true)
  .handleAction(
    [fetchMoreProviderGuestReviewAsync.success, fetchMoreProviderGuestReviewAsync.failure],
    () => false,
  );

export const reviewList = createReducer({} as ProviderReviewList)
  .handleAction(fetchReviewListAsync.success, (_state, action) => action.payload)
  .handleAction(fetchMoreReviewListAsync.success, (state, action) => ({
    ...state,
    ...action.payload,
    reviews: [...state.reviews, ...action.payload.reviews],
  }))
  .handleAction(fetchReviewItemAsync.success, (state, action) => {
    const newReviewList = state.reviews.map(review => {
      if (review.reviewId === action.payload.reviewId) {
        return action.payload;
      }
      return review;
    });

    return {
      ...state,
      reviews: newReviewList,
    };
  })
  .handleAction(updaterReportReviewAsync.request, (state, action) => {
    const reviewId = action.payload;
    const reviews = state.reviews;
    const review = getReview(reviews, reviewId);
    const isReported = !review.reports.isReported;
    const numberOfReports = isReported
      ? review.reports.numberOfReports + 1
      : review.reports.numberOfReports - 1;
    const payload: Partial<ProviderReviewDetail> = {
      reports: {
        isUpdating: true,
        isReported: isReported,
        numberOfReports: numberOfReports,
      },
    };
    const newReviewList = updateReview(reviews, reviewId, payload);
    return {
      ...state,
      reviews: newReviewList,
    };
  })
  .handleAction(updaterReportReviewAsync.success, (state, action) => {
    const { reviewId, count } = action.payload;
    const reviews = state.reviews;
    const review = getReview(reviews, reviewId);
    const payload: Partial<ProviderReviewDetail> = {
      reports: {
        isUpdating: false,
        isReported: review.reports.isReported,
        numberOfReports: count,
      },
    };
    const newReviewList = updateReview(reviews, reviewId, payload);
    return {
      ...state,
      reviews: newReviewList,
    };
  })
  .handleAction(updaterReportReviewAsync.failure, (state, action) => {
    const { reviewId } = action.payload;
    const reviews = state.reviews;
    const review = getReview(reviews, reviewId);
    const isReported = !review.reports.isReported;
    const numberOfReports = isReported
      ? review.reports.numberOfReports + 1
      : review.reports.numberOfReports - 1;
    const payload: Partial<ProviderReviewDetail> = {
      reports: {
        isUpdating: false,
        isReported,
        numberOfReports,
      },
    };
    const newReviewList = updateReview(reviews, reviewId, payload);
    return {
      ...state,
      reviews: newReviewList,
    };
  })
  .handleAction(updaterVoteReviewAsync.request, (state, action) => {
    const reviewId = action.payload;
    const reviews = state.reviews;
    const review = getReview(reviews, reviewId);
    const isHelpful = !review.votes.isHelpful;
    const helpfulVotes = isHelpful ? review.votes.helpfulVotes + 1 : review.votes.helpfulVotes - 1;
    const payload: Partial<ProviderReviewDetail> = {
      votes: {
        isUpdating: true,
        isHelpful: isHelpful,
        helpfulVotes: helpfulVotes,
      },
    };
    const newReviewList = updateReview(reviews, reviewId, payload);
    return {
      ...state,
      reviews: newReviewList,
    };
  })
  .handleAction(updaterVoteReviewAsync.success, (state, action) => {
    const { reviewId, count } = action.payload;
    const reviews = state.reviews;
    const review = getReview(reviews, reviewId);
    const payload: Partial<ProviderReviewDetail> = {
      votes: {
        isUpdating: false,
        isHelpful: review.votes.isHelpful,
        helpfulVotes: count,
      },
    };
    const newReviewList = updateReview(reviews, reviewId, payload);
    return {
      ...state,
      reviews: newReviewList,
    };
  })
  .handleAction(updaterVoteReviewAsync.failure, (state, action) => {
    const { reviewId } = action.payload;
    const reviews = state.reviews;
    const review = getReview(reviews, reviewId);
    const isHelpful = !review.votes.isHelpful;
    const helpfulVotes = isHelpful ? review.votes.helpfulVotes + 1 : review.votes.helpfulVotes - 1;
    const payload: Partial<ProviderReviewDetail> = {
      votes: {
        isUpdating: false,
        isHelpful: isHelpful,
        helpfulVotes: helpfulVotes,
      },
    };
    const newReviewList = updateReview(reviews, reviewId, payload);
    return {
      ...state,
      reviews: newReviewList,
    };
  });

export const errors = createReducer({
  reviewListErrors: [],
  reviewItemErrors: {},
} as ReviewErrors)
  .handleAction(fetchReviewListAsync.failure, (state, action) => ({
    ...state,
    reviewListErrors: action.payload,
  }))
  .handleAction(fetchReviewItemAsync.failure, (state, action) => ({
    ...state,
    reviewItemErrors: {
      ...state.reviewItemErrors,
      ...action.payload,
    },
  }))
  .handleAction(fetchReviewItemAsync.success, (state, action) => ({
    ...state,
    reviewItemErrors: {
      ...state.reviewItemErrors,
      [action.payload.reviewId]: undefined,
    },
  }))
  .handleAction(clearError, (state, action) => ({
    ...state,
    reviewItemErrors: {
      ...state.reviewItemErrors,
      [action.payload.reviewId]: undefined,
    },
  }));

/**
 * SEO Page
 */
export const providerGuestReview = createReducer({} as ProviderSEOReviewList)
  .handleAction(fetchProviderGuestReviewAsync.success, (_state, action) => action.payload)
  .handleAction(fetchMoreProviderGuestReviewAsync.success, (state, action) => ({
    ...state,
    ...action.payload,
    reviews: [...state.reviews, ...action.payload.reviews],
  }));

const providerReviewReducer = combineReducers({
  isFetching,
  isFetchingMore,
  reviewList,
  providerGuestReview,
  scoreSummary,
  errors,
});

export default providerReviewReducer;
export type ProviderReviewState = ReturnType<typeof providerReviewReducer>;
