import { AreaInfo } from './providerInformation';

import { PLACE_NAVIGATION } from '../constants/provider';

export type AreaDetailsItems = {
  areaName: string;
  parentAreaName: string;
  heroImage: string;
  recommendedProviders: Array<RecommendedProvider>;
  recommendedAreas: Array<RecommendedArea>;
  siblingAreas: Array<OtherArea>;
  childAreas: Array<OtherArea>;
  hotSprings: Array<OtherArea>;
  airports: Array<OtherArea>;
  breadcrumb: Array<PlaceLayer>;
  isSeoIndexExpected: boolean;
} & AreaInfo;

export enum AreaRelatedItemsCategory {
  TRAIN_STATION = 'TRAIN_STATION',
  HOT_SPRING = 'HOT_SPRING',
  CITY = 'CITY',
  POINT_OF_INTEREST = 'POINT_OF_INTEREST',
}

export type AreaRelatedItems = {
  placeName: string;
  parentName: string;
  category: AreaRelatedItemsCategory;
  relatedPlacesValid: boolean;
  hotSprings: Array<OtherArea>;
  pointsOfInterest: Array<OtherArea>;
  trainStations: Array<OtherArea>;
};

export type AreaInfoResponse = {
  areas: Array<RecommendedArea>;
};

export type RecommendedProvider = {
  id: string;
  areaName: string;
  providerName: string;
  pathId: string;
  lat: number;
  lng: number;
  price?: Price;
  media: Array<Media>;
  reviewScore: number | null;
  totalReviews: number;
};

export type PlaceLayer = {
  name: string;
  pathId?: string;
  navigatePage?: PLACE_NAVIGATION;
};

export type RecommendedArea = {
  placeName: string;
  pathId: string;
  bookingPercentage?: number;
  media?: Array<Media>;
};

export type OtherArea = {
  name: string;
  pathId: string;
};

export type Price = {
  base?: number;
  total?: number;
  salesPromotion?: SalesPromotion;
};

export type SalesPromotion = {
  id?: string;
  name?: string;
  offerType?: string;
  validUntil?: string;
  incentive?: Incentive;
  representativeFeatureName?: string;
};

export type Incentive = {
  method?: string;
  calculationMethod?: string;
  value?: number;
};

export type Media = {
  id: string;
  type?: string;
  alt?: string;
  name?: string;
  thumbnail?: string;
  url?: string;
  category?: string;
  size?: Size;
};

export type Size = {
  width?: number;
  height?: number;
};

export type AreaDetailsErrors = {
  statusCode: number;
  message: string;
};

export type SearchRequestBody = {
  checkInDate?: string | null;
  checkOutDate?: string | null;
  adults?: number;
  childrenAges?: Array<number>;
  rooms?: number;
  pathId: string;
};

export type AreaInfoSearchRequestBody = {
  sortKey?: string;
  pathId: string;
};

export type AreaRelatedRequestBody = {
  pathId: string;
};
