import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getMarket } from '@travel/i18n';
import { Info } from '@travel/icons/ui';
import useDeviceType from '@travel/traveler-core/hooks/useDeviceType';
import { FlatButton } from '@travel/ui';
import { isNotEmptyArray } from '@travel/utils';

import DataLayer from 'components/DataLayer';
import Swiper from 'components/Swiper';

import { getLocation } from 'store/__router/selectors';
import { getError } from 'store/bookingStep/selectors';
import { getItem as getProviderItem } from 'store/providerInformation/selectors';
import { getItems } from 'store/providerList/selectors';
import { getItems as getPlans } from 'store/providerRatePlan/selectors';

import { Translate } from 'core/translate';
import { Link } from 'core/universalRouter/Link';
import paths from 'core/universalRouter/paths';

import ItemContainer from './ItemContainer';
import PlanItem from './PlanItem';
import ProviderItem from './ProviderItem';
import RoomItem from './RoomItem';

import styles from './guideUserPage.module.scss';

const PLANS = 'PLANS';
const ROOMS = 'ROOMS';
const PROVIDERS = 'PROVIDERS';
export const PAGE_NAME: Record<string, string> = {
  RATE_CHANGED: 'availability_error:other_available_plans_list',
  PLAN_UNAVAILABLE: 'availability_error:other_available_plans_list',
  ITEMPLAN_UNAVAILABLE: 'availability_error:other_available_rooms_list',
  ITEM_UNAVAILABLE: 'availability_error:other_available_hotels_list',
  PROVIDER_UNAVAILABLE: 'availability_error:other_available_hotels_list',
  SALES_PROMOTION_NO_LONGER_APPLICABLE: 'availability_error:other_available_plans_list',
};

export type Items = typeof PLANS | typeof ROOMS | typeof PROVIDERS;

type Props = {
  query: { itemRatePlanId: string };
};

function GuideUserPage({ query }: Props) {
  const { providers, areaName } = useSelector(getItems);
  const providerItem = useSelector(getProviderItem);
  const itemPlans = useSelector(getPlans);
  const location = useSelector(getLocation);
  const market = useSelector(getMarket);
  const errorCode = useSelector(getError)?.code || 'PLAN_UNAVAILABLE';
  const isProviderAvailable = errorCode !== 'PROVIDER_UNAVAILABLE';

  // if provider is unavailable we should guide user to the area which provider in
  const providerPathIdArray = providerItem?.pathId?.split('/');
  if (providerPathIdArray?.length > 2) {
    providerPathIdArray?.pop();
  }
  const newPathId = providerPathIdArray?.join('/');

  const pathId = isProviderAvailable ? providerItem?.pathId : newPathId;

  const selectedItemPlan =
    itemPlans?.find(item => item.id === query.itemRatePlanId) || itemPlans[0];

  const ITEMS = useMemo(() => {
    return {
      ...(isNotEmptyArray(selectedItemPlan?.plans) && { PLANS }),
      ...(isNotEmptyArray(itemPlans) && { ROOMS }),
      ...(isNotEmptyArray(providers) && { PROVIDERS }),
    };
  }, [itemPlans, providers, selectedItemPlan]);

  const [currentItem, setCurrentItem] = useState(Object.keys(ITEMS)[0]);

  useEffect(() => {
    setCurrentItem(Object.keys(ITEMS)[0]);
  }, [ITEMS]);

  const isSP = useDeviceType() === 'sp';

  const rapPageName = PAGE_NAME[errorCode];

  const getLink = (id: string) => `${paths.providerInfo.pathResolver(id)}${location?.search || ''}`;

  const handleChangeItem = (index: number) => {
    setCurrentItem(Object.keys(ITEMS)[index]);
  };

  const backButton = (
    <Link
      to={`${paths.providerList.pathResolver(pathId || '')}${
        location?.search ? `?${location?.search}` : ''
      }`}
    >
      <FlatButton className={styles.backButton} data-testid="guideUserPage-back-button">
        <Translate id="Guide_User_Inventory.Back_to_Search" />
      </FlatButton>
    </Link>
  );

  return (
    <>
      <div className={styles.container} data-testid="guideUserPage-wrapper">
        <p className={styles.message}>
          <Info size={14} />
          <Translate id="Guide_User_Inventory.Error_Message" />
        </p>

        {isSP && (
          <Swiper
            paginationType="arrows"
            onChange={(_, newIndex) => {
              handleChangeItem(newIndex);
            }}
          >
            {Object.keys(ITEMS).map((item, index) => (
              <div className={styles.dotContainer} key={index}></div>
            ))}
          </Swiper>
        )}

        {/* others plan */}
        {isNotEmptyArray(selectedItemPlan?.plans) && (
          <ItemContainer
            title={
              <Translate
                id="Guide_User_Inventory.Suggestion_Plan"
                data={{ item_name: selectedItemPlan.name }}
              />
            }
            items={PLANS}
            currentItems={currentItem}
          >
            {selectedItemPlan.plans.map(plan => (
              <Link to={getLink(providerItem.id)} key={plan.itemRatePlanId}>
                <PlanItem plan={plan} />
              </Link>
            ))}
          </ItemContainer>
        )}

        {/* others itemPlans(rooms) */}
        {isNotEmptyArray(itemPlans) && (
          <ItemContainer
            title={
              <Translate
                id="Guide_User_Inventory.Suggestion_Item"
                data={{ provider_name: providerItem.name }}
              />
            }
            items={ROOMS}
            currentItems={currentItem}
          >
            {itemPlans.map(item => (
              <Link to={getLink(providerItem.id)} key={item.id}>
                <RoomItem ratePlanItem={item} />
              </Link>
            ))}
          </ItemContainer>
        )}

        {/* others providers */}
        {isNotEmptyArray(providers) && (
          <ItemContainer
            title={
              <Translate
                id="Guide_User_Inventory.Suggestion_Provider"
                data={{ area_name: areaName }}
              />
            }
            items={PROVIDERS}
            currentItems={currentItem}
          >
            {providers.map(provider => (
              <Link to={getLink(provider.id)} key={provider.id}>
                <ProviderItem market={market.marketCode} provider={provider} />
              </Link>
            ))}
          </ItemContainer>
        )}

        {!isSP && backButton}
      </div>
      {isSP && <div className={styles.buttonContainer}>{backButton}</div>}
      <DataLayer
        pageName={rapPageName}
        pageType="availability_error"
        siteSection="availability_error"
      />
    </>
  );
}

export default GuideUserPage;
