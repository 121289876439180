type ImageOptions = {
  /** Number of expected width in px */
  width?: number;
  /** Number of expected height in px */
  height?: number;
  /** Flag to define whether the passed URL is already RIC url or not  */
  isRIC?: boolean;
};

const DMC_BASE_URL = 'image.space.rakuten.co.jp';
const DMC_BASE_CONVERTED_URL = 'trvis.r10s.com';
const NAS_BASE_URL = 'img.travel.rakuten.co.jp';
const NAS_BASE_CONVERTED_URL = 'trvimg.r10s.jp';
/**
 * TODO:: Currently when image is converted using RIC from
 * UNIVERSAL_CDN_URL, a border appears in the image. It needs
 * investigation to find out why there is a border.
 */
// const CDN_URL = env('UNIVERSAL_CDN_URL');

const DEFAULT_SRCSET_SIZES_LEVEL = 5;
const DEFAULT_SRCSET_START_WIDTH = 150;

type ConverterConfig = {
  /** Number of generated sizes for srcset */
  srcsetSizesLevel?: number;
  /** Number of min-width in px, will be used as base min width for generating srcset */
  srcsetBaseWidth?: number;
};

export class RakutenImageConverter {
  /** Number of min-width in px, will be used as base min width for generating srcset */
  srcsetBaseWidth: number;
  /** Array of ratio each size for generating srcset */
  _srcsetMultipliers: Array<number>;
  /** Object with cache key to store generated srcset string */
  _generatedSrcset: { [key: string]: string };
  /** Object with cache key to store generated resize string */
  _generatedResize: { [key: string]: string };

  constructor(config?: ConverterConfig) {
    this.srcsetBaseWidth = config?.srcsetBaseWidth || DEFAULT_SRCSET_START_WIDTH;

    this._generatedSrcset = {};
    this._generatedResize = {};
    this._srcsetMultipliers = Array(config?.srcsetSizesLevel || DEFAULT_SRCSET_SIZES_LEVEL)
      .fill(0)
      .map((_, index) => index + 1);
  }

  /**
   * Function to get separator between current url and new URL params
   * @param url - image URL
   * @returns String of separator
   */
  _separator(url?: string) {
    return url?.includes('?') ? '&' : '?';
  }

  /**
   * Function to validate if the given url can be converted or not
   * @param url - image URL
   * @returns isValidated flag
   */
  validated(url?: string) {
    return url?.includes(DMC_BASE_URL) || url?.includes(NAS_BASE_URL);
    /**
     * we shall add back (CDN_URL !== undefined && url?.includes(CDN_URL)) once
     * image border issue gets resolved from CDN_URL server.
     */
  }

  /**
   * Function to get RIC resize URL params
   * @param options - image options object
   * @returns String of resized params
   */
  getResizeParams(options?: ImageOptions) {
    if (!options?.width && !options?.height) return '';

    const cacheKey = JSON.stringify(options);
    if (this._generatedResize[cacheKey]) return this._generatedResize[cacheKey];

    let resize = '';
    if (options?.width && options?.height) resize = `resize=${options?.width}:${options?.height}`;
    else if (options?.width) resize = `resize=${options?.width}:*`;
    else resize = `resize=*:${options?.height}`;

    this._generatedResize[cacheKey] = resize;
    return resize;
  }

  /**
   * Function to convert given url to RIC applied url
   * @param url - image URL
   * @returns RIC Image base URL
   */
  url(url?: string) {
    let convertedUrl = url;

    if (url?.includes(DMC_BASE_URL)) {
      convertedUrl = url?.replace(DMC_BASE_URL, DMC_BASE_CONVERTED_URL);
    } else if (url?.includes(NAS_BASE_URL)) {
      convertedUrl = url?.replace(NAS_BASE_URL, NAS_BASE_CONVERTED_URL);
    }

    return convertedUrl?.replace('http://', 'https://') || '';
  }

  /**
   * Function to apply RIC resize to given image URL
   * @param url - image URL
   * @param options - image options object
   * @returns String of resized RIC Image URL
   */
  resize(url?: string, options?: ImageOptions) {
    if (!options?.isRIC && !this.validated(url)) return url;

    const newUrl = options?.isRIC ? url : this.url(url);
    const separator = this._separator(newUrl);
    const params = this.getResizeParams(options);
    return `${newUrl}${params ? separator : ''}${params}`;
  }

  /**
   * Function to generate srcset string of given image URL
   * @param url - image URL
   * @param options - image options object
   * @returns String of RIC Image srcset
   */
  srcset(url?: string, options?: ImageOptions & { sizesLevel?: number; isCacheEnabled?: boolean }) {
    if (!url || (url && !options?.isRIC && !this.validated(url))) return url;

    const isCacheEnabled = options?.isCacheEnabled ?? true;
    const cacheKey = `${url}-${JSON.stringify(options)}`;
    if (isCacheEnabled && this._generatedSrcset[cacheKey]) return this._generatedSrcset[cacheKey];

    const startWidth = options?.width || this.srcsetBaseWidth; // width is required for relative srcset
    const startHeight = options?.height;
    let multipliers = this._srcsetMultipliers;

    if (options?.sizesLevel) {
      multipliers = Array(options?.sizesLevel)
        .fill(0)
        .map((_, index) => index + 1);
    }

    const newUrl = options?.isRIC ? url : this.url(url);
    const separator = this._separator(newUrl);
    const srcset = multipliers
      .reduce((result: Array<string>, ratio) => {
        const width = ratio * startWidth;
        const params = this.getResizeParams({
          width: ratio * startWidth,
          height: startHeight ? startHeight * ratio : undefined,
        });

        return [...result, `${newUrl}${separator}${params} ${width}w`];
      }, [])
      .join(', ');

    if (isCacheEnabled) {
      this._generatedSrcset[cacheKey] = srcset;
    }
    return srcset;
  }
}

export default RakutenImageConverter;
