import { fetchMember } from 'store/member/actions';
import { fetchUnreadNotificationCount } from 'store/notifications/actions';

import { universalRouterProps } from 'core/universalRouter/types';

export default ({ store: { dispatch } }: universalRouterProps) => {
  return Promise.all([dispatch(fetchMember())]).then(() =>
    dispatch(fetchUnreadNotificationCount()),
  );
};

export const appResolverMember = ({ store: { dispatch } }: universalRouterProps) => {
  return Promise.all([dispatch(fetchMember())]);
};

export const appResolverNotification = ({ store: { dispatch } }: universalRouterProps) => {
  return Promise.all([dispatch(fetchUnreadNotificationCount())]);
};
