import { fetchReservationDetails } from 'store/reservationDetails/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type SearchProps = {
  params: { reservationId: string };
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  return Promise.all([dispatch(fetchReservationDetails(search.params.reservationId))]);
};
