export const PAGE_NAMES: Record<string, string> = {
  UPCOMING: 'reservations_details:upcoming_reservations_list',
  PAST: 'reservations_details:past_reservations_list',
  CANCELED: 'reservations_details:cancelled_reservations_list',
};

export const MAUI_LINK: Record<string, string> = {
  'en-US': 'https://book.travel.rakuten.com/itinerary/signin',
  'zh-TW': 'https://book.travel.rakuten.com.tw/itinerary/signin',
  'zh-CN': 'https://book.travel.rakuten.cn/itinerary/signin',
  'zh-HK': 'https://book.travel.rakuten.com.hk/itinerary/signin',
  'ko-KR': 'https://book.travel.rakuten.co.kr/itinerary/signin',
  'th-TH': 'https://book.travel.rakuten.co.th/itinerary/signin',
  'id-ID': 'https://book.travel.rakuten.co.id/itinerary/signin',
  'en-SG': 'https://book.travel.rakuten.com.sg/itinerary/signin',
  ms: 'https://book.travel.rakuten.com.my/itinerary/signin',
  fr: 'https://book.travel.rakuten.fr/itinerary/signin',
};
