import { AppThunk, createAsyncAction } from 'typesafe-actions';

import {
  GuestPendingReviewsItems,
  GuestReviewsErrors,
  GuestReviewsItems,
  PendingReviewDetail,
  ReviewDetail,
  SearchRequestBody,
} from 'GuestReviews-Types';

import {
  fetchPendingReviewItem,
  fetchPendingReviewItems,
  fetchReviewItem,
  fetchReviewItems,
} from './api';

/**
 * TYPES
 */

export const fetchGuestReviewDetailAsync = createAsyncAction(
  'FETCH_GUEST_REVIEW_DETAIL_REQUEST',
  'FETCH_GUEST_REVIEW_DETAIL_SUCCESS',
  'FETCH_GUEST_REVIEW_DETAIL_FAILURE',
)<undefined, ReviewDetail, GuestReviewsErrors[]>();

export const fetchGuestReviewsAsync = createAsyncAction(
  'FETCH_GUEST_REVIEWS_REQUEST',
  'FETCH_GUEST_REVIEWS_SUCCESS',
  'FETCH_GUEST_REVIEWS_FAILURE',
)<undefined, GuestReviewsItems, GuestReviewsErrors[]>();

export const fetchMoreGuestReviewsAsync = createAsyncAction(
  'FETCH_MORE_GUEST_REVIEWS_REQUEST',
  'FETCH_MORE_GUEST_REVIEWS_SUCCESS',
  'FETCH_MORE_GUEST_REVIEWS_FAILURE',
)<undefined, GuestReviewsItems, GuestReviewsErrors[]>();

export const fetchPendingGuestReviewAsync = createAsyncAction(
  'FETCH_PENDING_GUEST_REVIEW_REQUEST',
  'FETCH_PENDING_GUEST_REVIEW_SUCCESS',
  'FETCH_PENDING_GUEST_REVIEW_FAILURE',
)<undefined, PendingReviewDetail, GuestReviewsErrors>();

export const fetchPendingGuestReviewsAsync = createAsyncAction(
  'FETCH_PENDING_GUEST_REVIEWS_REQUEST',
  'FETCH_PENDING_GUEST_REVIEWS_SUCCESS',
  'FETCH_PENDING_GUEST_REVIEWS_FAILURE',
)<undefined, GuestPendingReviewsItems, GuestReviewsErrors[]>();

export const fetchMorePendingGuestReviewsAsync = createAsyncAction(
  'FETCH_MORE_PENDING_GUEST_REVIEWS_REQUEST',
  'FETCH_MORE_PENDING_GUEST_REVIEWS_SUCCESS',
  'FETCH_MORE_PENDING_GUEST_REVIEWS_FAILURE',
)<undefined, GuestPendingReviewsItems, GuestReviewsErrors[]>();

/**
 * ACTION CREATORS
 */

export const fetchGuestReview = (id: string): AppThunk<Promise<ReviewDetail | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchGuestReviewDetailAsync.request());
  try {
    const response = await fetchReviewItem(apiClient, id);
    dispatch(fetchGuestReviewDetailAsync.success(response));
    return response;
  } catch (error) {
    dispatch(fetchGuestReviewDetailAsync.failure([error]));
  }
};

export const fetchGuestReviews = (
  req?: SearchRequestBody,
): AppThunk<Promise<GuestReviewsItems | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchGuestReviewsAsync.request());
  try {
    const response = await fetchReviewItems(apiClient, req?.offset, req?.limit);
    dispatch(fetchGuestReviewsAsync.success(response));
    return response;
  } catch (error) {
    dispatch(fetchGuestReviewsAsync.failure([error]));
  }
};

export const fetchMoreGuestReviews = (
  req?: SearchRequestBody,
): AppThunk<Promise<GuestReviewsItems | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMoreGuestReviewsAsync.request());
  try {
    const response = await fetchReviewItems(apiClient, req?.offset, req?.limit);
    dispatch(fetchMoreGuestReviewsAsync.success(response));
    return response;
  } catch (error) {
    dispatch(fetchMoreGuestReviewsAsync.failure([error]));
  }
};

/**
 * PENDING
 */
export const REVIEW_EXPIRED_CODE = 'REVIEW_EXPIRED';

export const fetchPendingGuestReview = (id: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchPendingGuestReviewAsync.request());

  try {
    const response = await fetchPendingReviewItem(apiClient, id);
    dispatch(fetchPendingGuestReviewAsync.success(response));
    dispatch(fetchPendingGuestReviewAsync.failure({}));
  } catch (error) {
    const { commonErrorHandler, code } = error;
    if (code === REVIEW_EXPIRED_CODE) {
      dispatch(fetchPendingGuestReviewAsync.failure(error));
    } else {
      dispatch(fetchPendingGuestReviewAsync.failure({}));
      commonErrorHandler();
    }
  }
};

export const fetchPendingGuestReviews = (req?: SearchRequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchPendingGuestReviewsAsync.request());
  try {
    const response = await fetchPendingReviewItems(apiClient, req?.offset, req?.limit);
    dispatch(fetchPendingGuestReviewsAsync.success(response));
  } catch (error) {
    dispatch(fetchPendingGuestReviewsAsync.failure(error));
  }
};

export const fetchMorePendingGuestReviews = (req?: SearchRequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMorePendingGuestReviewsAsync.request());
  try {
    const response = await fetchPendingReviewItems(apiClient, req?.offset, req?.limit);
    dispatch(fetchMorePendingGuestReviewsAsync.success(response));
  } catch (error) {
    dispatch(fetchMorePendingGuestReviewsAsync.failure(error));
  }
};
