import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  AcquiredCouponsErrors,
  AcquiredCouponsItems,
  SearchRequestBody,
} from 'AcquiredCoupons-Types';

import { fetchAcquiredCouponsItems } from './api';

export const fetchAcquiredCouponsAsync = createAsyncAction(
  'FETCH_ACQUIRED_COUPONS_REQUEST',
  'FETCH_ACQUIRED_COUPONS_SUCCESS',
  'FETCH_ACQUIRED_COUPONS_FAILURE',
)<undefined, AcquiredCouponsItems, AcquiredCouponsErrors>();

export const fetchMoreAcquiredCouponsAsync = createAsyncAction(
  'FETCH_MORE_ACQUIRED_COUPONS_REQUEST',
  'FETCH_MORE_ACQUIRED_COUPONS_SUCCESS',
  'FETCH_MORE_ACQUIRED_COUPONS_FAILURE',
)<undefined, AcquiredCouponsItems, AcquiredCouponsErrors>();

export const removeExpiredCoupon = createStandardAction('REMOVE_ACQUIRED_COUPON')<string>();

export const fetchAcquiredCoupons = (req: SearchRequestBody = {}): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchAcquiredCouponsAsync.request());

  try {
    const response = await fetchAcquiredCouponsItems(apiClient, req.offset, req.limit);
    dispatch(fetchAcquiredCouponsAsync.success(response));
  } catch (error) {
    dispatch(fetchAcquiredCouponsAsync.failure(error as AcquiredCouponsErrors));
  }
};

export const fetchMoreAcquiredCoupons = (req: SearchRequestBody = {}): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMoreAcquiredCouponsAsync.request());

  try {
    const response = await fetchAcquiredCouponsItems(apiClient, req.offset, req.limit);
    dispatch(fetchMoreAcquiredCouponsAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreAcquiredCouponsAsync.failure(error as AcquiredCouponsErrors));
  }
};
