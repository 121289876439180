import React from 'react';

import { useTranslation } from '@travel/traveler-core/hooks';
import { ContentsCarousel as ContentsCarouselCore, ContentsCarouselProps } from '@travel/ui';

function ContentsCarousel(props: ContentsCarouselProps) {
  const translatedLabelPrevious = useTranslation({ id: 'Traveler_Common.Alt.Slider_Previous' });
  const translatedLabelNext = useTranslation({ id: 'Traveler_Common.Alt.Slider_Next' });
  return (
    <ContentsCarouselCore
      ariaLabelLeftButton={translatedLabelPrevious}
      ariaLabelRightButton={translatedLabelNext}
      {...props}
    />
  );
}

export default ContentsCarousel;
