import { ApiClient, DEFAULT_TIMEOUT } from '@travel/api-client';

import {
  Applicant,
  ApplySpAndPoint,
  BookingStep3,
  BookingStepData,
  ItemPlan,
  ProviderQuestion,
} from 'BookingStep-Types';

export type PostBodyStep1 = {
  checkInDate?: string;
  checkOutDate?: string;
  childAges?: string[];
  itemId: string;
  noOfAdults: number;
  ratePlanId: string;
  providerId: string;
  itemRatePlanId: string;
  roomCount: number;
  appliedSalesPromotionIds?: string[] | null;
  perRoomPriceBeforeDiscount: number;
  perRoomPriceAfterDiscount: number | null;
};

export type PostBodyStep2 = {
  applicant: Applicant;
  expectedArrivalTime: string;
  itemPlans: ItemPlan[];
  specialRequest: string;
  providerQuestions?: ProviderQuestion[];
  stepId: string;
  totalEarnedPoints?: {
    amount: number;
    basePoints: number;
    promotionPoints: number;
  };
  totalPriceInProviderCurrency: number;
  totalPriceInUserCurrency: number;
  totalPriceUserAfterDiscount?: number;
};
export type PostBodyApplySpAndPoint = {
  itemPlans: {
    echoId: string;
    itemId: string;
    itemRatePlanId: string;
    appliedSalesPromotionId: string;
    appliedSubSalesPromotionId?: string;
    totalPointsUsed?: number;
    appliedGoToPromotionId?: string;
    appliedExtraRates?: {
      age: number;
      appliedFeatureIds: string[];
    }[];
  }[];
  stepId: string;
  acquiredSalesPromotionId?: string;
};

export type PostBodyStep3 = {
  siteId?: string;
  timeEntered?: string;
  stepId: string;
  optedForInsurance: boolean;
  payment: {
    card?: CardPostBodyStep3;
    method: string;
    paidCurrency: string;
    paymentAmount: number;
  };
};
export type CardPostBodyStep3 = {
  cardHolderName: string;
  cardToken: string;
  creditCardExpiryMonth: string;
  creditCardExpiryYear: string;
  cvvToken: string;
  saveCard: boolean;
  brandCode?: string | null;
  countryCode?: string | null;
  cardType?: string | null;
  iin?: string | null;
  issuerCode?: string | null;
  last4Digits?: string | null;
  signature?: string | null;
  timestamp?: string | null;
};

export const bookingStep1 = (http: ApiClient, req: PostBodyStep1): Promise<BookingStepData> =>
  http.post('/consumer/booking-steps/step1', {
    headers: {},
    values: req,
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    timeout: DEFAULT_TIMEOUT,
  });

export const bookingStep2 = (http: ApiClient, req: PostBodyStep2): Promise<BookingStepData> =>
  http.post('/consumer/booking-steps/step2', {
    headers: {},
    values: req,
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    timeout: DEFAULT_TIMEOUT,
  });

export const getBookingStep2 = (http: ApiClient, stepId: string): Promise<BookingStepData> =>
  http.get(`/consumer/booking-steps/step2/${stepId}`, {
    headers: {},
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    timeout: DEFAULT_TIMEOUT,
  });

export const applySpAndPoint = (
  http: ApiClient,
  req: PostBodyApplySpAndPoint,
): Promise<ApplySpAndPoint> =>
  http.post('/consumer/booking-steps/applySpAndPoint', {
    headers: {},
    values: req,
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    timeout: DEFAULT_TIMEOUT,
  });

export const bookingStep3 = (http: ApiClient, req: PostBodyStep3): Promise<BookingStep3> =>
  http.post('/consumer/booking-steps/step3', {
    values: req,
    headers: {
      'trv-platform': 'web',
    },
    withAuthCode: true,
    ignoreCommonErrorHandler: true,
    timeout: DEFAULT_TIMEOUT,
  });
