import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ReservationListErrors, ReservationListItems, STATUS_TYPE } from 'ReservationList-Types';

import {
  fetchMoreReservationsAsync,
  fetchReservationListAsync,
  setLastStatusType,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchReservationListAsync.request], (state, action) => true)
  .handleAction(
    [fetchReservationListAsync.success, fetchReservationListAsync.failure],
    (state, action) => false,
  );

export const isFetchingMore = createReducer(false as boolean)
  .handleAction([fetchMoreReservationsAsync.request], (state, action) => true)
  .handleAction(
    [fetchMoreReservationsAsync.success, fetchMoreReservationsAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as ReservationListItems)
  .handleAction(fetchReservationListAsync.success, (state, action) => {
    return action.payload;
  })
  .handleAction(fetchMoreReservationsAsync.success, (state, action) => {
    return {
      offset: action.payload.offset,
      limit: action.payload.limit,
      total: action.payload.total,
      reservations: state.reservations.concat(action.payload.reservations),
    };
  });

export const errors = createReducer([] as ReservationListErrors[]).handleAction(
  fetchReservationListAsync.failure,
  (state, action) => action.payload,
);

export const selectedStatusType = createReducer('UPCOMING' as STATUS_TYPE).handleAction(
  setLastStatusType,
  (state, action) => action.payload,
);

const reservationListReducer = combineReducers({
  isFetching,
  items,
  errors,
  isFetchingMore,
  selectedStatusType,
});

export default reservationListReducer;
export type ReservationListState = ReturnType<typeof reservationListReducer>;
