import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { AreaRelatedItemsCategory } from '@travel/traveler-core/types/areaDetails';

import { AreaRelatedItems } from 'AreaDetails-Types';

import { AreaDetailsState } from './reducer';

export const getItems = (state: RootState) => state.areaDetails.items;
export const getErrors = (state: RootState) => state.areaDetails.errors;

export const getAreaDetails = (state: RootState) => state.areaDetails;

export const getAreaName = (state: RootState) => state.areaDetails.items.areaName;
export const getParentAreaName = (state: RootState) => state.areaDetails.items.parentAreaName;
export const getHeroImage = (state: RootState) => [{ url: state.areaDetails.items.heroImage }];
export const getRecommendedProviders = (state: RootState) =>
  state.areaDetails.items.recommendedProviders;
export const getRecommendedAreas = (state: RootState) => state.areaDetails.items.recommendedAreas;
export const getAreas = (state: RootState) => state.areaDetails.areaInfoItems.areas;

export const getChildAreas = (state: RootState) => state.areaDetails.items.childAreas;
export const getSiblingAreas = (state: RootState) => state.areaDetails.items.siblingAreas;
export const getHotSprings = (state: RootState) => state.areaDetails.items.hotSprings;
export const getAirportAreas = (state: RootState) => state.areaDetails.items.airports;

export const getAreaRelatedItems = (state: RootState) => state.areaDetails.areaRelatedItems;

export const getAreaRelatedItemsTitle = createSelector(
  getAreaRelatedItems,
  (state: AreaRelatedItems) => {
    switch (state.category) {
      case AreaRelatedItemsCategory.CITY:
        return state.placeName;
      case AreaRelatedItemsCategory.TRAIN_STATION:
      case AreaRelatedItemsCategory.HOT_SPRING:
      case AreaRelatedItemsCategory.POINT_OF_INTEREST:
      default:
        return state.parentName;
    }
  },
);

export const getIsFetching = createSelector(
  getAreaDetails,
  (state: AreaDetailsState) => state.isFetching,
);
