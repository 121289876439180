import { ApiClient } from '@travel/api-client';

import { ReservationListItems, ReservationListPostBodyType } from 'ReservationList-Types';

export const fetchReservationListItems = (
  http: ApiClient,
  req: ReservationListPostBodyType,
): Promise<ReservationListItems> =>
  http
    .post('/consumer/mypage/reservation/list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      values: { ...req },
    })
    .then((data: ReservationListItems) => {
      return data;
    });
