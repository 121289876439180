import { Request, Response } from 'express';
import { LocationState } from 'history';
import { Store } from 'typesafe-actions';

export type universalRouterProps = {
  store: Store;
  isServer?: boolean;
  res?: Response;
  req?: Request;
};

export class Location {
  readonly pathname: string = '/';
  readonly key?: string = '';
  readonly search?: string = '';
  readonly hash?: string = '';
  readonly state?: LocationState | null = {};
  readonly protocol?: string = '';
  constructor(init?: Partial<Location>) {
    Object.assign(this, init);
  }
}
