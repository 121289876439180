import { RatParam } from '../types/analytics';

export const RAT_PAGE_TYPE_OBJ = {
  top: {
    pageType: 'top',
    siteSection: 'top',
    parentName: 'top:search_form',
  },
  topRentalCar: {
    pageType: 'top',
    siteSection: 'top',
    parentName: 'top:rental_car_banner',
  },
  providerListUndated: {
    pageType: 'search',
    siteSection: 'search',
    parentName: 'search:search_results_undated_list',
  },
  providerListDated: {
    pageType: 'search',
    siteSection: 'search',
    parentName: 'search:search_results_dated_list',
  },
  couponSearch: {
    pageType: 'top',
    siteSection: 'account_coupons',
    parentName: 'account_coupons:coupons_list',
  },
  area: {
    pageType: 'top',
    siteSection: 'area',
    parentName: 'area:search_form',
  },
  rateplan: {
    pageType: 'shop_item',
    siteSection: 'hotel',
    parentName: 'area:search_form',
  },
  providerInfoUndated: {
    pageType: 'shop_item',
    siteSection: 'hotel',
    parentName: 'hotel:rooms_and_plans_undated_list',
  },
  providerInfoDated: {
    pageType: 'shop_item',
    siteSection: 'hotel',
    parentName: 'hotel:rooms_and_plans_dated_list',
  },
  modification: {
    pageType: 'top',
    siteSection: 'modification',
    parentName: 'modification:change_booking_dates_form',
  },
  modificationStep2: {
    pageType: 'top',
    siteSection: 'modification',
    parentName: 'modification:pre_modification_summary',
  },
  modificationStep3: {
    pageType: 'top',
    siteSection: 'modification',
    parentName: 'modification:confirmation_message',
  },
  reviewList: {
    pageType: 'shop_item',
    siteSection: 'hotel',
    parentName: 'hotel:reviews_list',
  },
  vacancyCalendar: {
    pageType: 'shop_item',
    siteSection: 'hotel',
    parentName: 'area:search_form',
  },
  vacancyCalendarUndated: {
    pageType: 'shop_item',
    siteSection: 'hotel',
    parentName: 'area:search_form',
  },
  reservationListRentalCar: {
    pageType: 'top',
    siteSection: 'reservations_details',
    parentName: 'reservations_details:rental_car_banner',
  },
  authRequest: {
    pageType: 'cart_checkout',
    siteSection: 'booking',
    parentName: 'booking:non_member_email_form',
  },
  bookingStep1: {
    parentName: 'booking:guest_details_form',
    pageType: 'cart_checkout',
    siteSection: 'booking',
  },
  bookingStep2: {
    parentName: 'booking:payment_details_form',
    pageType: 'cart_checkout',
    siteSection: 'booking',
  },
  bookingStep3: {
    pageType: 'cart_checkout',
    siteSection: 'booking',
    parentName: 'booking:confirmation_message',
  },
  bookingRentalCar: {
    pageType: 'cart_checkout',
    siteSection: 'booking',
    parentName: 'booking:rental_car_banner',
  },
  bookmarkList: {
    pageType: 'top',
    siteSection: 'account_bookmarks',
    parentName: 'account_bookmarks:bookmarks_list',
  },
  cancelStep1: {
    pageType: 'top',
    siteSection: 'cancellation',
    parentName: 'cancellation:pre_cancellation_details',
  },
  couponDetail: {
    pageType: 'top',
    siteSection: 'account_coupons',
    parentName: 'account_coupons:coupons_detail',
  },
  guestReviewDetail: {
    pageType: 'top',
    siteSection: 'account_reviews',
    parentName: 'account_reviews:review_details',
  },
  guestReviewList: {
    pageType: 'top',
    siteSection: 'account_reviews',
    parentName: 'account_reviews:reviews_list',
  },
  loginOrGuest: {
    pageType: 'my_account',
    siteSection: 'my_account',
    parentName: 'log_in_or_continue_as_guest_menu_popup',
  },
  notificationDetails: {
    pageType: 'top',
    siteSection: 'account_notifications',
    parentName: 'account_notifications:notification_details',
  },
  notifications: {
    pageTpe: 'top',
    siteSection: 'account_notifications',
    parentName: 'account_notifications:notifications_list',
  },
  postReview: {
    pageType: 'top',
    siteSection: 'account_reviews',
    parentName: 'account_reviews:post_review_form',
  },
  mailSubscription: {
    pageType: 'top',
    siteSection: 'account_settings',
    parentName: 'account_settings:mail_magazine_authentication',
  },
};

export const ratPropsToParams: RatParam = {
  generic: [
    {
      prop: 'siteSection',
      id: 'ratSiteSection',
    },
    {
      prop: 'pageType',
      id: 'ratPageType',
    },
    {
      prop: 'pageName',
      id: 'ratPageName',
    },
    {
      prop: 'reservationId',
      id: 'ratReservationId',
    },
    {
      prop: 'shopId',
      id: 'ratShopId',
    },
    {
      prop: 'searchQuery',
      id: 'ratSearchQuery',
    },
    {
      prop: 'searchCondition',
      id: 'ratSearchCondition',
    },
    {
      prop: 'shopIdList',
      id: 'ratShopIdList',
    },
    {
      prop: 'itemIds',
      id: 'ratItemId',
    },
    {
      prop: 'areaName',
      id: 'ratArea',
    },
    {
      prop: 'orderPaymentMethod',
      id: 'ratPayment',
    },
  ],
  custom: [
    {
      prop: 'numOfAdults',
      id: 'number_of_adult_guests',
    },
    {
      prop: 'numOfChildren',
      id: 'number_of_child_guests',
    },
    {
      prop: 'numOfGuest',
      id: 'number_of_guests',
    },
    {
      prop: 'salesPromotions',
      id: 'sales_promotion_ids',
    },
    {
      prop: 'childrenAges',
      id: 'child_guest_ages',
    },
    {
      prop: 'noOfRooms',
      id: 'number_of_rooms',
    },
    {
      prop: 'isBookable',
      id: 'is_bookable',
    },
    {
      prop: 'propertyCountry',
      id: 'area_country',
    },
    {
      prop: 'propertyCity',
      id: 'area_city',
    },
    {
      prop: 'hotelRegion',
      id: 'area_province',
    },
    {
      prop: 'propertyDistrict',
      id: 'area_district',
    },
    {
      prop: 'placeName',
      id: 'place_name',
    },
    {
      prop: 'couponSalesPromotion',
      id: 'gotten_coupon_sales_promotion_id',
    },
    {
      prop: 'providerCategory',
      id: 'provider_category',
    },
    {
      prop: 'providerFacilities',
      id: 'provider_facilities',
    },
    {
      prop: 'reviewId',
      id: 'review_id',
    },
    {
      prop: 'searchFilters',
      id: 'search_condition',
    },
  ],
  conversion: [
    {
      prop: 'purchaseGms',
      id: 'purchase_gms',
    },
    {
      prop: 'purchaseOrder',
      id: 'purchase_order',
    },
    {
      prop: 'reserveRoom',
      id: 'reserve_room',
    },
    {
      prop: 'reserveNum',
      id: 'reserve_num',
    },
    {
      prop: 'reviewPost',
      id: 'review_post',
    },
    {
      prop: 'cancel',
      id: 'cancel',
    },
    {
      prop: 'mailMagSubscribe',
      id: 'mailmag_subscribe',
    },
    {
      prop: 'getCouponItem',
      id: 'get_coupon_item',
    },
  ],
};
