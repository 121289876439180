import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { BookmarksErrors, BookmarksItems } from 'Bookmarks-Types';

import { fetchBookmarksAsync, fetchMoreBookmarksAsync, removeBookmark } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchBookmarksAsync.request], (_state, _action) => true)
  .handleAction(
    [fetchBookmarksAsync.success, fetchBookmarksAsync.failure],
    (_state, _action) => false,
  );

export const isFetchingMore = createReducer(false as boolean)
  .handleAction([fetchMoreBookmarksAsync.request], (_state, action) => true)
  .handleAction(
    [fetchMoreBookmarksAsync.success, fetchMoreBookmarksAsync.failure],
    (_state, _action) => false,
  );

export const items = createReducer({} as BookmarksItems)
  .handleAction(fetchBookmarksAsync.success, (_state, action) => action.payload)
  .handleAction(fetchMoreBookmarksAsync.success, (state, action) => ({
    ...state,
    ...action.payload,
    bookmarks: [...state.bookmarks, ...action.payload.bookmarks],
  }))
  .handleAction(removeBookmark, (state, action) => ({
    ...state,
    bookmarks: state.bookmarks.filter(({ id }) => id !== action.payload.id),
  }));

export const errors = createReducer([] as BookmarksErrors[])
  .handleAction(fetchBookmarksAsync.failure, (_state, action) => action.payload)
  .handleAction(fetchBookmarksAsync.success, (_state, _action) => []);

const bookmarksReducer = combineReducers({
  isFetching,
  items,
  errors,
  isFetchingMore,
});

export default bookmarksReducer;
export type BookmarksState = ReturnType<typeof bookmarksReducer>;
