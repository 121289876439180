import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { BookmarksErrors, BookmarksItems, SearchRequestBody } from 'Bookmarks-Types';

import { deleteBookmarkItem, fetchBookmarksItems } from './api';

export const fetchBookmarksAsync = createAsyncAction(
  'FETCH_BOOKMARKS_REQUEST',
  'FETCH_BOOKMARKS_SUCCESS',
  'FETCH_BOOKMARKS_FAILURE',
)<undefined, BookmarksItems, BookmarksErrors>();

export const fetchBookmarks = (req: SearchRequestBody): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchBookmarksAsync.request());

  try {
    const response = await fetchBookmarksItems(apiClient, req.offset || 0, req.limit || 5);
    dispatch(fetchBookmarksAsync.success(response));
  } catch (error) {
    dispatch(fetchBookmarksAsync.failure(error as BookmarksErrors));
  }
};

export const fetchMoreBookmarksAsync = createAsyncAction(
  'FETCH_MORE_BOOKMARKS_REQUEST',
  'FETCH_MORE_BOOKMARKS_SUCCESS',
  'FETCH_MORE_BOOKMARKS_FAILURE',
)<undefined, BookmarksItems, BookmarksErrors[]>();

export const fetchMoreBookmarks = (req: SearchRequestBody): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchMoreBookmarksAsync.request());
  try {
    const response = await fetchBookmarksItems(apiClient, req.offset ?? 0, req.limit || 5);
    dispatch(fetchMoreBookmarksAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreBookmarksAsync.failure(error));
  }
};

export const removeBookmark = createStandardAction('REMOVE_BOOKMARK')<{ id: string }>();

export const deleteBookmark = (providerId: string): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  try {
    await deleteBookmarkItem(apiClient, providerId, true);
    dispatch(removeBookmark({ id: providerId }));
  } catch (error) {
    // TODO: error on page or show global error page
    console.error(error);
  }
};

export const updateIsBookmarked = createStandardAction('UPDATE_IS_BOOKMARKED')<{
  id: string;
  isBookmarked: boolean;
}>();
