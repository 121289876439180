import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { getDictionary } from '@travel/i18n';
import { getTranslation } from '@travel/translation';
// import { isNotEmptyArray } from '@travel/utils';
import { SortKeysType } from '@travel/traveler-core/constants/provider';
import { isEmptyObject, isNotEmptyArray } from '@travel/utils';

import {
  DefaultQueryParams,
  getDefaultQueryParams,
  SearchRequestBody,
  shouldUpdateProviderList,
} from 'pages/ProviderListPage/resolver';

// import { SORT_OPTIONS } from 'constants/provider';
import { updateSEOData } from 'store/seo/actions';

import paths from 'core/universalRouter/paths';
import { Location } from 'ProviderInformation-Types';
import { ProviderListErrors, ProviderListFilters, ProviderListItems } from 'ProviderList-Types';

import { callTealium } from '../../utils/dataLayer';
import { fetchProviderListFilters, fetchProviderListItems } from './api';
import { getItems } from './selectors';

type FetchProviderListOptionType = {
  isMapSearch?: boolean;
  isSuggestionSearch?: boolean;
  page?: number;
};

type FetchProviderListPayload = {
  response: ProviderListItems;
  options?: FetchProviderListOptionType;
};

type RequestOffset = {
  page: number;
  offset: number;
};

export const updateMapExpansionState = createStandardAction('UPDATE_MAP_EXPANSION_STATE')<
  boolean
>();

export const updateScrollPosition = createStandardAction('UPDATE_SCROLL_POSITION')<number>();

export const updateRequestOffset = createStandardAction('UPDATE_REQUEST_OFFSETS')<RequestOffset>();

export const updateAreaName = createStandardAction('UPDATE_AREA_NAME')<string>();

export const clearProviderList = createStandardAction('CLEAR_PROVIDER_LIST')<Location>();

export const updateQueryParams = createStandardAction('UPDATE_QUERY_PARAMS')<DefaultQueryParams>();

export const fetchProviderListAsync = createAsyncAction(
  'FETCH_PROVIDER_LIST_REQUEST',
  'FETCH_PROVIDER_LIST_SUCCESS',
  'FETCH_PROVIDER_LIST_FAILURE',
)<undefined, FetchProviderListPayload, ProviderListErrors[]>();

export const fetchProviderList = (
  req: SearchRequestBody,
  discardQueryParamsUpdate?: boolean,
  options?: FetchProviderListOptionType,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  // Due to scroll position restoration,
  // we have to keep the same state from where we have left
  // only keep the state when back from Provider info and there is fetched list
  const {
    pageLoading: { pageLoading },
    providerList: { items, queryParams: prevQueryParams },
  } = getState();
  const currentQueryParams = getDefaultQueryParams(req);
  const shouldRefetch = shouldUpdateProviderList(currentQueryParams, prevQueryParams);
  if (pageLoading.pageName === 'providerInfo' && !isEmptyObject(items) && !shouldRefetch) {
    return;
  }
  dispatch(fetchProviderListAsync.request());
  try {
    const response = await fetchProviderListItems(apiClient, req);

    if (options?.page) {
      dispatch(updateRequestOffset({ page: options?.page, offset: Number(req.offset || 0) }));
    }

    dispatch(fetchProviderListAsync.success({ response, options }));
    const queryParams = getDefaultQueryParams(req);
    if (discardQueryParamsUpdate) {
      dispatch(updateQueryParams({}));
    } else {
      dispatch(updateQueryParams(queryParams));
    }
  } catch (error) {
    dispatch(fetchProviderListAsync.failure(error as ProviderListErrors[]));
  }
};

export const fetchMoreProvidersAsync = createAsyncAction(
  'FETCH_MORE_PROVIDERS_REQUEST',
  'FETCH_MORE_PROVIDERS_SUCCESS',
  'FETCH_MORE_PROVIDERS_FAILURE',
)<undefined, ProviderListItems, ProviderListErrors[]>();

export const fetchMoreProviders = (req: SearchRequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMoreProvidersAsync.request());
  try {
    const response = await fetchProviderListItems(apiClient, req);
    const providerIds = response.providers.map(item => item.id);

    const tealiumData = getState().seo.dataLayer;
    callTealium({
      ...tealiumData,
      ratShopIdList: [tealiumData.ratShopIdList, ...providerIds].join(','),
      eventType: 'view',
      tealium_event: 'view',
    });

    dispatch(fetchMoreProvidersAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreProvidersAsync.failure(error as ProviderListErrors[]));
  }
};

export const fetchProviderFiltersAsync = createAsyncAction(
  'FETCH_PROVIDERS_FILTERS_REQUEST',
  'FETCH_PROVIDERS_FILTERS_SUCCESS',
  'FETCH_PROVIDERS_FILTERS_FAILURE',
)<undefined, ProviderListFilters, ProviderListErrors[]>();

export const fetchProviderFilters = (req: SearchRequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderFiltersAsync.request());
  try {
    const response = await fetchProviderListFilters(apiClient, req);
    dispatch(fetchProviderFiltersAsync.success(response));
  } catch (error) {
    dispatch(fetchProviderFiltersAsync.failure(error as ProviderListErrors[]));
  }
};

export const fetchSEOdata = (
  pageIdentifier: string,
  _sortKey?: SortKeysType,
  isIndexed?: boolean,
): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const dictionary = getDictionary(state);

  // Note: SortKey and Feature indexing are disabled temporarily from 2.2.3
  // let featureNames: Array<string> = [];
  const items = getItems(state);
  // const filters = getFilters(state);
  // const isFiltered = filters?.some(filter => filter?.features?.some(feature => feature.selected));
  // const selectedFeatureNames = getSelectedObjectivesFeatureName(state);
  // const selectedFeatureManagementNames = getSelectedObjectivesManagementName(state);
  // const condition = `place${isNotEmptyArray(selectedFeatureNames) ? '_feature' : ''}${
  //   sortKey ? '_sort' : ''
  // }`;

  // if (selectedFeatureNames?.length) {
  //   const discriminator = getTranslation({ id: 'SEO.Common.Features.Discriminator' }).join('');
  //   const lastDiscriminator = getTranslation({ id: 'SEO.Common.Features.Last_Discriminator' }).join(
  //     '',
  //   );

  //   featureNames = selectedFeatureNames.reduce((result: Array<string>, feature, index) => {
  //     return [
  //       ...result,
  //       feature,
  //       index === selectedFeatureNames.length - 1
  //         ? ''
  //         : index === selectedFeatureNames.length - 2
  //         ? lastDiscriminator
  //         : discriminator,
  //     ];
  //   }, []);
  // }

  const labelProps = {
    condition: () => `status == place`,
    data: {
      place: items.areaName,
    },
  };

  const title = getTranslation(
    {
      id: 'SEO.ProviderList.Title',
      ...labelProps,
    },
    dictionary,
  ).join('');

  const description = getTranslation(
    {
      id: 'SEO.ProviderList.Meta.Description',
      ...labelProps,
    },
    dictionary,
  ).join('');

  dispatch(
    updateSEOData({
      title,
      description,
      isIndexedByDefault: isIndexed && isNotEmptyArray(items.providers) && items.isSeoIndexExpected,
      canonicalPath: paths.providerList.canonicalPathResolver(
        pageIdentifier,
        // selectedFeatureManagementNames,
        // sortKey,
      ),
    }),
  );
};
