import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { ProviderViewersErrors, ProviderViewersItem } from 'ProviderViewers-Types';

import { fetchAll } from './apis';

export const fetchProviderViewersAsync = createAsyncAction(
  'FETCH_PROVIDER_VIEWERS_REQUEST',
  'FETCH_PROVIDER_VIEWERS_SUCCESS',
  'FETCH_PROVIDER_VIEWERS_FAILURE',
)<undefined, ProviderViewersItem, ProviderViewersErrors[]>();

export const fetchProviderViewers = (id: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderViewersAsync.request());

  try {
    const response = await fetchAll(apiClient, id);
    dispatch(fetchProviderViewersAsync.success(response));
  } catch (error) {
    // dispatch(fetchProviderViewersAsync.failure([error]));
    // NOTE: this endpoint is not exposed yet, defind mocked response
    dispatch(fetchProviderViewersAsync.success({ count: 10 }));
  }
};
