import React from 'react';

import env from '@travel/env';
import MediaCore, { Props } from '@travel/traveler-core/components/Media';

export type MediaType = {
  type?: string;
  alt?: string;
  url?: string;
  videoId?: string;
  videoVendorId?: string;
  thumbnailUrl?: string;
};

function Media(props: Props) {
  return (
    <MediaCore
      {...props}
      videoAccountId={env('BRIGHTCOVE_ACCOUNT_ID')}
      videoPlayerId={env('BRIGHTCOVE_PLAYER_ID')}
      data-testid="media-item"
      isDisableSrcSetCache={true}
    />
  );
}

export default React.memo(Media);
