import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { SubscriptionErrors, SubscriptionItem } from 'Subscription-Types';

import {
  clearSubscription,
  doSubscribeAsync,
  doUnsubscribeAsync,
  fetchSubscriptionAsync,
  SUBSCRIPTION_SUCCESS,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchSubscriptionAsync.request, doSubscribeAsync.request, doUnsubscribeAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchSubscriptionAsync.success,
      fetchSubscriptionAsync.failure,
      doSubscribeAsync.success,
      doSubscribeAsync.failure,
      doUnsubscribeAsync.success,
      doUnsubscribeAsync.failure,
    ],
    (state, action) => false,
  );

export const item = createReducer({
  isMember: false,
  isSubscribed: false,
} as SubscriptionItem)
  .handleAction(fetchSubscriptionAsync.success, (state, action) => action.payload)
  .handleAction(clearSubscription, () => ({} as SubscriptionItem));

export const errors = createReducer([] as SubscriptionErrors[])
  .handleAction(
    [fetchSubscriptionAsync.failure, doSubscribeAsync.failure, doUnsubscribeAsync.failure],
    (state, action) => action.payload,
  )
  .handleAction(
    [fetchSubscriptionAsync.success, doSubscribeAsync.success, doUnsubscribeAsync.success],
    () => [],
  );

export const isSuccess = createReducer(false as boolean)
  .handleAction(SUBSCRIPTION_SUCCESS, () => true)
  .handleAction(clearSubscription, () => false)
  .handleAction(doUnsubscribeAsync.success, () => false);

const subscriptionReducer = combineReducers({
  isFetching,
  item,
  errors,
  isSuccess,
});

export default subscriptionReducer;
export type SubscriptionState = ReturnType<typeof subscriptionReducer>;
