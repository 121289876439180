import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  ApplicableCouponDetails,
  ApplicableCouponRequestBody,
  CouponDetails,
  SalesPromotionErrors,
} from 'SalesPromotion-Types';

import { checkBin, requestCouponApplicable, requestCouponDetails } from './apis';
import { getCouponDetails } from './selectors';

export const getCouponDetailsAsync = createAsyncAction(
  'FETCH_COUPON_DETAILS_REQUEST',
  'FETCH_COUPON_DETAILS_SUCCESS',
  'FETCH_COUPON_DETAILS_FAILURE',
)<undefined, { id: string; details: CouponDetails }, SalesPromotionErrors[]>();

export const getApplicableCouponAsync = createAsyncAction(
  'FETCH_APPLICABLECOUPON_COUPON_REQUEST',
  'FETCH_APPLICABLECOUPON_COUPON_SUCCESS',
  'FETCH_APPLICABLECOUPON_COUPON_FAILURE',
)<undefined, ApplicableCouponDetails, SalesPromotionErrors[]>();

export const resetCouponDetail = createStandardAction('RESET_COUPON_DETAILS')();

export const fetchCouponDetails = (
  id?: string,
  shouldForceRefetch?: boolean,
  shouldIgnoreCommonError?: boolean,
): AppThunk<Promise<CouponDetails | undefined>> => async (dispatch, getState, { apiClient }) => {
  if (!id) {
    return;
  }

  const state = getState();
  const cache = getCouponDetails(id)(state);

  if (!shouldForceRefetch && cache) {
    return cache;
  }

  dispatch(getCouponDetailsAsync.request());

  try {
    const response = await requestCouponDetails(apiClient, id, shouldIgnoreCommonError);
    dispatch(getCouponDetailsAsync.success({ id, details: response }));
    return response;
  } catch (error) {
    dispatch(getCouponDetailsAsync.failure([error] as SalesPromotionErrors[]));
    return;
  }
};

export const fetchApplicableCoupon = (
  req?: ApplicableCouponRequestBody,
): AppThunk<Promise<ApplicableCouponDetails | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(getApplicableCouponAsync.request());
  try {
    const response = await requestCouponApplicable(apiClient, req);
    dispatch(getApplicableCouponAsync.success(response));
    return response;
  } catch (error) {
    dispatch(getApplicableCouponAsync.failure([error] as SalesPromotionErrors[]));
  }
};

export const fetchBinEligibility = (
  coupon?: string,
  bin?: string,
): AppThunk<Promise<boolean>> => async (dispatch, getState, { apiClient }) => {
  try {
    const { isUsable } = await checkBin(apiClient, coupon, bin);
    return isUsable;
  } catch (error) {
    return false;
  }
};
