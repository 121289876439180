import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { CountriesState } from './reducer';

export const getCountriesState = (state: RootState) => state.countries;

export const getCountries = createSelector(
  getCountriesState,
  (state: CountriesState) => state.items,
);
