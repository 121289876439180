import { DEFAULT_SITE_LANGUAGE, DEFAULT_SITE_MARKET } from '@travel/traveler-core/constants/common';
import { SortKeysType } from '@travel/traveler-core/constants/provider';
import { isNotEmptyArray } from '@travel/utils';

import { featuresPathPrefix, pageNumberPathPrefix, sortkeyPathPrefix } from 'constants/seo';

export const baseUrl: { [key: string]: string } = {
  area: '/explorer',
  providerList: '/hotel_list',
  providerInfo: '/hotel_info_item',
  providerReviewInfo: '/hotel_info_review',
  booking: '/hotel_booking',
  modification: '/hotel_modification',
  cancellation: '/hotel_cancellation',
  reservation: '/hotel_reservation',
  member: '/myaccount',
  offline: '/offline',
  logout: '/logout',
  privacyPolicy: '/privacy_policy',
  eSimCampaign: '/esim_campaign',
};

/**
 * Restrict unwanted request to call APIs
 * As per supplier application path id should be alphanumeric character with underscore
 * https://confluence.rakuten-it.com/confluence/pages/viewpage.action?pageId=4120043960
 */
const pathIdRegExp = '[A-Za-z0-9-_/]+?';

/**
 * Provider ID will be 14 digit with first two digit will be 10
 * https://confluence.rakuten-it.com/confluence/pages/viewpage.action?pageId=2146992170
 */
const providerIDExp = '[0-9]{14}';

/**
 * :: USAGE ::
 *
 * "subPath" is NOT expected to use on component level since it is not a full path
 * "path" is expected to use for page navigation on component level if there is no additional param to resolve
 * "pathResolver" is expected to use when the url needs to combine with some additional params
 * "canonicalPathResolver" is expected for SEO indexed page only
 * "alternatePaths" is expected to use as possible alternate path options for the page
 *
 */

const paths = {
  top: {
    path: '/(top|index.html)?',
    pathResolver: () => `/`,
    canonicalPathResolver: () => `/`,
    alternatePaths: ['/', '/top'],
  },

  // area
  area: {
    path: `${baseUrl.area}/:pathId(${pathIdRegExp})(.html)?`,
    pathResolver: (pathId: string) => `${baseUrl.area}/${pathId}/`,
    canonicalPathResolver: (pathId: string) => `${baseUrl.area}/${pathId}/`,
  },

  // provider
  providerList: {
    path: `${baseUrl.providerList}/:pageIdentifier(${pathIdRegExp}):features(${featuresPathPrefix}.*?)?:sortkey(${sortkeyPathPrefix}.*?)?:page(${pageNumberPathPrefix}\\d+|/index)?(.html)?`,
    pathResolver: (pageIdentifier: string) => `${baseUrl.providerList}/${pageIdentifier}/`,
    canonicalPathResolver: (
      pageIdentifier: string,
      features: string[] = [],
      sortKey?: SortKeysType,
      page?: number,
    ) => {
      const featureText = isNotEmptyArray(features)
        ? `${featuresPathPrefix}${features.join('-')}`
        : '';
      const sortKeyText = sortKey ? `${sortkeyPathPrefix}${sortKey}` : '';
      const pagePath = Number(page) > 1 ? `${pageNumberPathPrefix}${page}` : '';
      return `${baseUrl.providerList}/${pageIdentifier}${featureText}${sortKeyText}${pagePath}/`;
    },
  },
  providerInfo: {
    path: `${baseUrl.providerInfo}/:pathId(${pathIdRegExp})?/:pageIdentifier(${providerIDExp})(.html)?(/)?`,
    pathResolver: (pageIdentifier: string) => `${baseUrl.providerInfo}/${pageIdentifier}/`,
    canonicalPathResolver: (pathId: string) => `${baseUrl.providerInfo}/${pathId}/`,
  },
  providerReviewInfo: {
    path: `${baseUrl.providerReviewInfo}/:pathId(${pathIdRegExp})?/:pageIdentifier(${providerIDExp})(.html)?(/)?`,
    pathResolver: (pageIdentifier: string) => `${baseUrl.providerReviewInfo}/${pageIdentifier}/`,
    canonicalPathResolver: (pathId: string) => `${baseUrl.providerReviewInfo}/${pathId}/`,
  },

  // review
  guestReviewPost: {
    path: `${baseUrl.member}/guestreview_post/:reviewId`,
    pathResolver: (reviewId: string) => `${baseUrl.member}/guestreview_post/${reviewId}`,
  },
  guestReviewList: {
    path: `${baseUrl.member}/guestreview_list`,
  },
  guestReviewDetail: {
    path: `${baseUrl.member}/guestreview_detail/:pageIdentifier`,
    pathResolver: (reviewId: string) => `${baseUrl.member}/guestreview_detail/${reviewId}`,
  },
  guestReviewComplete: {
    path: `${baseUrl.member}/guestreview_completion`,
  },

  // bookmark
  bookmarkList: {
    path: `${baseUrl.member}/bookmark_list`,
  },

  // notification
  notificationList: {
    path: `${baseUrl.member}/notification_list`,
  },
  notificationDetail: {
    path: `${baseUrl.member}/notification_detail/:notificationId`,
    pathResolver: (notificationId: string) =>
      `${baseUrl.member}/notification_detail/${notificationId}`,
  },

  // coupon
  couponList: {
    path: `${baseUrl.member}/coupon_list`,
  },
  couponDetail: {
    path: `${baseUrl.member}/coupon_detail/:couponId`,
    pathResolver: (couponId: string) => `${baseUrl.member}/coupon_detail/${couponId}`,
  },

  // account
  accountSetting: {
    path: `${baseUrl.member}/account_setting`,
  },

  // email subscription
  emailSubscriptionManagement: {
    path: `${baseUrl.member}/email_subscription_management`,
  },

  // booking (parentPath: baseUrl.booking)
  bookingStep1: {
    subPath: `/step1/:providerId(.*?)(.html)?`,
    pathResolver: (providerId: string) => `${baseUrl.booking}/step1/${providerId}`,
  },
  bookingStep2: {
    subPath: `/step2`,
    path: `${baseUrl.booking}/step2`,
  },
  bookingStep3: {
    subPath: `/step3`,
    path: `${baseUrl.booking}/step3`,
  },
  guideUser: {
    subPath: `/guide_user/:pageIdentifier`,
    pathResolver: (pageIdentifier: string) => `${baseUrl.booking}/guide_user/${pageIdentifier}`,
  },
  reservationForm: {
    subPath: `/reservation_entrance`,
    path: `${baseUrl.booking}/reservation_entrance`,
  },
  legacyReservationForm: {
    subPath: `/legacy_reservation_entrance`,
    path: `${baseUrl.booking}/legacy_reservation_entrance`,
  },
  reservationFailed: {
    subPath: `/reservation_entrance_failed`,
    path: `${baseUrl.booking}/reservation_entrance_failed`,
  },
  bookingMailRequest: {
    subPath: `/auth_mail_request`,
    path: `${baseUrl.booking}/auth_mail_request`,
  },
  bookingMailAccept: {
    subPath: `/auth_mail_request_accepted`,
    path: `${baseUrl.booking}/auth_mail_request_accepted`,
  },
  bookingMailFail: {
    subPath: `/auth_mail_failed`,
    path: `${baseUrl.booking}/auth_mail_failed`,
  },

  // modification steps (parentPath: baseUrl.modification)
  modificationStep1: {
    subPath: `/step1/:reservationId`,
    pathResolver: (reservationId: string) => `${baseUrl.modification}/step1/${reservationId}`,
  },
  modificationStep2: {
    subPath: `/step2/:reservationId`,
    pathResolver: (reservationId: string) => `${baseUrl.modification}/step2/${reservationId}`,
  },
  modificationStep3: {
    subPath: `/step3/:reservationId`,
    pathResolver: (reservationId: string) => `${baseUrl.modification}/step3/${reservationId}`,
  },

  // cancellation steps (parentPath: baseUrl.cancellation)
  cancellationStep1: {
    subPath: `/step1/:reservationId`,
    pathResolver: (reservationId: string) => `${baseUrl.cancellation}/step1/${reservationId}`,
  },
  cancellationStep2: {
    subPath: `/step2/:reservationId`,
    pathResolver: (reservationId: string) => `${baseUrl.cancellation}/step2/${reservationId}`,
  },
  legacyCancellationStep2: {
    subPath: `/legacy_step2/:reservationId`,
    pathResolver: (reservationId: string) =>
      `${baseUrl.cancellation}/legacy_step2/${reservationId}`,
  },

  // reservation steps
  reservationList: {
    subPath: `/list`,
    path: `${baseUrl.reservation}/list`,
  },
  reservationDetail: {
    subPath: `/detail/:reservationId`,
    pathResolver: (reservationId: string) => `${baseUrl.reservation}/detail/${reservationId}`,
  },
  reservationSelectingReceipt: {
    path: `${baseUrl.reservation}/selecting_output_receipt`,
  },
  reservationPreviewReceipt: {
    path: `${baseUrl.reservation}/preview_receipt`,
  },
  reservationOpenReceipt: {
    path: `${baseUrl.reservation}/open_receipt`,
  },
  legacyReservationList: {
    subPath: `/legacy_list`,
    path: `${baseUrl.reservation}/legacy_list`,
  },
  legacyReservationDetail: {
    subPath: `/legacy_detail/:reservationId`,
    pathResolver: (reservationId: string) =>
      `${baseUrl.reservation}/legacy_detail/${reservationId}`,
  },
  reservationLogin: {
    subPath: `/login`,
    path: `${baseUrl.reservation}/login`,
  },

  auth: {
    // DO NOT UPDATE: This path is registered on OMNI system
    path: `/login`,
    pathResolver: (domain: string) => `${domain}/login`,
  },
  logout: {
    path: `${baseUrl.logout}`,
  },
  loginError: {
    path: `/login_error`,
  },
  loginOrGuest: {
    path: `/login_or_guest`,
  },
  signIn: {
    path: `/sign_in`,
  },

  notFound: {
    path: `/not_found`,
  },

  guestBooking: {
    path: '/non-member/:toWhere(.*)/:deviceId/:token',
  },

  privacyPolicy: {
    path: `${baseUrl.privacyPolicy}`,
  },

  // offline - Currently, it is being used for caching (service-worker)
  offline: {
    path: `${baseUrl.offline}(.html)?`,
  },

  eSimCampaign: {
    path: `${baseUrl.eSimCampaign}`,
  },
};

export const isDefaultTopPagePath = (market: string, language: string, path: string) => {
  return (
    path === paths.top.canonicalPathResolver() &&
    market.toLowerCase() === DEFAULT_SITE_MARKET.toLowerCase() &&
    language.toLowerCase() === DEFAULT_SITE_LANGUAGE.toLowerCase()
  );
};

export const getURLPagePathName = (market: string, language: string, path: string) => {
  if (isDefaultTopPagePath(market, language, path)) {
    return path;
  } else {
    const formattedMarketCode = market.toLowerCase();
    const formattedLanguageCode = language.toLowerCase();
    return `/${formattedMarketCode}/${formattedLanguageCode}${path}`;
  }
};

export default paths;
