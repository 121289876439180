import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { getDeviceType } from '@travel/traveler-core/utils';

export const getUserAgent = (state: RootState) => {
  if (state && state.userAgent) {
    return state.userAgent.userAgent;
  } else {
    return null;
  }
};

export const getSsrDeviceType = createSelector(getUserAgent, getDeviceType);

export const getIsIOS = createSelector(getUserAgent, userAgent => {
  if (userAgent) {
    return userAgent.isiPad || userAgent.isiPod || userAgent.isiPhone;
  }
  return false;
});

export const getIsBot = createSelector(getUserAgent, userAgent => {
  if (userAgent) {
    return userAgent.isBot;
  }
  return false;
});

export const getIsSafari = createSelector(getUserAgent, userAgent => {
  if (userAgent) {
    return userAgent.isSafari;
  }

  return false;
});
