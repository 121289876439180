import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrency, getLanguage } from '@travel/i18n';
import L10nMoney, { Props } from '@travel/i18n/src/components/L10nMoney';
import { cx } from '@travel/utils';

import { getIsGlobalCurrency } from 'store/markets/selectors';

import styles from './money.module.scss';

function Money(props: Props & { className?: string }) {
  const language = useSelector(getLanguage);
  const currency = useSelector(getCurrency);
  const shouldDisplayGlobalCurrency = useSelector(getIsGlobalCurrency);
  let isGlobalCurrency = shouldDisplayGlobalCurrency;

  if ((props.locale || props.currency) && !shouldDisplayGlobalCurrency) {
    // if it should be domestic currency but the given currency or language is conflicted with the current site setting,
    // then display the global currency instead
    if (props.locale && props.locale !== language) {
      isGlobalCurrency = true;
    }
    if (props.currency && props.currency !== currency) {
      isGlobalCurrency = true;
    }
  }

  return (
    <span className={cx(props.className, styles.wrapper)}>
      <L10nMoney {...props} isGlobalCurrency={isGlobalCurrency} />
    </span>
  );
}

Money.defaultProps = {
  value: null,
};

export default Money;
