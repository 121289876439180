import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { SuggestionsErrors, SuggestionsItem } from 'Suggestions-Types';

import { fetch, FetchOption } from './apis';

// XXX: Kindly update payloads to your own async implementation
export const fetchSuggestionsAsync = createAsyncAction(
  'FETCH_SUGGESTIONS_REQUEST',
  'FETCH_SUGGESTIONS_SUCCESS',
  'FETCH_SUGGESTIONS_FAILURE',
)<undefined, SuggestionsItem[], SuggestionsErrors[]>();

export const fetchSuggestions = (text: string, options?: FetchOption): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchSuggestionsAsync.request());
    let suggestions = await fetch(text, { limit: 5, ...options }, apiClient);
    dispatch(fetchSuggestionsAsync.success(suggestions));
  } catch (error) {
    dispatch(fetchSuggestionsAsync.failure(error));
  }
};
