import { fetchGuestReview } from 'store/guestReviews/actions';
import { setDefaultSEOData } from 'store/seo/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type SearchProps = {
  params: { pageIdentifier: string };
};

export default ({ store: { dispatch } }: universalRouterProps, search: SearchProps) => {
  return Promise.all([
    dispatch(fetchGuestReview(search.params.pageIdentifier)),
    dispatch(setDefaultSEOData()),
  ]);
};
