import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { LegacyCancelStep2Item, LegacyCancelStepErrors } from 'LegacyCancelStep-Types';

import { postLegacyCancelStep2Async } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([postLegacyCancelStep2Async.request], (state, action) => true)
  .handleAction(
    [postLegacyCancelStep2Async.success, postLegacyCancelStep2Async.failure],
    (state, action) => false,
  );

export const step2 = createReducer({} as LegacyCancelStep2Item).handleAction(
  postLegacyCancelStep2Async.success,
  (state, action) => {
    return action.payload;
  },
);

export const errors = createReducer({} as LegacyCancelStepErrors)
  .handleAction(postLegacyCancelStep2Async.failure, (state, action) => action.payload)
  .handleAction([postLegacyCancelStep2Async.request], () => {
    return {};
  });

const legacyCancelStepReducer = combineReducers({
  isFetching,
  step2,
  errors,
});

export default legacyCancelStepReducer;
export type LegacyCancelStepState = ReturnType<typeof legacyCancelStepReducer>;
