import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ReservationEntranceErrors, ReservationEntranceItems } from 'ReservationEntrance-Types';

import { fetchReservationEntranceAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchReservationEntranceAsync.request], (state, action) => true)
  .handleAction(
    [fetchReservationEntranceAsync.success, fetchReservationEntranceAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as ReservationEntranceItems).handleAction(
  fetchReservationEntranceAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer({} as ReservationEntranceErrors).handleAction(
  fetchReservationEntranceAsync.failure,
  (state, action) => action.payload,
);

const reservationEntranceReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default reservationEntranceReducer;
export type ReservationEntranceState = ReturnType<typeof reservationEntranceReducer>;
