import { as } from '@travel/utils';

import { setPreviousPageName } from 'store/__router/actions';
import { getLatestPageName } from 'store/pageLoading/selectors';
import { fetchReservationList, setLastStatusType } from 'store/reservationList/actions';
import { setDefaultSEOData } from 'store/seo/actions';

import { PAGE_NAMES } from 'constants/reservationList';
import { universalRouterProps } from 'core/universalRouter/types';
import { SORT_OPTION_TYPE, STATUS_TYPE } from 'ReservationList-Types';

import { BOOKED_AS_GUEST } from './constants';

type Query = {
  category: STATUS_TYPE;
};

export default ({ store: { dispatch, getState } }: universalRouterProps, query: Query) => {
  const state = getState();

  if (state?.reservationList?.selectedStatusType === BOOKED_AS_GUEST) {
    return;
  }

  const latestPageName = getLatestPageName(state);
  const selectedTab =
    query?.category ||
    (latestPageName === 'reservationDetail'
      ? state.reservationList.selectedStatusType
      : 'UPCOMING');

  const req = {
    offset: 0,
    limit: 5,
    reservationList: selectedTab,
    sort: 'BOOKING_DATE_DESC' as SORT_OPTION_TYPE,
  };

  dispatch(setPreviousPageName(PAGE_NAMES[selectedTab]));

  return Promise.all([
    dispatch(fetchReservationList(req)),
    dispatch(setDefaultSEOData()),
    latestPageName !== 'reservationDetail'
      ? dispatch(setLastStatusType(as<STATUS_TYPE>(selectedTab)))
      : null,
  ]);
};
