import React, { ReactNode } from 'react';

import styles from './components.module.scss';

type Props = {
  children: ReactNode;
};

function Layout(props: Props) {
  return <div className={styles.layout}>{props.children}</div>;
}

export default Layout;
