import { ApiClient } from '@travel/api-client';

import { SpecialFeatureArticlesItems } from 'SpecialFeatureArticles-Types';
import { fetchEditableContents, getValidatedContents } from 'utils/editableContents';

export const fetchAll = (
  http: ApiClient,
  page: string,
  market: string,
  language: string,
): Promise<SpecialFeatureArticlesItems[]> =>
  fetchEditableContents(http, page, 'articlebanner', market, language).then(
    data => getValidatedContents(data) || ([] as SpecialFeatureArticlesItems[]),
  );
