import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { isNotEmptyArray } from '@travel/utils';

import {
  clearErrorMessage,
  fetchMoreVacancyCalendarAsync,
  fetchVacancyCalendarAsync,
  fetchVacancyCalendarDetailsAsync,
} from 'store/vacancyCalendar/actions';

import {
  VacancyCalendar,
  VacancyCalendarDetails,
  VacancyCalendarErrors,
} from 'VacancyCalendar-Types';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchVacancyCalendarAsync.request,
      fetchMoreVacancyCalendarAsync.request,
      fetchVacancyCalendarDetailsAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchVacancyCalendarAsync.success,
      fetchVacancyCalendarAsync.failure,
      fetchMoreVacancyCalendarAsync.success,
      fetchMoreVacancyCalendarAsync.failure,
      fetchVacancyCalendarDetailsAsync.success,
      fetchVacancyCalendarDetailsAsync.failure,
    ],
    () => false,
  );

export const item = createReducer({ calendars: [] } as VacancyCalendar)
  .handleAction(fetchVacancyCalendarAsync.success, (_state, action) => action.payload)
  .handleAction(fetchMoreVacancyCalendarAsync.success, (state, action) => {
    const currCalendars = action.payload.calendars;
    const prevCalendars = state.calendars;
    const hasPrevCalendars = prevCalendars && isNotEmptyArray(prevCalendars);

    if (hasPrevCalendars) {
      return { calendars: [...prevCalendars, ...currCalendars] };
    } else {
      return action.payload;
    }
  })
  .handleAction(fetchVacancyCalendarAsync.failure, () => ({ calendars: [] }))
  .handleAction(fetchMoreVacancyCalendarAsync.failure, () => ({ calendars: [] }));

export const details = createReducer({} as VacancyCalendarDetails)
  .handleAction(fetchVacancyCalendarDetailsAsync.success, (_state, action) => action.payload)
  .handleAction(fetchVacancyCalendarDetailsAsync.failure, () => {
    return {} as VacancyCalendarDetails;
  });

export const errors = createReducer([] as VacancyCalendarErrors[])
  .handleAction(fetchVacancyCalendarDetailsAsync.failure, (_state, action) => action.payload)
  .handleAction(fetchVacancyCalendarDetailsAsync.request, (_state, _action) => [])
  .handleAction(clearErrorMessage, () => []);

const VacancyCalendarReducer = combineReducers({
  isFetching,
  item,
  details,
  errors,
});

export default VacancyCalendarReducer;
export type VacancyCalendarState = ReturnType<typeof VacancyCalendarReducer>;
