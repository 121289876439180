import React from 'react';

import { Adult, Bed, OceanView } from '@travel/icons/service';
import { IconTextList } from '@travel/ui';
import { isEmptyObject, isNotEmptyArray } from '@travel/utils';

import Media from 'components/Media';
import Money from 'components/Money';

import { Translate } from 'core/translate';
import { RatePlanItem } from 'ProviderRatePlan-Types';

import { mappedIconTextListData } from '../../ProviderInformationPage/helpers';

import styles from './roomItem.module.scss';

type Props = {
  ratePlanItem: RatePlanItem;
};

function RoomItem({ ratePlanItem }: Props) {
  const { name, media, bedSizes, capacity, plans, view, facilities } = ratePlanItem;
  const minPricePlans = plans.sort((a, b) => a.price.total - b.price.total);
  const minPrice = minPricePlans[0].price.total || 0;

  const featureIcons = mappedIconTextListData(facilities?.[0]?.features, styles.iconSize);

  return (
    <div className={styles.itemContainer} data-testid="guideUserPage-roomItem-wrapper">
      <p className={styles.title} data-testid="guideUserPage-roomItem-name">
        {name}
      </p>

      <div className={styles.priceContainer}>
        <Media media={media[0]} className={styles.image} />
        <div className={styles.price} data-testid="guideUserPage-roomItem-price">
          <p className={styles.priceTitle}>
            <Translate id="Guide_User_Inventory.Item_Starting_At" />
          </p>
          <Money value={minPrice} />
        </div>
      </div>

      <div>
        {isNotEmptyArray(bedSizes) && (
          <div className={styles.icon} data-testid="guideUserPage-roomItem-bed">
            <Bed size={12} />
            {bedSizes[0].name} x {bedSizes[0].amount || 1}
          </div>
        )}

        {!isEmptyObject(view) && (
          <div className={styles.icon} data-testid="guideUserPage-roomItem-view">
            <OceanView size={12} />
            {view.text}
          </div>
        )}

        {!isEmptyObject(capacity) && (
          <div className={styles.icon} data-testid="guideUserPage-roomItem-capacity">
            <Adult size={12} />
            <Translate id="Common_Plan_Details.Room_Plan_Info.Room_Info.Max" />
            <Translate
              id="Common_Plan_Details.Room_Plan_Info.Room_Info.Adult"
              count={capacity?.maxAdults}
              data={{ adult_guest_count: capacity?.maxAdults }}
            />
            {capacity?.maxChildren ? <span>/</span> : null}
            <Translate
              id="Common_Plan_Details.Room_Plan_Info.Room_Info.Child"
              count={capacity?.maxChildren}
              data={{ child_count: capacity?.maxChildren }}
            />
          </div>
        )}

        <IconTextList
          iconTextSpacing={5}
          spaceBetween={16}
          items={featureIcons}
          iconTextClassName={styles.iconSize}
          data-testid="guideUserPage-roomItem-features"
        />
      </div>
    </div>
  );
}

export default RoomItem;
