import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { pushLocation } from 'store/__router/actions';

import {
  CancelStep1Items,
  CancelStep2Items,
  CancelStepErrors,
  GetCancelStep1,
  NewCreditCard,
  PostCancelStep2,
} from 'CancelStep-Types';
import paths from 'core/universalRouter/paths';

import { cancelStep1, cancelStep2 } from './api';

export const postCancelStep2Async = createAsyncAction(
  'POST_CANCEL_STEP2_REQUEST',
  'POST_CANCEL_STEP2_SUCCESS',
  'POST_CANCEL_STEP2_FAILURE',
)<undefined, CancelStep2Items, CancelStepErrors>();

export const getCancelStep1Async = createAsyncAction(
  'GET_BOOKING_STEP1_REQUEST',
  'GET_BOOKING_STEP1_SUCCESS',
  'GET_BOOKING_STEP1_FAILURE',
)<undefined, CancelStep1Items, CancelStepErrors>();

export const getCancelStep1 = (req: GetCancelStep1): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(getCancelStep1Async.request());
  try {
    const response = await cancelStep1(apiClient, req);
    dispatch(getCancelStep1Async.success(response));
  } catch (error) {
    dispatch(getCancelStep1Async.failure({ status: error?.status, errors: error?.errors }));
  }
};

export const postCancelStep2 = (req: PostCancelStep2, data?: NewCreditCard): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(postCancelStep2Async.request());
  const response = await cancelStep2(apiClient, req, data);
  if (!response.status) {
    dispatch(postCancelStep2Async.success(response));
    dispatch(pushLocation(paths.cancellationStep2.pathResolver(response.reservationId)));
  } else {
    dispatch(postCancelStep2Async.failure({ status: response?.status, errors: response?.errors }));
  }
};
