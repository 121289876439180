import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  LegacyReservationList,
  LegacyReservationListErrors,
  LegacyReservationListPostBody,
  STATUS_TYPE,
} from 'LegacyReservationList-Types';

import { fetchLegacyReservationListItems } from './api';

export const fetchLegacyReservationListAsync = createAsyncAction(
  'FETCH_LEGACY_RESERVATION_LIST_REQUEST',
  'FETCH_LEGACY_RESERVATION_LIST_SUCCESS',
  'FETCH_LEGACY_RESERVATION_LIST_FAILURE',
)<undefined, LegacyReservationList, LegacyReservationListErrors[]>();

export const fetchLegacyReservationList = (req: LegacyReservationListPostBody): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchLegacyReservationListAsync.request());
  try {
    const response = await fetchLegacyReservationListItems(apiClient, req);
    dispatch(fetchLegacyReservationListAsync.success(response));
  } catch (error) {
    dispatch(fetchLegacyReservationListAsync.failure(error));
  }
};

export const setLastStatusType = createStandardAction('SET_LAST_LEGACY_STATUS_TYPE')<STATUS_TYPE>();

export const fetchMoreLegacyReservationsAsync = createAsyncAction(
  'FETCH_MORE_LEGACY_RESERVATIONS_REQUEST',
  'FETCH_MORE_LEGACY_RESERVATIONS_SUCCESS',
  'FETCH_MORE_LEGACY_RESERVATIONS_FAILURE',
)<undefined, LegacyReservationList, LegacyReservationListErrors[]>();

export const fetchMoreLegacyReservationList = (
  req: LegacyReservationListPostBody,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchMoreLegacyReservationsAsync.request());
  try {
    const response = await fetchLegacyReservationListItems(apiClient, req);
    dispatch(fetchMoreLegacyReservationsAsync.success(response));
  } catch (error) {
    dispatch(fetchMoreLegacyReservationsAsync.failure(error));
  }
};
