import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { sessionHasOwnProperty } from '@travel/traveler-core/utils/sessionStorage';

import { getLocation } from 'store/__router/selectors';
import { getIsMaintenanceMode } from 'store/commonError/selectors';
import { getIsSafari } from 'store/userAgent/selectors';

import paths, { baseUrl } from 'core/universalRouter/paths';

import { BannerState } from './reducer';

export const getItem = (state: RootState) => state.banner.item;
export const getErrors = (state: RootState) => state.banner.errors;

export const getBanner = (state: RootState) => state.banner;

export const getIsFetching = createSelector(getBanner, (state: BannerState) => state.isFetching);

export const getHeight = createSelector(
  getBanner,
  getIsMaintenanceMode,
  (state, isMaintenanceMode) => (isMaintenanceMode ? 0 : state.height),
);

export const getHeightIfSmartBannerPresent = createSelector(
  getBanner,
  getIsMaintenanceMode,
  getIsSafari,
  (state, isMaintenanceMode, isSafari) => {
    if (isMaintenanceMode) {
      return 0;
    }
    const SMART_BANNER_HEIGHT =
      sessionHasOwnProperty('shouldHideSmartBanner') === false && !isSafari ? 60 : 0;
    return state.height + SMART_BANNER_HEIGHT;
  },
);

export const getIsSmartBannerDisplay = createSelector(
  getBanner,
  getIsMaintenanceMode,
  getIsSafari,
  (_, isMaintenanceMode, isSafari) => {
    if (isMaintenanceMode) {
      return false;
    }
    return sessionHasOwnProperty('shouldHideSmartBanner') === false && !isSafari;
  },
);

export const getShouldDisplayWideBanner = createSelector(
  getBanner,
  getLocation,
  getIsMaintenanceMode,
  (state, location, isMaintenanceMode) => {
    return isMaintenanceMode
      ? false
      : Boolean(
          (state.item && location.pathname.includes(baseUrl.area)) ||
            (state.item && location.pathname.includes(baseUrl.providerList)) ||
            (state.item && location.pathname.includes(baseUrl.providerInfo)) ||
            paths.top.alternatePaths.some(path => path === location.pathname),
        );
  },
);
