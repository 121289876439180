import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { SalesPromotionState } from './reducer';

export const getSalesPromotion = (state: RootState) => state.salesPromotion;

export const getIsCouponDetailFetching = createSelector(
  getSalesPromotion,
  (state: SalesPromotionState) => state.isCouponDetailFetching,
);

export const getCouponDetailsErrors = createSelector(
  getSalesPromotion,
  (state: SalesPromotionState) => state.couponDetailsErrors,
);

export const getCouponDetails = (id?: string) =>
  createSelector(getSalesPromotion, (state: SalesPromotionState) => state.couponDetails[id || '']);

export const getMultipleCouponDetails = (ids?: string[]) =>
  createSelector(getSalesPromotion, (state: SalesPromotionState) =>
    ids?.map(id => state.couponDetails[id || '']),
  );

export const getApplicableCoupon = createSelector(
  getSalesPromotion,
  (state: SalesPromotionState) => state.applicableCoupon,
);
