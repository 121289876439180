import React from 'react';

import styles from './loading.module.scss';

type Props = {
  className?: string;
};

export default function Loading(props: Props) {
  const { className } = props;

  return (
    <div className={className}>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
        fill="url(#grad)"
        className={styles.loading}
      >
        <defs>
          <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#8BC558" />
            <stop offset="100%" stopColor="#4C9415" />
          </linearGradient>
        </defs>
        <circle cx="28" cy="28" r="24" stroke="url(#grad)"></circle>
      </svg>
    </div>
  );
}
