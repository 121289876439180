import { createStandardAction } from 'typesafe-actions';

import { SeoData } from 'Seo-Types';

import { TealiumObject } from '../../hooks/useTealium';

export const updateSEOData = createStandardAction('UPDATE_SEO_DATA').map((payload: SeoData) => ({
  payload,
}));

export const setDefaultSEOData = createStandardAction('SET_DEFAULT_SEO_DATA').map(() => ({
  payload: {},
}));

export const setDataLayer = createStandardAction('SET_DATA_LAYER')<TealiumObject>();

export const resetDataLayer = createStandardAction('RESET_DATA_LAYER').map(() => ({
  payload: {},
}));
