import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { HeroImagesErrors, HeroImagesItems } from 'HeroImages-Types';

import { fetchHeroImageCountAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchHeroImageCountAsync.request], () => true)
  .handleAction([fetchHeroImageCountAsync.success, fetchHeroImageCountAsync.failure], () => false);

export const items = createReducer([] as HeroImagesItems)
  .handleAction(fetchHeroImageCountAsync.success, (_state, action) => action.payload)
  .handleAction(fetchHeroImageCountAsync.failure, () => []);

export const errors = createReducer([] as HeroImagesErrors[]).handleAction(
  fetchHeroImageCountAsync.failure,
  (_state, action) => action.payload,
);

const heroImagesReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default heroImagesReducer;
export type heroImagesState = ReturnType<typeof heroImagesReducer>;
