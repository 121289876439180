import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { ProviderReviewState } from './reducer';

/**
 * Review List errors
 */

export const getErrors = (state: RootState) => state.providerReview.errors.reviewListErrors;

export const getProviderReview = (state: RootState) => state.providerReview;

export const getHasReviews = createSelector(
  getProviderReview,
  (state: ProviderReviewState) => Number(state.reviewList?.total) > 0,
);

export const getIsFetching = createSelector(
  getProviderReview,
  (state: ProviderReviewState) => state.isFetching,
);

export const getIsFetchingMore = createSelector(
  getProviderReview,
  (state: ProviderReviewState) => state.isFetchingMore,
);

export const getReviewScoreSummary = createSelector(
  getProviderReview,
  (state: ProviderReviewState) => state.scoreSummary,
);

/**
 * Provider info page
 */
export const getReviewListScoreInformation = createSelector(
  getProviderReview,
  (state: ProviderReviewState) => state.reviewList,
);

/**
 * SEO page
 */
export const getProviderGuestReview = createSelector(
  getProviderReview,
  (state: ProviderReviewState) => state.providerGuestReview,
);

/**
 * Review item errors
 */

export const getReviewItemError = (id: string) =>
  createSelector(
    getProviderReview,
    (state: ProviderReviewState) => state.errors.reviewItemErrors[id],
  );
