import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { GeneralContents, GeneralContentsErrors } from 'GeneralContents-Types';

import { fetchGeneralContentsAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchGeneralContentsAsync.request], (state, action) => true)
  .handleAction(
    [fetchGeneralContentsAsync.success, fetchGeneralContentsAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as GeneralContents).handleAction(
  fetchGeneralContentsAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as GeneralContentsErrors[]).handleAction(
  fetchGeneralContentsAsync.failure,
  (state, action) => action.payload,
);

const generalContentsReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default generalContentsReducer;
export type GeneralContentsState = ReturnType<typeof generalContentsReducer>;
