import React from 'react';
import cx from '../../utils/classnames';
import styles from './loadingMask.module.scss';

import Loading from '../Loading';

type Props = {
  /** The type of showing mode */
  type?: 'page' | 'section';
  /** Custom style for wrapper */
  className?: string;
};

function LoadingMask(props: Props) {
  const { type = 'page', className, ...rest } = props;

  return (
    <div
      className={cx(className, styles.flexCenter, styles[`loadingMask-${type}`])}
      data-testid="loadingMask-wrapper"
      {...rest}
    >
      <Loading />
    </div>
  );
}

export default LoadingMask;
