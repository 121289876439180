import { SetFieldValue } from '@travel/ui/components/Drawer/types';
import { isNotEmptyArray } from '@travel/utils';

import { ApplySpAndPoint, BookingForm, BookingStepData, PricingDetail } from 'BookingStep-Types';
import { ApplicableCouponRequestBody } from 'SalesPromotion-Types';

import { normalizeFeatureGroups } from './responseNormalizer';

export const initialBookingFormSate = {
  applicant: {
    countryCode: '',
    email: '',
    firstName: '',
    lastName: '',
    membership: 'RAKUTEN',
    phoneCountryCode: '',
    phoneNumber: '',
  },
  providerQuestions: [],
  specialRequest: '',
  expectedArrivalTime: '',
  stepId: '',
  totalPriceInProviderCurrency: 0,
  totalPriceInUserCurrency: 0,
  totalPriceUserAfterDiscount: 0,
  itemPlans: [],
};

export const bookingFormHelper = (state: BookingForm, action: { payload: BookingStepData }) => {
  const {
    pricingDetails,
    providerQuestions,
    stepId,
    totalEarnedPoints,
    totalPriceInProviderCurrency,
    totalPriceInUserCurrency,
    totalPriceUserAfterDiscount,
    extraRateForAges,
  } = action.payload;
  const itemPlans = pricingDetails.map((pricingDetail, index) => {
    const defaultPromotion = pricingDetail.salesPromotions.find(promotion => promotion.selected);
    const defaultSubPromotion =
      isNotEmptyArray(defaultPromotion?.subPromotions) &&
      defaultPromotion?.subPromotions.find(promotion => promotion.selected);
    const appliedSalesPromotionId = defaultPromotion ? defaultPromotion.detail.id : '';
    const appliedSubSalesPromotionId = defaultSubPromotion ? defaultSubPromotion?.detail.id : '';

    const defaultAppliedExtraRate = isNotEmptyArray(extraRateForAges)
      ? extraRateForAges
          .sort((a, b) => b.age - a.age)
          .map(item => {
            const defaultOption = item.options.find(opt => opt.selected);
            const id = defaultOption?.features.map(fea => fea.featureId);
            return { age: item.age, appliedFeatureIds: id || [] };
          })
      : [];

    const itemPlansGuests = state.itemPlans?.[index]?.guests;

    return {
      echoId: pricingDetail.echoId,
      guests: isNotEmptyArray(itemPlansGuests)
        ? itemPlansGuests
        : [
            {
              lastName: '',
              firstName: '',
              guestSequence: 1,
              representative: true,
              age: null,
            },
          ],
      itemId: pricingDetail.itemId,
      itemRatePlanId: pricingDetail.itemRatePlanId,
      appliedExtraRates: defaultAppliedExtraRate,
      appliedSalesPromotionId,
      appliedSubSalesPromotionId,
      priceWithoutDiscount: pricingDetail.priceWithoutDiscount,
      priceWithDiscount: pricingDetail.priceWithDiscount,
      totalPointsUsed: pricingDetail.totalPointsUsed,
      earnedPoints: pricingDetail.earnedPoints,
    };
  });

  const expectedArrivalTime = '';

  return {
    ...state,
    providerQuestions,
    expectedArrivalTime,
    stepId,
    totalEarnedPoints,
    totalPriceInProviderCurrency,
    totalPriceInUserCurrency,
    totalPriceUserAfterDiscount,
    itemPlans,
  };
};

export const bookingFormPostSpHelper = (
  state: BookingForm,
  action: { payload: ApplySpAndPoint },
) => {
  const {
    totalEarnedPoints,
    totalPriceInProviderCurrency,
    totalPriceInUserCurrency,
    totalPriceUserAfterDiscount,
    pricingDetails,
    extraRateForAges,
  } = action.payload;
  const { itemPlans } = state;

  const newItemPlans = itemPlans.map(item => {
    const priceDetail = pricingDetails.find(pricingDetail => pricingDetail.echoId === item.echoId);

    const selectedPromotion = priceDetail?.salesPromotions.find(item => item.selected);
    const selectedSubPromotion = selectedPromotion?.subPromotions?.find(item => item.selected);

    const defaultAppliedExtraRate = isNotEmptyArray(extraRateForAges)
      ? extraRateForAges
          .sort((a, b) => b.age - a.age)
          .map(item => {
            const defaultOption = item.options.find(opt => opt.selected);
            const id = defaultOption?.features.map(fea => fea.featureId);
            return { age: item.age, appliedFeatureIds: id || [] };
          })
      : [];

    return {
      ...item,
      appliedSalesPromotionId: selectedPromotion?.detail.id || '',
      appliedSubSalesPromotionId: selectedSubPromotion?.detail.id || '',
      appliedExtraRates: defaultAppliedExtraRate,
      priceWithoutDiscount: priceDetail
        ? priceDetail.priceWithoutDiscount
        : item.priceWithoutDiscount,
      priceWithDiscount: priceDetail ? priceDetail.priceWithDiscount : item.priceWithDiscount,
      totalPointsUsed: item.totalPointsUsed,
      earnedPoints: priceDetail ? priceDetail.earnedPoints : item.earnedPoints,
    };
  });
  return {
    ...state,
    itemPlans: newItemPlans,
    totalPriceUserAfterDiscount,
    totalPriceInUserCurrency,
    totalPriceInProviderCurrency,
    totalEarnedPoints,
  };
};

export const couponRequestBodyHelper = (action: { payload: BookingStepData }) => {
  const {
    childAges,
    pricingDetails,
    noOfAdults,
    providerCurrency,
    checkInDate,
    checkOutDate,
    providerToUserCurrencyExchangeRate,
    providerChildAgeTo,
    providerTaxRate,
    providerPriceRoundPosition,
  } = action.payload;

  const couponRequest: ApplicableCouponRequestBody = {
    salesPromotionId: '',
    pointCurrency: pricingDetails?.[0]?.earnedPoints?.currency,
    itemRatePlan: {
      ratePlanId: pricingDetails[0].ratePlanId,
      provider: {
        providerId: pricingDetails[0].providerId,
        priceRoundPosition: providerPriceRoundPosition,
        currency: providerCurrency,
        providerToUserCurrencyExchangeRate: providerToUserCurrencyExchangeRate,
        childAgeTo: providerChildAgeTo || 0,
        taxRate: providerTaxRate,
      },
      usageUnits: pricingDetails[0]?.usageUnits?.map(usage => ({
        date: usage.date,
        unitPriceProvider: usage.unitPrice?.providerAmount,
        nonDiscountableUnitPrice: usage.nonDiscountableUnitPriceProvider,
      })),
      travelPeriod: {
        from: checkInDate,
        to: checkOutDate,
      },
      guest: {
        adultCount: noOfAdults,
        childrenAges: childAges || [],
      },
    },
  };
  return couponRequest;
};

export const bookingDataHelper = (state: BookingStepData, action: { payload: BookingStepData }) => {
  const featureGroups = normalizeFeatureGroups(action.payload.featureGroups);
  const itemFeatureGroups = normalizeFeatureGroups(action.payload.item?.featureGroups);
  const ratePlanFeatureGroups = normalizeFeatureGroups(action.payload.ratePlan?.featureGroups);
  const providerFeatureGroups = normalizeFeatureGroups(action.payload.provider?.featureGroups);
  const extraRateForAges = action.payload?.extraRateForAges?.sort(
    (a: any, b: any) => b.age - a.age,
  );
  const itemVisualContent = action.payload.itemVisualContent || state.itemVisualContent;

  return {
    ...state,
    ...action.payload,
    featureGroups,
    extraRateForAges,
    itemVisualContent,
    item: { featureGroups: itemFeatureGroups },
    ratePlan: { featureGroups: ratePlanFeatureGroups },
    provider: { featureGroups: providerFeatureGroups },
  };
};

export const bookingDataPostSpHelper = (
  state: BookingStepData,
  action: { payload: ApplySpAndPoint },
) => {
  const {
    totalPriceInProviderCurrency,
    totalEarnedPoints,
    totalPriceInUserCurrency,
    totalPriceUserAfterDiscount,
    excludeTax,
    includeTax,
    pricingDetails,
    appliedBinRestrictedCouponId,
  } = action.payload;
  const extraRateForAges = action.payload?.extraRateForAges?.sort(
    (a: any, b: any) => b.age - a.age,
  );

  return {
    ...state,
    excludeTax,
    includeTax,
    totalEarnedPoints,
    totalPriceInProviderCurrency,
    totalPriceInUserCurrency,
    totalPriceUserAfterDiscount,
    pricingDetails,
    extraRateForAges,
    appliedBinRestrictedCouponId,
  };
};

export const syncAcquiredCouponAutoApplied = (
  pricingDetails: PricingDetail[] = [],
  setFieldValue: SetFieldValue,
) => {
  pricingDetails?.map((room, roomIndex) => {
    const selectedMainPromotion = room.salesPromotions?.find(sp => sp.selected);
    // main promotion is selected
    if (selectedMainPromotion) {
      setFieldValue(
        `itemPlans.${roomIndex}.appliedSalesPromotionId`,
        selectedMainPromotion.detail.id,
        false,
      );
      const selectedSubPromotion = selectedMainPromotion.subPromotions?.find(sp => sp.selected);
      // sub promotion is not selected
      if (selectedSubPromotion) {
        setFieldValue(
          `itemPlans.${roomIndex}.appliedSubSalesPromotionId`,
          selectedSubPromotion.detail.id,
          false,
        );
      }
    }
    return room;
  });
};
