import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getCurrency } from '@travel/i18n';

const useCurrencyChangeCallback = (callback: () => void) => {
  const currency = useSelector(getCurrency);
  const loadedCurrency = useRef(currency);

  useEffect(() => {
    if (currency !== loadedCurrency.current) {
      loadedCurrency.current = currency;
      callback();
    }
  }, [callback, currency]);
};

export default useCurrencyChangeCallback;
