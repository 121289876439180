import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { clearMember } from 'store/authCode/actions';

import { MemberError, MemberItem } from 'Member-Types';

import { fetchMemberAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchMemberAsync.request], (state, action) => true)
  .handleAction([fetchMemberAsync.success, fetchMemberAsync.failure], (state, action) => false);

export const isMember = createReducer(false)
  .handleAction(fetchMemberAsync.success, () => true)
  .handleAction(fetchMemberAsync.failure, () => false)
  .handleAction(clearMember, () => false);

export const item = createReducer({} as MemberItem)
  .handleAction(fetchMemberAsync.success, (state, action) => action.payload)
  .handleAction(clearMember, () => ({} as MemberItem));

export const error = createReducer({} as MemberError).handleAction(
  fetchMemberAsync.failure,
  (state, action) => action.payload,
);

const memberReducer = combineReducers({
  isFetching,
  isMember,
  item,
  error,
});

export default memberReducer;
export type MemberState = ReturnType<typeof memberReducer>;
