import { ApiClient } from '@travel/api-client';

import {
  ProviderReviewDetail,
  ProviderReviewList,
  ProviderSEOReviewList,
  ReviewActionResponse,
  SearchRequestBody,
} from 'ProviderReview-Types';

/**
 * Provider info page
 */
export const fetchReviewListItem = (
  http: ApiClient,
  providerId: string,
  req: SearchRequestBody,
): Promise<ProviderReviewList> =>
  http.get(`/reviews/traveler/providers/${providerId}`, {
    method: 'GET',
    req: { ...req },
    values: { ...req },
    ignoreCommonErrorHandler: true,
  });

export const reportReview = (http: ApiClient, reviewId: string): Promise<ReviewActionResponse> =>
  http.post(`/reviews/traveler/${reviewId}/report`, {});

export const voteReview = (http: ApiClient, reviewId: string): Promise<{ count: number }> =>
  http.post(`/reviews/traveler/${reviewId}/helpful`, {});

/**
 * SEO page
 */
export const fetchProviderGuestReviewAPI = (
  http: ApiClient,
  providerId: string,
  req: SearchRequestBody,
): Promise<ProviderSEOReviewList> =>
  http.get(`/reviews/traveler/providers/${providerId}/seo`, {
    req: { ...req },
    values: { ...req },
    ignoreCommonErrorHandler: true,
  });

/**
 * PROVIDER
 */
export const fetchProviderReviewItem = (
  http: ApiClient,
  reviewId: string,
): Promise<ProviderReviewDetail> =>
  http.get(`/reviews/traveler/providerReviews/${reviewId}`, {
    ignoreCommonErrorHandler: true,
  });
