import { ApiClient } from '@travel/api-client';

import { BannerItem } from 'Banner-Types';
import { fetchEditableContents, getValidatedContents } from 'utils/editableContents';

export const fetch = (
  http: ApiClient,
  page: string,
  market: string,
  language: string,
): Promise<BannerItem> =>
  fetchEditableContents(http, page, 'widebanner', market, language).then(
    data => getValidatedContents(data)?.[0] || ({} as BannerItem),
  );
